.portlet-dropzone.empty {
  padding: 1px;
}
.portlet-topper {
  z-index:1000;
}

.box-comments__avatar-liferay{
  vertical-align: unset;
  width: 40px;
  height: 40px;
}

.intranet-search-liferay__dropdown {
  max-height: calc(100vh - 199px)!important;
}

.liferay-search {
  padding-top:40px;
}

.liferay-facet {
  margin-top:40px;
}

.liferay-box-accordion {
  border-top: $color-border-medium;
}

.liferay-search-icon {
	background: transparent;
	border: 0px;
	display: flex;
}
.liferay-search-input-8020{
	border-right-width: 1px !important;
}

.liferay-search-icon:focus{
	background: transparent;
}
