.box-calendar {
  background-color: $background-light;
  padding: 20px 10px;
  
  &__table {
    table-layout: fixed;
    width: 100%;

    thead {
      font-size: 16px;
      line-height: 40px;
      color: $color-text-secondary;

      th {
        width: calc(100% / 7);
        white-space: nowrap;
      }
    }

    tbody {
      border-top: solid 1px $color-border-medium;
      border-left: solid 1px $color-border-medium;

      td {
        position: relative;
        width: calc(100% / 7);
        height: 100px;
        vertical-align: top;
        border-right: solid 1px $color-border-medium;
        border-bottom: solid 1px $color-border-medium;
      }
    }

    .prev, .next {
      .box-calendar__day {
        opacity: 0.5;
      }
    }
  }

  &__month {
    font-size: 20px;
    line-height: 27px;
    color: $color-text;
  }

  &__day {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $color-text;
    text-align: right;
    padding: 7px;

    &[data-toggle="modal"] {
      cursor: pointer;
    }
  }

  &__event {
    &:hover {
      .box-calendar__event-detail {
        display: block;
      }
    }

    &--edit {
      cursor: pointer;
      
      .box-calendar__event-title {
        background-color: $color-success;
      }
    }

    &-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1;
      color: $notification-num-text-color;
      background-color: $notification-num-bg-color;
      border-radius: $border-radius-input-form;
      padding: 1px 5px;
      margin-bottom: 5px;
    }
  }

  &__event-detail {
    display: none;
    width: 290px;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 99;
    transform: translateY(-100%);
    padding-bottom: 20px;
    margin-left: -145px;

    @include media-breakpoint-down(md) {
      left: 0;
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 10px;
      width: 0;
      height: 0; 
      border-top: 10px solid $background-light;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      margin-left: -10px;

      @include media-breakpoint-down(md) {
        left: 10%;
      }
    }

    > div {
      background-color: $background-light;
      box-shadow: $box-shadow-default;
      padding: 25px;
    }

    &-title {
      font-size: 20px;
      color: $color-text;
      margin-bottom: 30px;
    }
  }
}

.box-calendar-buttons {
  
  .btn-link {
    margin-left: 1px;
    margin-bottom: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}