.article-detail {

  &--list {
    padding-top: 15px;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }

    &:first-of-type {
      padding-top: 0;
      margin-bottom: 0;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    color: $color-text-contrast;
    text-align: center;
    text-transform: uppercase;
    background-color: $background-primary;
    border-radius: $border-radius-date;
    margin-right: 12px;
  }

  &__day {
    @include font-family($font-family-primary, bold);
    font-size: 20px;
    line-height: 1;
    color: $color-text-contrast;
    margin-bottom: 4px;
  }
  
  &__month {
    @include font-family($font-family-primary, regular);
    font-size: 16px;
    line-height: 1;
    color: $color-text-contrast;
  }

  &__date-text {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  &__value {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
  }

  &__source, &__office {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
    text-transform: uppercase;
  }

  &__category {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    line-height: 1.5;
    color: $category-text-color;
    text-transform: uppercase;
    background-color: $category-bg-color;
    border-radius: $border-radius-bean;
    padding: 0 10px;
  }

  &__type {
    margin-left: 24px;

    .ico-svg {
      max-width: 18px;
      max-height: 18px;
      color: $color-icon-secondary;
    }

    span {
      display: inline-block;
      color: $color-text;
      margin-left: 4px;
    }
  }

  &__check {
    margin-left: 24px;

    .ico-svg {
      max-width: 18px;
      max-height: 18px;
    }

    span {
      display: inline-block;
      color: $color-text;
      margin-left: 4px;
    }
  }

  &__info {
    color: $color-text;
    border-top: 1px solid $color-border-dark;
    padding-top: 24px;
    padding-bottom: 30px;
    line-height: 1.5;

    &:first-child {
      @include media-breakpoint-up(lg) {
        border-top: none;
        padding-top: 0;
      }
    }

    .article-social {
      padding-top: 4px;
    }

    .btn-acknowledgment {
      margin-left: 0;
    }
  }

  &__results {
    color: $color-text;
    padding: 12px 0;
    border-bottom: 1px solid $color-border-dark;
    margin-bottom: 40px;

    .ico-svg {
      color: $color-icon-primary;
      max-width: 10px;
      max-height: 10px;
      margin-left: 16px;
    }
  }

  &__text {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 1.5;
    color: $color-text;
    
    h1, h2, h3, h4, h5, h6 {
      color: $color-text-headings;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 24px;
    }

    ul, ol {
      @include font-family($font-family-primary, medium);
      font-size: $font-size-default;
      line-height: 1.5;
      color: $color-text;
      margin: 10px 0 20px 15px;

      li {
        @include font-family($font-family-primary, medium);
        font-size: $font-size-default;
        line-height: 1.5;
        color: $color-text;
      }
    }

    a {
      color: $detail-link-text-color;
      text-decoration: $detail-link-text-decoration;

      &:hover {
        color: $detail-link-text-color-hover;
        text-decoration: $detail-link-text-decoration-hover;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $detail-link-text-color;
      }
    }

    table {
      @include font-family($font-family-primary, medium);
      font-size: $font-size-default;
      border-left: solid $detail-table-border-width $detail-table-border;
      border-bottom: solid $detail-table-border-width $detail-table-border;
      margin-bottom: 24px;

      tr {
        &:nth-child(even) {
          background-color: $detail-table-row-even-bg-color;
        }
        &:nth-child(odd) {
          background-color: $detail-table-row-odd-bg-color;
        }
      }

      td, th {
        color: $color-text;
        border-top: solid $detail-table-border-width $detail-table-border;
        border-right: solid $detail-table-border-width $detail-table-border;
        padding: $detail-table-cell-padding-y $detail-table-cell-padding-x;
      }

      th {
        @include font-family($font-family-primary, semibold);
        background-color: $detail-table-th-bg-color;
      }
    }
  }

  &__video {
    margin-bottom: 40px;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: 32px;
    }
  }

  &__img {
    width: 100%;
    border-radius: $border-radius;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }
  }

  &__attachments {
    @include font-family($font-family-primary, semibold);
    font-size: 34px;
    line-height: 1;
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }

  &__faq {
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }

  &__apps {
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }

  &__links {
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }
  
  &__contacts {
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }

  &__role {
    font-size: $font-size-small;
    line-height: 1;
    color: $color-text;
  }

  &__feedback {
    color: $color-link;
    margin-bottom: 20px;

    .ico-svg {
      max-width: 36px;
      max-height: 36px;
      color: $color-accent;
      margin-right: 15px;
    }

    span {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: 1.2;
      text-decoration: underline;
    }
  }

  &__person {
    margin: 10px 0 20px;
  }

  &__name {
    @include font-family($font-family-primary, bold);
    font-size: 16px;
    color: $color-text;
    line-height: 1;
    margin-bottom: 5px;
  }

  &__avatar {
    position: relative;
    min-width: 40px;
    width: 40px;
    height: 40px;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-default;
    line-height: 40px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius:$border-radius-avatar;
    overflow: hidden;
    margin-right: 15px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__tag {
    display: inline-block;
    font-size: 12px;
    line-height: 1.5;
    color: $color-link;
    text-transform: uppercase;
    border-radius: $border-radius-bean;
    border: solid 1px $color-link;
    padding: 0 14px;
    margin-top: 7px;
    margin-right: 7px;
  }

  &__add-calendar {
    background-color: transparent;
    line-height: $line-height-default;
    color: $color-link;
    text-align: left;
    margin-top: 8px;
    margin-bottom: $section-spacing-size;

    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm / 2;
    }

    .ico-svg {
      max-width: 10px;
      max-height: 10px;
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        margin-right: 8px;
      }
    }
  }

  &__time {
    line-height: $line-height-default;
  }

  &__location {
    @include font-family($font-family-primary, semibold);
    font-size: 16px;
  }

  &__file {
    display: block;
    font-size: $font-size-default;
    line-height: 1.2;
    color: $color-text;
    background-color: $background-light;
    border: solid 1px $color-border-light;
    border-radius: $border-radius-card;
    transition: box-shadow 0.3s ease-in-out;
    padding: 15px 20px;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      &:hover {
        box-shadow: $box-shadow-default;

        .article-detail__icon {
          color: $color-text;

          &--pdf {
            color: $color-icon-pdf;
          }
      
          &--spreadsheet {
            color: $color-icon-spreadsheet;
          }
      
          &--word {
            color: $color-icon-word;
          }
      
          &--video {
            color: $color-icon-video;
          }
      
          &--music {
            color: $color-icon-music;
          }
      
          &--image {
            color: $color-icon-image;
          }
      
          &--archive {
            color: $color-icon-archive;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-small;
      line-height: $line-height-default;
      padding: 10px 0 10px 15px;
    }

    .article-detail__link {
      word-break: break-word;
    }
  }

  &__icon {
    margin-right: 20px;
    color: $color-text;

    @include media-breakpoint-down(sm) {
      margin-right: 10px;
    }

    &--pdf {
      color: $color-icon-pdf;
    }

    &--spreadsheet {
      color: $color-icon-spreadsheet;
    }

    &--word {
      color: $color-icon-word;
    }

    &--video {
      color: $color-icon-video;
    }

    &--music {
      color: $color-icon-music;
    }

    &--image {
      color: $color-icon-image;
    }

    &--archive {
      color: $color-icon-archive;
    }

    .ico-svg {
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      max-height: 50px;

      @include media-breakpoint-down(sm) {
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        max-height: 30px;
      }
    }
  }

  &__link {
    color: $color-link;
    text-decoration: underline;
  }

  &__download {
    cursor: pointer;
    color: $color-icon-primary;
    margin-left: 12px;

    @include media-breakpoint-down(sm) {
      margin-left: 5px;
    }

    .ico-svg {
      max-height: 21px;
      width: 40px;
    }
  }

  &__label {
    line-height: 22px;
  }

  &__status {
    font-size: 12px;
    line-height: 22px;
    color: $color-text;
    text-transform: uppercase;
    border-radius: $border-radius-bean;
    border: solid 1px $color-text;
    min-width: 110px;
    display: inline-block;
    text-align: center;
  }

  &__signature {
    border-bottom: solid 1px $color-border-medium;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }

    .article-social {
      &__info {
        margin-left: 16px;
      }
    }
  }
}