$box-profilo-left-col-width: 220px;
$box-profilo-left-col-xl-width: 340px;
$box-profilo-left-col-md-width: 200px;

.box-profilo {
  display: flex;
  font-size: 15px;
  line-height: 24px;
  color: $color-text;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  &__card {
    width: $box-profilo-left-col-width;
    margin-right: 30px;
    
    @media (min-width: 1600px) {
      width: $box-profilo-left-col-xl-width;
    }

    @include media-breakpoint-only(md) {
      width: $box-profilo-left-col-md-width;
    }

    @include media-breakpoint-down(sm) {
      width: auto;
      margin-right: 0px;
      margin: 0 auto;
    }
  }

  &__col {
    width: calc(100% - (#{$box-profilo-left-col-width} + 30px));
    
    @media (min-width: 1600px) {
      width: calc(100% - (#{$box-profilo-left-col-xl-width} + 30px));
    }

    @include media-breakpoint-only(md) {
      width: calc(100% - (#{$box-profilo-left-col-md-width} + 30px));
    }

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  &__avatar {
    position: relative;
    width: $box-profilo-left-col-width;
    height: $box-profilo-left-col-width;
    text-align: center;
    background-color: $background-light;
    border-radius: $border-radius-avatar;
    margin: 0 auto;
    margin-bottom: 24px;

    @include media-breakpoint-only(md) {
      width: $box-profilo-left-col-md-width;
      height: $box-profilo-left-col-md-width;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: $box-profilo-left-col-width;
      width: $box-profilo-left-col-width;
      height: $box-profilo-left-col-width;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;

      @include media-breakpoint-only(md) {
        min-width: $box-profilo-left-col-md-width;
        width: $box-profilo-left-col-md-width;
        height: $box-profilo-left-col-md-width;
      }
    }
    
    .initials {
      display: block;
      @include font-family($font-family-primary, regular);
      font-size: 90px;
      line-height: $box-profilo-left-col-width;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;

      @include media-breakpoint-only(md) {
        line-height: $box-profilo-left-col-md-width;
      }
    }
  }

  &__editphoto {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 48px;
    height: 48px;
    color: $profile-editphoto-color;
    background-color: $profile-editphoto-bg-color;
    padding: 11px;
    border-radius: 50%;
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 24px;
    width: 24px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      height: 16px;
      width: 16px;
    }

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }
  }

  &__actions {
    display: inline-block;
    color: $color-link;
    margin-right: 12px;

    &:disabled {
      color: $color-grey-3;
      cursor: not-allowed;
    }

    .ico-svg {
      min-width: 27px;
      width: 27px;
      height: 20px;
    }
  }

  &__name {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    text-align: center;
    margin-bottom: 4px;
  }

  &__role {
    font-size: 15px;
    line-height: 20px;
    color: $color-text;
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 1;
    color: $section-title-color;
    word-break: break-word;
  }

  &__badge {
    height: 22px;
    font-size: $font-size-small;
    line-height: 21px;
    color: $color-link;
    text-transform: uppercase;
    border: solid 1px $color-link;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &__marker {
    .ico-svg {
      color: $color-accent;
      max-width: 20px;
      max-height: 26px;
      margin-right: 12px;
    }
  }

  &__details {
    font-size: $font-size-default;
    line-height: 30px;

    a {
      text-decoration: underline;
    }
  }

  &__wrap {
    background-color: $background-light;
    border-radius: $border-radius;
    padding: 24px;
    margin-top: 8px;
    margin-bottom: 24px;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;

      &--col {
        .box-profilo__info {
          width: 33%;
          padding-bottom: 0;
          margin-bottom: 0;

          &::before {
            content: none;
          }
        }
      }
    }

    &--settings {
      @include media-breakpoint-up(lg) {
        gap: 32px;
      }

      .box-profilo__setting {
        @include media-breakpoint-up(lg) {
          margin-top: 0;
          flex: 1 1 auto;
        }

        .switch-color-scheme__slider {
          border-color: $color-grey-4;
        }

        .switch-toggle__slider {
          border-color: $color-grey-4;
        }

        &-select {
          select {
            border-color: $color-grey-4;
          }
        }
      }
    }
  }

  &__info {
    position: relative;
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 8px;
    margin-bottom: 8px;

    > div, a {
      max-width: 100%;
    }

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;

      &::before {
        content: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 44px;
      width: calc(100% - 44px);
      height: 1px;
      background-color: $background-dark;
    }

    /* solo due contatti */
    @include media-breakpoint-up(lg) {
      &:first-child:nth-last-child(2),
      &:first-child:nth-last-child(2) ~ .box-profilo__info {
        width: 50%;
        padding-bottom: 0;
        margin-bottom: 0;
  
        &::before {
          content: none;
        }
      }
    }

    span {
      color: $color-text;

      &.email {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .icon {
      min-width: 44px;
      width: 44px;
      min-height: 44px;
      height: 44px;
      color: $mypage-icon-color;
      border: solid 1px $mypage-icon-color;
      border-radius: 50%;
      margin-right: 8px;

      &--full {
        background-color: $mypage-icon-full-bg-color;
        border: solid 1px $mypage-icon-full-bg-color;
        color: $mypage-icon-full-color;
      }

      .ico-svg {
        max-width: 20px;
        max-height: 20px;
      }
    }
  }

  &__settings {
    border-top: 1px solid $color-border-medium;
    padding-top: 24px;
    margin-top: 24px;

    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid $color-border-medium;
      padding-bottom: 24px;
      margin-bottom: 24px;
    }
  }

  &__setting {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    &-label {
      display: block;
      @include font-family($font-family-primary, semibold);
      line-height: 1.2;
      margin-right: 8px;
    }

    &-select {
      position: relative;
      min-width: 100px;
      
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 4px;
        display: inline-block;
        border: solid $color-black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg) translateX(-8px);
        padding: 3px;
      }

      select {
        width: 100%;
        appearance: none;
        font-size: 14px;
        cursor: pointer;
        background-color: $background-light;
        border: solid 1px $background-light;
        border-radius: 4px;
        padding: 4px;
        padding-right: 24px;
      }
    }
  }

  &__button {
    color: $color-link;
  }

  &__link {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    text-decoration: underline;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 8px;
    border-right: 1px solid $color-border-accent;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
    
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }
  }

  &__kudos {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 18px;
    text-decoration: underline;

    .ico-svg {
      max-width: 36px;
      max-height: 36px;
      color: $color-accent;
      margin-right: 12px;
    }
  }

  &__credits {
    display: inline-block;
    width: 36px;
    text-align: center;
    text-decoration: none;
    margin-top: 8px;
  }

  &__list {
    max-height: 320px;
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      padding-right: 4px;

      /* Firefox */
      scrollbar-color: rgba($background-primary, 0.4);
      scrollbar-width: thin;

      /* Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px $background-medium;
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($background-primary, 0.4); 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($background-primary, 0.8); 
      }
    }
  }

  &__birthday-label {
    @include font-family($font-family-primary, medium);
    font-size: 20px;
    line-height: 1;
    margin-bottom: 16px;

    .ico-svg {
      min-width: 23px;
      width: 23px;
      height: 25px;
      margin-right: 12px;
    }
  }

  &__comment {
    font-size: $font-size-default;
    line-height: 1.4;
    border-top: 1px solid $color-border-medium;
    padding-top: 24px;

    p {
      font-size: $font-size-default;
      line-height: 1.4;
    }

    &:first-child {
      border-top: 0;
    }

    &-avatar {
      position: relative;
      min-width: 30px;
      width: 30px;
      height: 30px;
      @include font-family($font-family-primary, regular);
      font-size: $font-size-small;
      line-height: 30px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
      overflow: hidden;
      margin-right: 8px;
  
      span {
        display: inline-block;
        width: 30px;
        min-width: 30px;
        height: 30px;
        @include font-family($font-family-primary, regular);
        font-size: $font-size-small;
        line-height: 30px;
        color: $avatar-text-color;
        text-align: center;
        text-transform: uppercase;
        background-color: $avatar-bg-color;
        border-radius: $border-radius-avatar;
      }
  
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        min-width: 30px;
        height: 30px;
        object-fit: cover;
        object-position: center center;
        border-radius: $border-radius-avatar;
        vertical-align: initial;
      }
    }
  }

  &__details {
    font-size: 14px;
    line-height: 1;
    margin-top: $section-spacing-size/2;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      margin-top: 8px;
      margin-bottom: $section-spacing-size-sm;
    }

    button, a {
      color: $color-link;
      background-color: transparent;
      outline: 0;
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    span {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__text {
    width: 100%;
    min-height: 120px;
    background-color: $background-medium;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: solid 1px $color-border-dark;
    border-radius: $border-radius;
    padding: 12px 21px;
    margin-bottom: 16px;

    &::placeholder {
      color: $color-text;
    }
  }

  &__birthday-form, &__birthday-congrats-wrap {
    border-bottom: 1px solid $color-border-medium;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  &__birthday-congrats {
    background-color: $background-medium;
    border-radius: $border-radius-card;
    padding: 16px 24px;
    padding-top: 0;
  }

  &__list-smart {
    .box-person-birthday {
      width: calc(33% - 16px);
      min-width: calc(33% - 16px);
      padding-bottom: 8px;
      margin: 0 8px 8px 8px;
    }
  }
}