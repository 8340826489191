.intranet-notification {
  height: 40px;

  @include media-breakpoint-up(md) {
    position: relative;
  }

  &.open {
    .intranet-notification__submenu {
      display: block;
    }
  }

  &__btn {
    position: relative;
    color: $notification-btn-color;
    background-color: transparent;
    padding-top: 4px;
    padding-right: 16px;
    padding-bottom: 4px;

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $notification-btn-color;
    }

    .ico-svg {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }

  &__number {
    display: block;
    min-width: 26px;
    height: 17px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    line-height: 15px;
    color: $notification-num-text-color;
    background-color: $notification-num-bg-color;
    border: solid 2px $notification-num-border-color;
    border-radius: 8px;
  }

  &__submenu {
    display: none;
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    z-index: 99;
    color: $color-text;

    @include media-breakpoint-up(md) {
      top: auto;
      left: auto;
      right: -32px;
      width: 380px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 46px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(sm) {
        top: 6px;
        right: 125px;
      }

      @include media-breakpoint-down(xs) {
        right: 88px;
      }
    }

    > div {
      background-color: $color-body;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      box-shadow: $box-shadow-default;
      margin-top: 15px;
    }
  }

  &__scroll {
    max-height: calc(100vh - 95px);
    overflow: auto;

    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 180px);
      
      /* Firefox */
      scrollbar-color: rgba($background-primary, 0.4);
      scrollbar-width: thin;

      /* Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px $color-body; 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($background-primary, 0.4); 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($background-primary, 0.8); 
      }
    }
  }

  &__label {
    height: 28px;
    font-size: 13px;
    line-height: 28px;
    color: $color-text-contrast;
    background-color: $background-primary;
    border-radius: 14px;
    padding: 0 18px;
    margin: 0 8px;
  }

  &__no-results {
    padding: 8px 18px;
  }

  &__list {
    .box-notification {
      margin: 8px;

      > div {
        padding: 8px 16px;
        padding-left: 58px;
      }
      
      &__ico {
        top: 8px;
        left: 15px;
        width: 20px;
        height: 20px;
    
        .ico-svg {
          max-width: 20px;
          max-height: 20px;
        }
      }

      &__options {
        top: 10px;
        right: 16px;
      }
    }
  }

  &__already-read {
    display: inline-block;
    position: relative;
    font-size: $font-size-small;
    color: $color-link;
    white-space: nowrap;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border-left: solid 1px $color-border-medium;
    padding-left: 8px;
    margin-top: 16px;
    margin-left: 4px;
    margin-right: 8px;
    margin-bottom: 16px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}