.portlet-document-library {

  .management-bar {
    background-color: transparent;

    &.management-bar-primary {
      background-color: transparent;
      border-color: $color-border-primary;
    }

    .container-fluid-max-xl {
      max-width: none;
      padding-left: 0;
      padding-right: 0;
    }

    .navbar-overlay {
      background-color: transparent;
    }

    // nascondo il bottone "i" (Toggle Info Panel)
    .navbar-nav {
      .nav-item {
        button.nav-link.btn-monospaced {
          display: none;
        }
      }
    }

    .btn-primary {
      color: $color-border-primary;
      border-color: $color-border-primary;

      &:hover, &:focus {
        color: $color-white;
        background-color: $background-primary;
      }
      
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
      }
    }
  }

  .subnav-tbar-primary {
    background-color: $color-primary-light;

    .container-fluid-max-xl {
      max-width: none;
    }
  }

  .breadcrumb {
    background-color: $color-grey-2;
  }

  .card, .card-section-header {
    color: $color-text;
    line-height: 1.2;

    .card-title {
      line-height: 1.2;
      margin-bottom: 0;

      &.text-truncate,
      a.text-truncate {
        text-overflow: unset;
        white-space: unset;
        line-height: 1.2;
      }
    }

    // nascondo l'icona della cartella
    .card-body {
      .card-row {
        .autofit-col {
          .sticker {
            display: none;
          }
        }
      }

      //nascondo la data di ultima modifica e l'autore
      .card-subtitle {
        display: none;
      }

      //nascondo lo stato del file (approvato, draft..)
      .card-detail {
        display: none;
      }
    }

    .aspect-ratio {
      .sticker {
        display: none;
      }
      
      .sticker-img,
      .aspect-ratio-item-center-middle {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  .list-group-notification {
    .list-group-item {
      background-color: transparent;

      .autofit-col.autofit-col-expand {
        span:nth-child(2) {
          display: none;
        }

        span:nth-child(3) {
          font-size: 14px;
          font-style: italic;
        }

        span:nth-child(4) {
          display: none;
        }

        span.file-entry-status {
          display: none;
        }
      }

      .checkbox {
        padding-left: 0;
      }
    }
  }

  .form-control {
    border-color: $color-border-primary;

    &:focus {
      border-color: $color-border-primary;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
      background-color: $color-white;
    }
  }

  .input-group .input-group-inset-item {
    border-color: $color-border-primary;

    .btn {
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
      }
    }
  }

  .form-check-card:hover .card,
  .form-check-card:hover .card-section-header {
    box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
  }

  .form-check-card .custom-control .custom-control-input:checked ~ .card {
    box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
  }
  
  .pagination-bar {
    .pagination-items-per-page > a,
    .pagination-items-per-page > button {
      color: $color-text;
      border: none;

      &[aria-expanded=true], &.show {
        background-color: $color-primary-light;
      }

      &:hover, &:focus {
        background-color: transparent;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
      }
    }

    .pagination-results {
      font-weight: 400;
    }

    .pagination {
      .page-item {
        background-color: transparent;
        border: none;

        .page-link {
          background-color: transparent;
          border: none;

          &:focus {
            box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
          }
        }

        &.active {
          .page-link {
            color: $color-white;
            background-color: $background-primary;
            border: none;
            border-radius: 8px;

            &:hover, &:focus {
              box-shadow: none;
            }
          }
        }

        &.disabled {
          background-color: transparent;
          border: none;

          .page-link {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }
  
  .sidenav-container {
    .sidenav-content {
      .container-fluid-max-xl {
        max-width: none;

        .navbar-nav {
          flex-wrap: unset;

          .nav-item {
            h3 {
              color: $color-text-headings !important;
              margin-bottom: 0 !important;
            }
            .btn-outline-secondary {
              &:hover {
                color: $color-icon-primary;
                background-color: transparent;
              }
              &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
              }
            }
            //nascondo il bottone "i" (Toggle Info Panel) nel dettaglio foto
            .btn.btn-monospaced.btn-secondary.btn-sm.btn-unstyled {
              display: none;
            }
          }
        }
      }

      .search-info {
        margin-top: 16px;

        .btn {
          margin-left: 8px;

          &.btn-secondary {
            color: $color-white;
            background-color: rgba($background-primary, .6);
            border: none;

            &.active {
              color: $color-white;
              background-color: $background-primary;
              border: none;
            }
          }
          
          &:focus {
            box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
          }
        }
      }
    }
  }

  .management-bar .navbar-nav .nav-link.focus,
  .management-bar .navbar-nav .nav-link:focus {
    box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
  }

  .component-action {
    color: $color-icon-primary;

    &:hover, &[aria-expanded=true], &.show {
      color: $color-white;
      background-color: $background-primary;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($color-primary-base, 0.25);
    }
  }
  
  .taglib-header {
    border-bottom: 0;
		margin-bottom: 0;
		padding-bottom: 0;
  
    .header-title{
      font-size: 24px;
      line-height: 1.5;
      @include font-family($font-family-primary, medium);
      text-transform: uppercase;
      border-bottom: 2px solid $color-border-primary;
      padding-bottom: 10px;
      margin: 0 0 30px;
			width: 100%;
		}
  }

  .info-panel, 
  .header-back-to,
  .taglib-discussion,  
  .lfr-message-response,
  .btn-default{
    display: none;
  }

  .container-fluid-1280 {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
  
  .preview-file{
    margin:0;
  }
}