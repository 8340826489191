/* MODIFICHE PER OTTIMIZZAZIONE SU LIFERAY 7.2
==================================================*/
html.error-page {
  height: 100%;

  .intranet.intranet--error {
    height: 100%;
    padding-top: 0;

    .wrapper {
      height: 100%;

      .main-wrapper {
        height: 100%;
      }

      .intranet-error-page__main {
        padding: 40px 0;
      }
    }
  }
}

.intranet {

  // fat menu
  .sidenav-menu-slider,
  .control-menu-container {
    z-index: 1004 !important;
  }

  // fat menu
  &.product-menu-open {
    .intranet-header {
      left: 320px;
    }
  }

  button[value=expandToggle] {
    display: none;
  }

  // per portlet native backend in frontend
  .dropdown-item {
    &:active {
      background-color: $color-primary-light;
    }
  }

  &--home {
    .wrapper {
      .box-acknowledgment {
        margin-top: $section-spacing-size;
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          margin-top: $section-spacing-size-sm;
        }
      }
    }
  }

  #wrapper {
    padding-top: 0;
  }

  //avvisi hp: la portlet ultimi avvisi deve essere posizionata dopo l'hero content
  @include media-breakpoint-up(lg) {
    &.signed-in {
      .box-links {
        top: -332px;
      }
    }
  }

  //Widget portlet notification header
  .intranet-header__info {
    .portlet {
      position: static;
    }
  }

  .portlet {
    margin-bottom: 0;

    .portlet-topper {
      z-index: 40;
    }

    .portlet-content {
      border-width: 0;
      padding: 0;
    }
  }

  .portlet-login {
    .portlet-icon-back {
      display: none;
    }

    .form-control {
      margin: auto;
    }

    .portlet-header {
      display: block;
    }
  }

  .portlet-search-bar {
    .search-bar {
      margin-top: 20px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }

  &.page-maximized {
    .modal-footer {
      padding: 0;
    }
  }

  &.hide-menu {
    .portlet-dropzone.empty {
      padding: 0;
    }
  }

  .portlet-layout {
    .portlet-header {
      margin: 0;
    }
  }

  .breadcrumb__item:before {
    right: 3px;
  }

  // Show/hide controls menu icon even with desktop viewport
  .control-menu-icon.toggle-controls.visible-xs {
    display: block !important;
  }

  // If controls are hidden, hide them in desktop too
  &.controls-hidden {
    .portlet {
      .portlet-topper {
        display: none;
        opacity: 0;
        transform: translateY(0);
      }
    }

    .portlet-journal-content {
      .portlet {
        .portlet-header {
          .visible-interaction {
            display: none;
          }
        }
      }
    }
  }

  .portlet-breadcrumb {
    position: relative;
    z-index: 1;
  }

  // Class wrapper means "ONLY ON MY CUSTOM THEME!": leave classic Liferay
  // theme untouched
  .wrapper {
    @import 'liferay-facets';
    @import 'liferay-pagination';
    @import 'liferay-sortby';
    @import 'liferay-documentmedia';
    @import 'liferay-navigation';
    @import 'liferay-layouttpl';
    @import 'liferay-notification';
    @import 'liferay-placeholders';
    @import 'liferay-roles';
    @import 'liferay-ddm-form';

    .portlet-boundary {
      &:first-child {
        .box-accordion {
          border-top: 0;
        }
      }
    }
  }

  // OTTIMIZZAZIONI COMPONENTI 8020
  .hero {
    .portlet-content-container, .portlet-body {
      height: 100%;
    }
  }

  &.has-control-menu {
    @include media-breakpoint-up(lg) {
      .intranet-megamenu {
        max-height: calc(100vh - 164px);
      }
    }

    .intranet-navigation {
      top: 105px;

      &.open {
        height: calc(100vh - 106px);
      }

      @include media-breakpoint-down(xs) {
        top: 97px;

        &.open {
          height: calc(100vh - 98px);
        }
      }
    }
  }

  //fix per cache senna primo click nel control panel di sx
  &.has-control-menu.controls-visible:not(.intranet-hide-controls-visible) {

    .anchor {
      height: 250px;
      margin-top: -250px;

      @include media-breakpoint-down(lg) {
        height: 200px;
        margin-top: -200px;
      }
    }

    // .portlet { 
    //   margin-top: 25px; 
    // }

    // div.main-wrapper {
    //   margin-top: 100px;
    // }

    #column-1 {
      margin-top: 50px;
    }

    div[id^='column-'] {
      border: 2px solid $color-primary-base;
      min-height: 30px;
      min-width: 100px;
    }

    div#intranet8020-container {
      border: 2px solid $color-primary-base;
      min-height: 30px;
      min-width: 100px;
    }

    .portlet-boundary_com_liferay_portal_search_web_category_facet_portlet_CategoryFacetPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_com_liferay_portal_search_web_internal_custom_filter_portlet_CustomFilterPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_com_liferay_portal_search_web_tag_facet_portlet_TagFacetPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_com_liferay_portal_search_web_custom_facet_portlet_CustomFacetPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_it_ariadne_intranet8020_user_skills_micro_activities_portlet_UserSkillsMicroActivitiesPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_it_ariadne_intranet8020_user_skills_micro_activities_portlet_UserSkillsMicroActivitiesPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }

    .portlet-boundary_it_ariadne_intranet8020_user_profile_job_UserProfileJobPortlet_ {
      border: 2px solid $color-primary-base;
      margin: 25px;
    }
  }


  &.has-control-menu.controls-hidden {
    .lfr-configurator-visibility {
      display: none;
    }
  }

  .control-menu-level-1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 989;
    transition: all .5s ease-in-out;
  }

  &.open {
    .control-menu-level-1 {
      left: 320px;
    }
  }

  // liferay tag taglib - start
  .dropdown-menu.autocomplete-dropdown-menu.show {
    z-index: 10000 !important;
  }

  .intranet-tags-input {

    .lfr-tooltip-scope .form-group > label {
      display: none;
    }

    .btn.btn-secondary {
      background-color: $background-accent;
      border: solid 1px $background-accent;
      color: $color-white;
      text-decoration: none;

      &:hover {
        background-color: $color-accent-dark;
      }
    }

    .input-group-item span {
      height: 22px;
      font-size: 13px;
      line-height: 23px;
      color: $color-primary-base;
      text-transform: uppercase;
      border-color: $color-primary-base;
      padding: 0 10px;
      margin-right: 8px;
      margin-bottom: 8px;
      font-weight: 400;

      &.label-item-after {
        margin-left: 0;
        margin-right: 0;
      }
    }

  }

  // liferay tag taglib - end

}

// .intranet

// classi di controllo per griglia di pagina (solo con controlli abilitati)
.controls-visible.signed-out:not(.intranet-hide-controls-visible) {
  padding-top: 70px;
  padding-bottom: 70px;
}

#portlet_terms-of-use,
#portlet_new-password,
#portlet_verify-email-address,
#portlet_email-address,
#portlet_password-reminder {

  .portlet-title-text {
    display: none;
  }

  .portlet-img-logo {
    max-height: 50px;
    margin-bottom: 32px;
  }

  .sheet {
    border: 0;
    border-radius: 0;
    margin-bottom: 127px;

    .sheet-title {
      max-width: none;
      font-size: 34px;
      line-height: 40px;
      color: $color-text;
      white-space: inherit;
    }
  }

  .sheet-header {
    .btn-secondary {
      .btn-section {
        color: $color-text;
      }
    }
  }

  .sheet-footer,
  .sheet-text {
    .btn {
      min-width: 110px;
      @include font-family($font-family-primary, regular);
      font-size: $font-size-default;
      line-height: 25px;
      font-weight: 400;
      color: $color-white;
      text-transform: uppercase;
      cursor: pointer;
      background-color: $color-accent;
      border: solid 1px $color-accent;
      margin-top: 16px;

      &:hover, &:active, &:focus {
        color: $color-white;
        background-color: $color-accent;
        border: solid 1px $color-accent;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $color-white;
        background-color: $color-accent;
        border: solid 1px $color-accent;
      }

      &.btn-secondary {
        @include font-family($font-family-primary, semibold);
        color: $color-accent;
        background-color: transparent;

        &:hover, &:focus, &--selected {
          color: $color-accent-dark;
          background-color: transparent;
          border-color: $color-border-accent-dark;
        }

        &:focus {
          text-decoration: $focus-text-decoration;
          outline: auto;
          outline-style: $focus-outline-style;
          outline-width: $focus-outline-width;
          outline-color: $color-accent-dark;
        }
      }
    }
  }
}

.intranet-header {
  #sign-in {
    display: none;
  }
}

.portlet-login {
  .btn-primary {
    min-width: 110px;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-default;
    line-height: 25px;
    font-weight: 400;
    color: $color-white;
    text-transform: uppercase;
    cursor: pointer;
    background-color: $color-accent;
    border: solid 1px $color-accent;

    &:hover, &:active, &:focus {
      color: $color-white;
      background-color: $color-accent;
      border: solid 1px $color-accent;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $color-white;
      background-color: $color-accent;
      border: solid 1px $color-accent;
    }
  }
}

.portlet-login {
  max-width: 540px;
  color: $color-text;
  background-color: $background-light;
  border: solid 1px $color-border-medium;
  border-radius: $border-radius;
  // box-shadow: $box-shadow-default;
  padding: 40px 32px;
  margin: 0 auto;

  .form-group {
    margin-bottom: 32px;

    .control-label {
      color: $color-text;
    }

    &.form-inline {
      label {
        display: inherit;
      }
    }
  }

  .input-checkbox-wrapper {
    .field {
      margin-top: -2px;
      margin-right: 8px;
    }
  }

  .button-holder {
    margin-top: 0;
    margin-bottom: 32px;

    .btn {
      width: 100%;
    }
  }

  .portlet-title-text {
    max-width: none;
    font-size: 34px;
    line-height: 40px;
    color: $color-text;
    text-align: center;
    margin-bottom: 48px;
    white-space: inherit;
  }

  .portlet-img-logo {
    max-height: 50px;
    margin-bottom: 32px;
  }

  .navigation {
    text-align: right;

    .taglib-icon-list {
      overflow: hidden;
      margin-bottom: 0;

      li {
        float: right;
        margin-left: 12px;
      }
    }
  }

  label .reference-mark {
    color: $color-accent !important;
  }
}

.box-profilo__box {
  .modal.modal-liferay {
    overflow: scroll;
  }
}

.modal.modal-liferay.modal-liferay-overflow {
  overflow: scroll;
}

.modal.modal-liferay {
  display: none;
}

.modal.modal-liferay.active {
  display: block;
}

.modal.modal-avviso.modal-overflow {
  overflow: hidden auto;
}

@media (min-width: 576px) {
  .modal-dialog.modal-dialog-liferay {
    max-width: 500px !important;
  }
}

.portlet-content {
  background-color: transparent !important;
}

.fragments-editor-sidebar-sections-buttons {
  padding-top: 100px !important;
}

.fragments-editor-toolbar {
  margin-top: 40px;
}

.fragments-editor-sidebar-section {
  padding-top: 100px !important;
}

.wrapper .intranet-header.intranet-header-signed-in {
  top: 56px;
  z-index: 969;

  @include media-breakpoint-down(xs) {
    top: 48px;
  }
}

.wrapper.open-admin-panel .main-wrapper {
  //padding-top: 56px;

  @include media-breakpoint-down(xs) {
    padding-top: 48px;
  }
}

.leaflet-container a, .leaflet-container span {
  color: $color-primary-base;
}

.leaflet-bottom, .leaflet-top {
  z-index: auto;
}

.wrapper .control-menu-level-1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.cursor-text {
  cursor: text !important;
}

#group-card.disable-link-card-group {
  cursor: auto;
}

.map-center-detail {
  top: -100px;

  @include media-breakpoint-down(xs) {
    top: auto;
  }
}

.input-group-item .input-group-inset-before.form-control.liferay-search-input-8020 {
  padding: 15px 20px;
  border: solid 1px #c0c1c7;
}

//document preview
.intranet-preview-container {
  .preview-file .floating-bar {
    background: $color-grey-1;
  }

  .preview-toolbar-container {
    button.btn-primary {
      background: none;
      border: none;
      color: $color-grey-4;

      &:active {
        background: none;
        border: none;
      }
    }

    button.btn-floating-bar.btn-floating-bar-text.btn.btn-primary {
      cursor: default;
      pointer-events: none;
    }

    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none;
    }

    .floating-bar-input-wrapper {
      display: none;
    }

    button.btn-floating-bar.btn.btn-monospaced.btn-primary:focus {
      box-shadow: none;
    }
  }
}

.wrapper .modal-avviso .modal-form__submit .btn-link.group-btn-disabled {
  color: white;
}


// Weather CSS
.wrapper .weather-dropdown-menu {

  .dropdown-menu {
    height: 150px;
    min-height: 150px;
    border-radius: 0;

    ul {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 1px;

      li {
        display: flex;
      }
    }
  }
}

// Portlet "Documenti e Media" (bottone "Accedi dal desktop")
.dropdown-menu.dropdown-menu-indicator-start {
  button[data-action=accessFromDesktop] {
    display: none;
  }
}

/*Group error card*/
.error-card {
  .intranet-card__header {
    background: #9b76ff;
  }
}

.error-card.intranet-card.intranet-card--gruppi {
  cursor: auto;
  opacity: 0.5;
}

.slider-avvisi-editable {
  .slick-list {
    padding-bottom: 50px;
  }
}

.wrapper .slider.slider-avvisi-editable .slick-dots {
  bottom: 30px;
}

.btn-outline-danger {
  margin-left: 10px;
  margin-right: 10px;
}


//no file attached
.no-attached-file {
  .favourite-star {
    margin-left: 10px;
  }
}


#popupDetailModalDate {
  vertical-align: middle;
  display: table-cell;
}


#form-announcement .calendar-input .calendar-input__date {
  width: 100%;
}

//Reservation
.tooltip {
  @include font-family($font-family-primary, semibold);
  font-size: $font-size-default;
  line-height: 1.2;

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    z-index: 99;
    white-space: normal;
    background-color: transparent;

    .box-calendar__event-detail {
      min-width: 260px;
			@include font-family($font-family-primary, regular);
			line-height: 1.5;
      color: $color-text;
      text-align: left;
      background-color: $background-light;
			border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 16px;

			&-resource {
				@include font-family($font-family-primary, semibold);
				color: $color-primary-base;
				margin-bottom: 4px;
			}

			&-title {
				@include font-family($font-family-primary, semibold);
				font-size: 18px;
				color: $color-text;
				margin-bottom: 16px;
			}

			&-author, &-date {
				font-size: $font-size-small;
			}
    }
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: $background-light;
    top: -4px;
  }

  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $background-light;
    bottom: -4px;
  }
}

a.fc-day-grid-event.fc-h-event.fc-event.box-calendar__event.res-event {
  padding: 1px 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $color-white;
  border-radius: 0;
}

a.fc-day-grid-event.fc-h-event.fc-event.box-calendar__event.res-event.box-calendar__event--view {
  border-color: $color-danger;
  background-color: $color-danger;
}

a.fc-day-grid-event.fc-h-event.fc-event.box-calendar__event.res-event.box-calendar__event--edit {
  border-color: $color-success;
  background-color: $color-success;
  font-weight: 900;
}

a.fc-day-grid-event.fc-h-event.fc-event.box-calendar__event.res-event.box-calendar__event--past {
  border-color: $color-grey-4;
  background-color: $color-grey-4;
  color: $color-black;
}


body.controls-hidden {
  .main-wrapper {
    padding-top: 56px;
  }

  header {
    &.portlet-topper {
      display: none !important;
    }
  }
}

body.controls-visible {
  .intranet-header__info {
    .portlet {
      position: relative;
    }
  }

  .intranet-header__topbar {
    header {
      &.portlet-topper {
        margin-top: 21px !important;
      }
    }
  }
}

body.has-edit-mode-menu {
  .portlet-boundary_com_liferay_layout_content_page_editor_web_internal_portlet_ContentPageToolbarPortlet_ {
    position: fixed;
    width: 100% !important;
    top: 56px;
  }

  .intranet-header.intranet-header-signed-in {
    padding-top: 65px;
  }
}

body.intranet.has-edit-mode-menu {
  padding-top: 276px;
}

.has-control-menu.controls-visible {
  #wrapper.wrapper {
    padding-top: 56px;
  }
}

body.intranet.dark-theme {
  div.portlet-content-container .portlet-body .sheet {
    background-color: transparent;
  }
}

//
//  liferay comment
//
.intranet-comment {
  font-size: $font-size-default;
  line-height: 29px;
  color: $color-link;
  border: none;
  padding: 0;

  &:hover, &:focus {
    color: $color-link;
    background-color: unset;
    box-shadow: none;
  }

  &.btn-primary, &.btn-secondary {
    background-color: unset;

    &:hover {
      background-color: unset;
    }
  }
}

.intranet-ratings-icons {
  margin-left: 16px;

  svg {
    color: $color-text;

    &:hover {
      color: $color-accent !important;
      background-color: unset;
      border: none;
    }
  }

  button {
    &.btn-thumbs-up[aria-pressed="true"] svg.lexicon-icon-thumbs-up-full {
      color: $color-accent !important;
    }

    &.btn-thumbs-down[aria-pressed="true"] svg.lexicon-icon-thumbs-down-full {
      color: $color-accent !important;
    }
  }

  .btn-outline-secondary {
    background-color: unset;
    border: none;
    color: $color-link;

    &:hover, &:focus {
      background-color: unset;
      border: none;
      box-shadow: none;
    }
  }
}

.taglib-discussion {
  .lfr-discussion-container {
    margin-bottom: 0;
  }

  .comment-container {
    padding-top: 8px;
    margin-bottom: 16px;
  }
}

.intranet-username {
  color: $color-secondary-base;
}

// .lexicon-icon {
//   color: $color-link !important;
// }

.lfr-discussion-reply-popover {
  &.popover {
    border: 1px solid $color-border-medium;
    border-radius: $border-radius;

    .popover-header {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .popover-body {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.ratings-thumbs {
  .lexicon-icon {
    color: $color-link !important;
  }
}

.cke_wysiwyg_frame,
.cke_wysiwyg_div,
.cke_top,
.cke_inner,
.cke_bottom {
  background-color: rgba($color-grey-3, .4) !important;
}

.cke_contents {
  border: 1px solid $color-grey-2 !important;
  margin: -1px 0 !important;
}

.liferay-container-hero-content {
  z-index: 968;
  position: relative;
}

.intranet.signed-in.controls-visible:not(.intranet-hide-controls-visible) #main-content:not(.layout-content) .portlet-boundary {
  padding: 10px;
  margin: 5px;
  border: 1px solid $color-primary-base;
}