.box-app {
  &:not(.box-app--sidebar) {
    position: relative;
    border-radius: $border-radius-card;
    transition: box-shadow 0.3s ease-in-out;
    padding: 5px;

    @include media-breakpoint-up(md) {
      &:hover {
        background-color: $background-light;
        box-shadow: $box-shadow-default;
      }
    }
  }

  &--complete {
    height: 250px;
    background-color: $background-light;
    margin-bottom: 24px;

    @include media-breakpoint-down(lg) {
      height: 210px;
    }

    @include media-breakpoint-down(sm) {
      height: 230px;
    }

    @include media-breakpoint-down(xs) {
      height: 170px;
    }

    .box-app__trash {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &:hover {
        .box-app__desc {
          opacity: 1;
        }
      }
    }
  }

  &--list {
    height: 170px;
    background-color: $background-light;
    margin: 0 8px 30px;

    .box-app__trash {
      display: none;
    }

    .box-app__check {
      display: none;
    }

    @include media-breakpoint-up(md) {
      &:hover {
        .box-app__desc {
          opacity: 1;
        }
      }
    }
  }

  &__link {
    color: $color-accent;

    &:hover, &:focus {
      color: $color-accent-dark;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent-dark;
    }
  }

  &__title {
    display: block;
    line-height: $line-height-default;
    color: $color-text;
    margin-top: 10px;
  }

  &__ico {
    width: 30px;
    height: 30px;
  }

  &__category {
    display: inline-block;
    height: 22px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    color: $category-text-color;
    line-height: 23px;
    text-transform: uppercase;
    border-radius: $border-radius-bean;
    background-color: $category-bg-color;
    white-space: nowrap;
    padding: 0 10px;
  }

  &__check {
    position: absolute;
    top: 10px;
    right: 0px;
    z-index: 3;
    color: $color-text-secondary;

    .checkbox__mark {
      top: 0;
    }
  }

  &__desc {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    font-size: 14px;
    line-height: 25px;
    color: $color-white;
    text-align: center;
    background-color: $background-accent;
    border-radius: $border-radius;
    opacity: 0;
    padding: 30px 21px;
  }

  &__detail {
    text-decoration: underline;
  }
  
  &__content {
    position: relative;
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 1;
    color: $box-apps-text-color;
    background-color: $box-apps-bg-color;
    border-radius: $box-apps-radius;
    transition: box-shadow 0.3s ease-in-out;
    padding: 8px;
    padding-right: 32px;
    margin-top: 8px;

    &:hover {
      color: $box-apps-text-color-hover;
      background-color: $box-apps-bg-color-hover;

      .box-app__icon {
        background-color: $box-apps-icon-bg-color-hover;

        .ico-svg {
          color: $box-apps-icon-color-hover;
        }
      }

      .box-app__trash {
        display: block;
      }
    }
  }

  &__icon {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: $box-apps-radius;
    background-color: $box-apps-icon-bg-color;
    padding: 8px;
    margin-right: 8px;

    .ico-svg {
      max-width: 32px;
      max-height: 32px;
      color: $box-apps-icon-color;
    }
  }

  &__trash {
    display: none;
    position: absolute;
    top: 50%;
    right: 8px;
    z-index: 2;
    transform: translateY(-50%);

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
    }
  }
}