.box-group-activities {
  background-color: $background-light;
  box-shadow: $box-shadow-default;
  padding: 50px 10px 0 15px;

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  .box-portlet {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    padding-bottom: 34px;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding: 0;
      padding-bottom: 34px;
    }
  }
}