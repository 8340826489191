.intranet-hamburger {
  flex: 0 0 43px;
  width: 43px;
  max-width: 43px;
  height: 38px;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  margin-left: 15px;
  margin-right: 15px;

  @include media-breakpoint-down(xs) {
    width: 30px;
    max-width: 30px;
    height: 38px;
  }
  
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $hamburger-color;
    border-radius: $hamburger-border-radius;
    filter: alpha(opacity=1);
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    
    &:nth-child(1) {
      top: 7px;
    }
    
    &:nth-child(2), &:nth-child(3) {
      top: 16px;
    }
    
    &:nth-child(4) {
      top: 25px;
    }
  }
  
  &.open {
    
    span {
      &:nth-child(1) {
        width: 0%;
        left: 50%;
      }
      
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      
      &:nth-child(4) {
        width: 0%;
        left: 50%;
      }
    }
  }
}