.box-notification {
  width: calc(100% - 15px);
  cursor: pointer;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  margin-right: 15px;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-right: 0;
  }

  > div {
    position: relative;
    padding: 24px;
    padding-left: 71px;
  }

  &--unread {
    > div {
      background-color: $box-notification-bg-color-unread;
      border-radius: $border-radius-card;
    }
  }

  &__ico {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    width: 22px;
    height: 22px;
    color: $color-text;

    .ico-svg {
      max-width: 22px;
      max-height: 22px;
      color: $color-text;
    }
  }

  &__options {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 2;
    width: 36px;
    height: 36px;

    &.open {
      .box-notification__button {
        color: $category-text-color;
        background-color: $category-bg-color;
        border-color: $color-border-accent;
      }

      .box-notification__dropdown {
        display: block;
      }
    }
  }

  &__button {
    color: $color-secondary-dark;
    background-color: transparent;
    border: solid 1px $color-border-dark;
    border-radius: 50%;
    padding: 7px;

    .ico-svg {
      max-width: 22px;
      max-height: 20px;
    }

    &:hover {
      color: $category-text-color;
      background-color: $category-bg-color;
      border-color: $color-border-accent;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text-contrast;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 12px;
    right: 36px;
    z-index: 3;
    width: 200px;
    cursor: default;
    background-color: $background-light;
    border: solid 1px $color-border-medium;
    box-shadow: $box-shadow-default;
    padding: 15px 24px;
  }

  &__delete, &__read {
    display: inline-block;
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    background-color: transparent;
    outline: 0;
    margin: 9px 0;
    text-align: left;

    @include media-breakpoint-up(md) {
      &:hover {
        color: $color-accent;
      }

      &:focus {
        color: $color-text;
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-text;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }

  &__category {
    font-size: 13px;
    line-height: 20px;
    color: $color-text;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__title {
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    margin-right: 48px;
    margin-bottom: 12px;

    span {
      color: $color-link;
      text-decoration: underline;
      text-decoration-line: underline;
      text-decoration-thickness: 1px;
    }
  }

  &__source {
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__time {
    font-size: 13px;
    line-height: 20px;
    color: $color-text-secondary;
  }
}