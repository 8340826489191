.btn-acknowledgment {
  color: $btn-acknowledgment-color;
  margin-left: 8px;

  &--left {
    margin-left: auto;
    margin-right: 0;

    .btn-acknowledgment__tooltip {
      left: -170px;
      transform: translate(0, 0);

      &::after {
        left: 180px;
      }
    }
  }

  &.confirmed {
    color: $color-success;

    .confirm-button {
      display: none;
    }
    .confirm-label {
      display: block;
    }

    .btn-acknowledgment__btn {
      color: $color-success;
      border-color: $color-success;

      .ico-svg {
        color: $color-success;
      }
    }
  }
  
  .confirm-label {
    display: none;
    cursor: help;
  }

  &__btn {
    color: $btn-acknowledgment-color;
    border: solid 1px $btn-acknowledgment-color;
    border-radius: $border-radius-bean;
    padding: 6px 8px;

    .ico-svg {
      max-width: 24px;
      max-height: 24px;
      color: $btn-acknowledgment-color;
      margin-right: 8px;
    }
  
    span {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: 1;
    }
  }

  &__view {
    position: relative;

    .ico-svg {
      min-width: 20px;
      max-width: 20px;
      max-height: 20px;
      margin-right: 0;
    }

    &:hover, &:focus {
      .btn-acknowledgment__tooltip {
        display: block;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 30px;
    z-index: 4;
    width: 200px;
    font-size: $font-size-small;
    line-height: $line-height-default;
    color: $color-text-secondary;
    background-color: $background-light;
    box-shadow: $box-shadow-default;
    padding: 15px;
    transform: translate(-50%, 0);

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -5px;
      width: 10px;
      height: 10px;
      background-color: $background-light;
      transform: rotate(-135deg);
      margin-left: -5px;
    }
  }
}