.intranet-navigation {
  @include font-family($font-family-primary, semibold);
  font-size: $font-size-small;
  line-height: 40px;
  background-color: $nav-bg-color;

  @include media-breakpoint-down(md) {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 2;
    max-height: 0;
    overflow: hidden;
    background-color: $nav-bg-color-mobile;
    border-radius: 0;
    transition: $transition-cubic;
    
    &.open {
      height: calc(100vh - 50px);
      max-height: 2000px;
      overflow-y: auto;
    }

    nav {
      border-top: solid 1px rgba($color-border-light, 0.08);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }
  }

  &__list {
    list-style-type: none;
    min-height: 40px;

    &--megamenu {
      @include media-breakpoint-up(lg) {

        .intranet-navigation__item {
          position: static;

          &.open {
            .intranet-navigation__link {
              padding-bottom: 0;
              border-bottom: 2px solid $color-border-accent;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        .intranet-navigation__item {
          &.open {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    padding: 0 20px;
    
    @include media-breakpoint-down(md) {
      padding-bottom: 10px;

      &.open {
        .intranet-navigation__submenu {
          max-height: 5000px;
        }
        .intranet-megamenu {
          max-height: 5000px;
        }

        .ico-svg {
          transform: rotate(180deg);
        }
      }

      .ico-svg {
        max-width: 16px;
        max-height: 16px;
      }
    }
    
    @include media-breakpoint-up(lg) {

      &--active {
        .intranet-navigation__link {
          color: $nav-text-color-hover;
        }
      }
     
      &.open {
        .intranet-navigation__submenu {
          display: block;
        }
        .intranet-megamenu {
          transform: translateY(0px);
          transition: transform 0.5s ease 0.1s; 
        }
      }

      > .intranet-navigation__link {
        border-bottom: 0;
      }
    }
  }

  &__link {
    display: block;
    @include font-family($font-family-primary, medium);
    line-height: 40px;
    color: $nav-text-color;

    @include media-breakpoint-down(md) {
      display: inline-block;
      font-size: 28px;
      color: $nav-text-color-mobile;

      &:hover, &--active {
        color: $nav-text-color-mobile;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $nav-text-color-mobile;
      }
    }

    @include media-breakpoint-up(lg) {
      @include font-family($font-family-primary, medium);
      font-size: $font-size-default;
      line-height: 36px;
      padding-top: 2px;
      padding-bottom: 2px;

      &:hover, &--active {
        color: $nav-text-color-hover;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $nav-text-color-hover;
      }
    }

  }
  
  .ico-arrow {
    &::after {
      border-width: 0 2px 2px 0;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 15px;
    color: $nav-text-color-mobile;
    background-color: transparent;
    padding: 0 10px;
  }

  &__submenu {
    color: $nav-text-color;

    @include media-breakpoint-up(lg) {
      display: none;
      width: 230px;
      position: absolute;
      left: 50%;
      z-index: 99;
      margin-left: -115px;

      &::before {
        content: "";
        position: absolute;
        top: 11px;
        left: 50%;
        width: 0;
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $background-light;
        margin-left: -10px;
      }
    }

    @include media-breakpoint-down(md) {
      max-height: 0;
      transition: $transition-cubic;
      overflow: hidden;

      .intranet-navigation__link {
        font-size: 22px;
      }
  
      .ico-arrow {
        &::after {
          content: none;
        }
      }
    }

    ul {

      @include media-breakpoint-up(lg) {
        min-height: 270px;
        background-color: $background-light;
        border-radius: $border-radius;
        padding: 30px;
        margin-top: 20px;
      }

      @include media-breakpoint-down(md) {
        font-size: $font-size-default;
        margin-left: 20px;
        margin-bottom: 20px;
      }

      li {
        list-style-type: none;

        &.active {
          a {
            color: $color-link;
          }
        }
        
        a {
          line-height: 1.4;
          letter-spacing: $nav-text-letter-spacing-submenu;
          color: $nav-text-color-submenu;
          padding-top: 10px;
          padding-bottom: 10px;

          @include media-breakpoint-down(md) {
            color: $nav-text-color-mobile;
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              color: $color-link;
              text-decoration: underline;
            }

            &:focus {
              text-decoration: $focus-text-decoration;
              outline: auto;
              outline-style: $focus-outline-style;
              outline-width: $focus-outline-width;
              outline-color: $color-link;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__smartphone {
      padding: 0 20px 20px 20px;

      .switch-color-scheme {
        margin-top: 24px;
        margin-left: 0;
      }
    }
  }
}