.box-corsi {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  padding: 16px 16px 24px 16px;

  &[data-link], &[data-toggle] {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &__icon {
    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 16px;
    }

    .ico-svg {
      max-height: 40px;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      color: $color-text;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__category {
    font-size: 13px;
    line-height: 18px;
    color: $color-text;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    
    p {
      font-size: 13px;
      line-height: 18px;
    }

    label {
      @include font-family($font-family-primary, bold);
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      text-decoration: underline;
    }
  }

  &__progress {
    border-top: solid 1px $color-border-dark;
    padding-top: 16px;
    margin-top: 16px;

    .timeline {
      position: relative;
      width: 145px;
      height: 6px;
      background-color: $color-border-dark;
      border-radius: 4px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: inline-block;
        height: 6px;
        background-color: $color-accent;
        border-radius: 4px;
      }
    }
  }
}