.box-avviso {
  position: relative;
  height: calc(100% - 24px);
  display: flex;
  align-items: start;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px;
  margin: 0 15px;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    color: $color-text-contrast;
    text-align: center;
    text-transform: uppercase;
    background-color: $background-primary;
    border-radius: $border-radius-date;
    margin-right: 12px;
  }

  &__day {
    @include font-family($font-family-primary, bold);
    font-size: 20px;
    line-height: 1;
    color: $color-text-contrast;
    margin-bottom: 4px;
  }
  
  &__month {
    @include font-family($font-family-primary, regular);
    font-size: 16px;
    line-height: 1;
    color: $color-text-contrast;
  }

  &__title, &__link {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 16px;
    line-height: 22px;
    color: $color-text-headings;
    
    @media (min-width: 1400px) {
      font-size: 18px;
      line-height: 24px;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent;
    }
  }

  &__info {
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 20px;
    color: $color-text;
  }
  
  &__edit {
    color: $color-primary-base;
    margin-left: 8px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-primary-base;
    }
  }

  .btn-acknowledgment__tooltip {
    background-color: $background-medium;

    &::after {
      background-color: $background-medium;
    }
  }
}