.box-category {
  position: relative;
  height: calc(100% - 32px);
  @include font-family($font-family-primary, semibold);
  font-size: 18px;
  line-height: 1.2;
  color: $color-text;
  background-color: $background-light;
  border-bottom-left-radius: $border-radius-card;
  border-bottom-right-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 24px;
  padding-right: 86px;
  margin-top: 8px;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    height: 8px;
    background-color: $background-primary;
    border-top-left-radius: $border-radius-card;
    border-top-right-radius: $border-radius-card;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }
  
  &__name {
    @include font-family($font-family-primary, semibold);
    font-size: 18px;
    line-height: 1.2;
    color: $color-text;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 24px;
    width: 38px;
    min-width: 38px;
    height: 38px;
    min-height: 38px;
    display: flex;
    transform: translateY(-50%);
    color: $category-text-color;
    background-color: $category-bg-color;
    border-radius: 50%;
    padding: 8px;
  }
}