.section {
  padding: $section-spacing-size 0;

  @include media-breakpoint-down(sm) {
    padding: $section-spacing-size-sm 0;
  }

  &--light {
    background-color: $background-light;
  }

  &--small {
    padding: 40px 0;
  }

  &--two-cols {
    .intranet-card {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__page-title {
    @include font-family($font-family-secondary, semibold);
    color: $section-page-title-color;
    font-size: 34px;
    line-height: 1;
    word-break: break-word;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  &__notify {
    position: relative;
    margin-left: 12px;
    
    &.open {
      .section__submenu {
        display: block;
      }
    }

    .section__submenu {
      width: 280px;
      right: -25px;
    }

    .notify-link {
      @include font-family($font-family-primary, semibold);
      font-size: 20px;
      line-height: 26px;
      color: $color-text;
  
      &:hover {
        color: $color-text;
        text-decoration: none;
      }

      &:focus {
        color: $color-text;
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-text;
      }

      span {
        @include font-family($font-family-primary, regular);
        font-size: 14px;
        line-height: 26px;
        color: $color-link;
        text-decoration: underline;
        text-transform: uppercase;
        margin-left: 12px;
  
        &:hover {
          text-decoration: none;
        }

        &:focus {
          text-decoration: $focus-text-decoration;
          outline: auto;
          outline-style: $focus-outline-style;
          outline-width: $focus-outline-width;
          outline-color: $color-link;
        }
      }
    }
  }

  &__notify-ico {
    height: 22px;
    font-size: $font-size-small;
    color: $notification-num-text-color;
    text-transform: uppercase;
    background: $notification-num-bg-color;
    border-radius: 11px;
    white-space: nowrap;
    padding: 0 10px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
    }
  }

  &__title {
    @include font-family($font-family-secondary, medium);
    font-size: 20px;
    line-height: 30px;
    color: $section-title-color;
    word-break: break-word;
    margin-bottom: 16px;

    &--arrow {
      display: inline-block;
      position: relative;
      padding-right: 24px;
  
      &::after {
        content: "";
        position: absolute;
        top: 9px;
        right: 8px;
        width: 12px;
        height: 12px;
        border-top: solid 2px $section-title-color;
        border-left: solid 2px $section-title-color;
        transform: rotate(135deg);
      }
  
      &:hover {
        text-decoration: underline;
      }
  
      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }

  &__link {
    display: inline-block;
    position: relative;
    @include font-family($font-family-primary, semibold);
    line-height: 30px;
    color: $section-link-text-color;
    white-space: nowrap;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border-left: solid 1px $color-border-medium;
    outline: 0;
    padding-left: 20px;
    margin-left: 20px;
    margin-bottom: 16px;

    @include media-breakpoint-only(lg) {
      line-height: 24px;
      margin-bottom: 24px;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      font-size: 14px;
      line-height: 18px;
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 16px;
    }

    &:first-child {
      border-left: 0;
      padding-left: 0;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }

    &--icon {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-text;
      }

      span {
        text-decoration: underline;
      }

      .ico-svg {
        max-width: 16px;
        max-height: 16px;
        margin-left: 4px;
      }
    }
  }
  
  &__description {
    margin-top: $section-description-spacing-size/2;
    margin-bottom: $section-description-spacing-size;

    @include media-breakpoint-down(sm) {
      margin-top: $section-description-spacing-size-sm/2;
      margin-bottom: $section-description-spacing-size-sm;
    }
  }

  &__btn {
    display: flex;
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid $color-border-medium;
    color: $color-border-primary;
    padding: 7px;
    margin-left: 20px;

    &:hover, &.active {
      color: $color-border-light;
      background-color: $background-primary;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-border-light;
    }
  }

  &__actions {
    position: relative;
    
    @include media-breakpoint-up(sm) {
      top: 10px;
      margin-left: 15px;
    }
    
    &.open {
      .section__button {
        color: $color-text-contrast;
        background-color: $background-accent-dark;
      }
      .section__submenu {
        display: block;
      }
    }

    @include media-breakpoint-up(sm) {
      .section__button {
        margin-top: -20px;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: -5px;
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 270px;
    position: absolute;
    right: 0;
    z-index: 99;
    color: $color-text;
    border-radius: $border-radius;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 30px;
      }
    }

    > div {
      color: $color-text;
      background-color: $background-light;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 15px;

      p {
        color: $color-text;

        strong {
          color: $color-text;
        }
      }
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;

    a, button {
      color: $color-text-secondary;
      text-align: left;
      background-color: transparent;

      &:hover {
        color: $color-link;
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }

  &__button {
    position: relative;
    display: inline-block;
    height: 53px;
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 53px;
    color: $btn-link-text-color;
    text-transform: uppercase;
    cursor: pointer;
    background-color: $btn-link-bg-color;
    border: solid 1px $btn-link-bg-color;
    border-radius: $border-radius-btn-link;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;

    &:hover, &:focus, &--selected {
      color: $btn-link-text-color-hover;
      text-decoration: none;
      background-color: $btn-link-bg-color-hover;
      border-color: $btn-link-bg-color-hover;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $btn-link-text-color-hover;
    }

    &:disabled {
      color: $color-text-contrast;
      background-color: $background-dark;
      border-color: $color-border-dark;
      cursor: not-allowed;

      &:hover, &:focus {
        background-color: $background-dark;
      }
    }

    .ico-svg {
      max-width: 24px;
      max-height: 24px;
      margin-right: 8px;
    }
  }

  &__extra-col {
    @media (min-width: 1400px) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;

      .box-document, .article-detail__file {
        width: calc(50% - 30px);
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}
