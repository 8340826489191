.btn-birthday {

  &--only-icon {
    .btn-birthday__cta {
      display: inline-block;
      width: 32px;
      height: 32px;
      color: $btn-birthday-cta-text-color;
      background-color: $btn-birthday-cta-bg-color;
      border-radius: 50%;
      padding: 8px;

      .ico-svg {
        margin-right: 0;
      }
    }

    .btn-birthday__msg {
      width: 32px;
      height: 32px;
      color: $btn-birthday-msg-text-color;
      background-color: $btn-birthday-msg-bg-color;
      border-radius: 50%;
      padding: 8px;

      .ico-svg {
        margin-right: 0;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-small;
    line-height: 1;
    color: $btn-birthday-cta-text-color;
    background-color: $btn-birthday-cta-bg-color;
    border-radius: 24px;
    padding: 6px 12px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      color: $btn-birthday-cta-text-color;
      margin-right: 8px;
    }
  }

  &__msg {
    display: none;
    justify-content: center;
    align-items: center;
    @include font-family($font-family-primary, medium);
    font-size: $font-size-small;
    line-height: 1;
    color: $btn-birthday-msg-text-color;
    background-color: $btn-birthday-msg-bg-color;
    border-radius: 24px;
    padding: 6px 12px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      color: $btn-birthday-msg-text-color;
      margin-right: 8px;
    }

    &.active {
      display: inline-flex;
    }
  }
}