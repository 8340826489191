.box-weather {
  min-width: 160px;
  text-transform: uppercase;
  margin-right: 32px;

  @include media-breakpoint-down(xl) {
    margin-right: 16px;
  }

  .ico-svg {
    max-width: 25px;
    max-height: 20px;
    margin-right: 5px;
  }

  &__degrees {
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    margin-right: 15px;

    @include media-breakpoint-only(lg) {
      margin-right: 8px;
    }
  }
  
  &__date {
    font-size: $font-size-small;
  }
}