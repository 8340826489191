.box-financial {
  font-size: $font-size-small;
  line-height: 1;
  margin-top: 32px;

  @include media-breakpoint-down(md) {
    @include font-family($font-family-primary, regular);
    line-height: 1;
    padding-bottom: 20px;
  }
  
  &__chart {
    min-width: 16px;
    max-width: 16px;
    min-height: 20px;
    max-height: 20px;
    margin-right: 8px;
  }

  &__label {
    margin-right: 16px;

    @include media-breakpoint-only(lg) {
      display: none;
    }
  }

  &__value {
    border-bottom: solid 1px $color-text;
    margin-right: 16px;

    @include media-breakpoint-only(lg) {
      margin-right: 8px;
    }
  }

  &__trend {
    margin-right: 4px;
  }

  &__arrow {
    min-width: 10px;
    max-width: 10px;
    min-height: 20px;
    max-height: 20px;
  }
}