.box-welcome {
  &__avatar {
    position: relative;
    min-width: 96px;
    width: 96px;
    min-height: 96px;
    height: 96px;
    text-align: center;
    background-color: $background-light;
    border-radius: $border-radius-avatar;
    padding: 0;

    @include media-breakpoint-only(md) {
      min-width: 64px;
      width: 64px;
      min-height: 64px;
      height: 64px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 96px;
      width: 96px;
      height: 96px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;

      @include media-breakpoint-only(md) {
        min-width: 64px;
        width: 64px;
        height: 64px;
      }
    }
    
    .initials {
      display: block;
      @include font-family($font-family-primary, regular);
      font-size: 56px;
      line-height: 96px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;

      @include media-breakpoint-only(md) {
        font-size: 40px;
        line-height: 64px;
      }
    }
  }

  &__editphoto {
    position: absolute;
    right: -8px;
    bottom: 0;
    width: 32px;
    height: 32px;
    color: $profile-editphoto-color;
    background-color: $profile-editphoto-bg-color;
    padding: 8px;
    border-radius: 50%;
  }

  &__info {
    width: calc(100% - 120px);
    font-size: $font-size-default;
    line-height: 1;
    margin-left: 24px;

    @include media-breakpoint-only(md) {
      width: calc(100% - 88px);
    }
  }

  &__good-morning {
    font-size: $font-size-default;
    line-height: 1;
  }

  &__name {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $font-size-big;
    line-height: 1.2;
    font-weight: $bold;
  }

  &__customer {
    font-style: italic;
    margin-top: 8px;
  }

  &__link {
    display: inline-block;
    text-decoration: underline;
    color: $color-link;
    margin-top: 8px;
  }
}