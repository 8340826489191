.intranet-card {
  height: calc(100% - 24px);
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  margin: 0 15px;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;

      .intranet-card__img {
        transform: scale(1.1);
      }
    }
  }

  &--small {
    .intranet-card__header {
      padding-bottom: 75%;
    }
  }

  &--bacheca {
    cursor: pointer;
    
    .intranet-card__category {
      position: static;
    }
  }

  &--gruppi {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
    .intranet-card__category {
      position: absolute;
      bottom: 24px;
      left: 24px;
      z-index: 3;
      margin-bottom: 0;
      
      @include media-breakpoint-down(sm) {
        bottom: 24px;
        left: 24px;
      }
    }
    
    .intranet-card__body {
      position: static;
    }

    &.open {
      .intranet-card__moreinfo {
        top: 0;
      }
      
      .intranet-card__moreinfo-btn {
        .more {
          display: none;
        }
        .less {
          display: inline-block;
        }
      }
    }

    .intranet-card__title {
      min-height: 48px;
      margin-bottom: 40px;
    }

    .intranet-card__abstract {
      position: relative;
      min-height: auto;
      height: 45%;
      font-size: 13px;
      line-height: 1.5;
      overflow: hidden;
      margin-bottom: 10px;

      p {
        font-size: 13px;
        line-height: 18px;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 24px;
        background-image: linear-gradient(to bottom, rgba($background-light,0), rgba($background-light,1));
      }
    }
    .intranet-card__user {
      border-top: solid 1px $color-border-medium;
      padding-top: 19px;
      margin-bottom: 0;
    }

    .intranet-card__role {
      margin-top: 0;
    }
  }
  
  &__header {
    display: block;
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    border-top-left-radius: $border-radius-card;
    border-top-right-radius: $border-radius-card;
    
    .favourite-star {
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 3;
    }
    
    .btn-acknowledgment {
      position: absolute;
      top: 24px;
      right: 60px;
      z-index: 3;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .6s ease-in-out 0s;
  }
  
  &__notify {
    position: absolute;
    top: 26px;
    left: 37px;
    z-index: 4;

    .info-notify {
      margin-left: 0;
    }

    .info-notify__submenu {
      right: -200px;

      &::before {
        left: 40px;
        right: auto;
      }
    }
  }

  &__status {
    position: absolute;
    top: 26px;
    left: 37px;
    z-index: 3;
    height: 22px;
    line-height: 24px;
    font-size: $font-size-small;
    color: $color-text;
    text-transform: uppercase;
    background: $color-warning;
    border-radius: 11px;
    white-space: nowrap;
    padding: 0 10px;
  }

  &__type {
    display: inline-block;
    height: 22px;
    font-size: $font-size-small;
    line-height: 22px;
    color: $bacheca-type-text-color;
    text-transform: uppercase;
    background: $bacheca-type-bg-color;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
    margin-right: 8px;
  }

  &__info {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;
    font-size: 12px;
    line-height: 1;
    color: $category-text-color;
    background-color: $category-bg-color;
    border-radius: $border-radius-bean;
    padding: 4px 8px;

    .ico-svg {
      width: 20px;
      max-width: 20px;
      max-height: 14px;
      color: $category-text-color;
      margin-right: 6px;
    }
  }

  &__info-type {
    position: absolute;
    left: 24px;
    right: 12px;
    bottom: 26px;
    z-index: 3;
    height: 22px;
  }

  &__actions {
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 3;
    
    &.open {
      .intranet-card__submenu {
        display: block;
      }
    }
  }

  &__edit {
    width: 40px;
    height: 40px;
    color: $color-accent;
    background-color: $color-body;
    border: solid 1px $color-border-accent;
    border-radius: $border-radius-bean;
    padding: 8px;
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    left: -20px;
    z-index: 99;
    box-shadow: $box-shadow-default;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 35px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;
    }

    > div {
      background-color: $background-light;
      padding: 16px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;

    a, button {
      line-height: 1;
      color: $color-text-secondary;
      background-color: transparent;

      &:hover {
        color: $color-link;
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }
  
  &__body {
    position: relative;
    background-color: $background-light;
    border-bottom-left-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    padding: 16px;
  }
  
  &__category {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 12px;
    line-height: 1;
    color: $category-text-color;
    text-transform: uppercase;
    background-color: $category-bg-color;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 4px 8px;
    margin-right: 8px;
    margin-bottom: 16px;
  }
  
  &__state {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 12px;
    line-height: 1;
    color: $color-text-contrast;
    text-transform: uppercase;
    background-color: $color-info;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 4px 8px;
    margin-bottom: 16px;
  }

  &__event-date {
    position: absolute;
    top: -48px;
    left: 24px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    color: $color-text-contrast;
    text-align: center;
    text-transform: uppercase;
    background-color: $background-primary;
    border-radius: $border-radius-date;
  }

  &__day {
    @include font-family($font-family-primary, bold);
    font-size: 18px;
    line-height: 1;
    color: $color-text-contrast;
    margin-bottom: 4px;
  }
  
  &__month {
    @include font-family($font-family-primary, regular);
    font-size: 16px;
    line-height: 1;
    color: $color-text-contrast;
  }

  &__agenda {
    width: 56px;
    min-width: 56px;
    height: 56px;
    min-height: 56px;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 25px;
    color: $category-text-color;
    background-color: $category-bg-color;
    border-radius: 50%;
    outline: 0;
    padding: 16px;

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  &__abstract {
    min-height: 96px;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-default;
    line-height: 1.5;
    color: $color-text-secondary;
    margin-bottom: 16px;

    p {
      @include font-family($font-family-primary, regular);
      font-size: $font-size-default;
      line-height: 1.5;
      color: $color-text-secondary;
      margin-bottom: 0;
    }
  }

  &__address {
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
    margin-top: -12px;
  }

  &__responsabile {
    border-top: solid 1px $color-border-dark;
    padding-top: 24px;
    margin-top: 24px;
  }

  &__user {
    margin-bottom: 20px;
  }

  &__avatar {
    position: relative;
    min-width: 36px;
    width: 36px;
    height: 36px;
    @include font-family($font-family-primary, regular);
    font-size: 12px;
    line-height: 36px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 10px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      min-width: 36px;
      height: 36px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__time {
    font-size: $font-size-small;
    line-height: 1;
    color: $color-text-secondary;
    margin-bottom: 12px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
    }
  }

  &__price {
    display: block;
    height: 20px;
    @include font-family($font-family-primary, bold);
    font-size: 18px;
    line-height: 20px;
    color: $color-text;
    margin-bottom: 12px;
  }

  &__expired {
    display: inline-block;
    height: 20px;
    @include font-family($font-family-primary, regular);
    font-size: 12px;
    line-height: 19px;
    color: $color-text-contrast;
    text-transform: uppercase;
    background-color: $color-danger;
    border-radius: 20px;
    padding-top: 1px;
    padding-left: 8px;
    padding-right: 8px;
  }

  &__date {
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-text;
    margin-bottom: 8px;
  }

  &__media-info {
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-text;
    margin-bottom: 8px;
  }
  
  &__title, &__link {
    @include font-family($font-family-primary, semibold);
    font-size: 18px;
    line-height: 24px;
    color: $color-text;
    transition: color .2s ease-in-out;
    margin-bottom: 16px;
  
    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent;
    }
  }

  &__role {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 18px;
    color: $color-text;
    margin-top: 8px;

    span {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        background-color: $background-medium;
        border-radius: 50%;
      }
    }
  }

  &__email {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 1;
    margin-top: 8px;

    .ico-svg {
      width: 16px;
      height: 16px;
    }
    
    span {
      display: inline-block;
      margin-left: 4px;
    }
  }

  &__tag {
    font-size: 12px;
    line-height: 21px;
    color: $color-link;
    text-transform: uppercase;
    border-radius: $border-radius-bean;
    border: solid 1px $color-link;
    padding: 0 14px;
    margin-top: 7px;
    margin-right: 7px;
  }

  &__moreinfo {
    position: absolute;
    top: calc(100% - 50px);
    left: 0;
    right: 0;
    z-index: 99;
    height: 100%;
    cursor: auto;
    background-color: $background-light;
    transition: $transition-ease-in-out;
    transition-property: top;
    padding: 50px 30px 20px 30px;

    &-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      font-size: 13px;
      line-height: 1;
      color: $color-text;
      padding-left: 30px;
      padding-right: 30px;

      .ico-svg {
        max-width: 23px;
        max-height: 16px;
        color: $color-icon-secondary;
      }
    }
  }

  &__moreinfo-btn {
    @include font-family($font-family-primary, semibold);
    color: $color-link;
    text-transform: uppercase;

    .ico-svg {
      color: $color-link;
    }

    span {
      border-bottom: solid 1px $color-link;

      .less {
        display: none;
        border-bottom: 0;
      }

      .more {
        border-bottom: 0;
      }

    }
  }

  &__enter {
    display: block;
    position: relative;
    z-index: 100;
    height: 55px;
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 55px;
    color: $category-text-color;
    text-transform: uppercase;
    text-align: center;
    background-color: $category-bg-color;

    &:hover {
      color: $category-text-color;
      background-color: $category-bg-color;
    }

    &:focus {
      color: $category-text-color;
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $category-text-color;
    }

    &--cancel {
      color: $gruppi-btn-cancel-request-color;
      background-color: $gruppi-btn-cancel-request-bg-color;

      &:hover, &:focus {
        color: $gruppi-btn-cancel-request-color;
        background-color: $gruppi-btn-cancel-request-bg-color;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $gruppi-btn-cancel-request-color;
      }
    }
  }

  &__stars {
    margin-top: auto;

    .ico-svg {
      max-width: 13px;
      max-height: 13px;
      color: $color-border-dark;
      margin-right: 4px;

      &.active {
        color: $color-warning;
      }
    }
  }

  &__progress {
    border-top: solid 1px $color-border-dark;
    padding-top: 24px;
    margin-top: 16px;

    .timeline {
      position: relative;
      width: 145px;
      height: 6px;
      background-color: $color-border-dark;
      border-radius: 4px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: inline-block;
        height: 6px;
        background-color: $color-accent;
        border-radius: 4px;
      }
    }
  }
}