.profile {
  color: $color-text;
  
  &__row {
    background-color: $background-light;
    margin-top: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }
  
  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 44px;
    line-height: 1;
    color: $color-text;
    margin-right: 30px;

    @include media-breakpoint-down(sm) {
      font-size: 30px;
      margin-right: 12px;
      margin-bottom: 8px;
    }
  }
  
  &__box {
    padding: 0 24px;
    margin: 30px 0;

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      margin-top: 0;
    }
    
    &--award {
      @include media-breakpoint-down(md) {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &--title {
      padding-bottom: 25px;
    }

    &--border-b {
      border-bottom: 1px solid $color-border-medium;
    }

    &--border-l {
      border-left: 1px solid $color-border-medium;

      @include media-breakpoint-down(md) {
        border-left: 0;
        border-top: 1px solid $color-border-medium;
      }
    }

    &--h100 {
      height: calc(100% - 60px);
    }
  }

  &__box-title {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  &__direzione {
    margin-bottom: 8px;
  }

  &__disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
  
  &__award, &__award-border {
    text-align: center;
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 5px;
    background: $profile-award-bg-color;
    margin-right: 20px;
    margin-bottom: 16px;

    &--column {
      margin-top: 8px;
      margin-right: 0;
      margin-bottom: 30px;
    }
    
    &::before, &::after {
      content: "";
      position: absolute;
      width: 34px;
      height: 21px;
      border-radius: 5px;
      bottom: -10px;
      background: $profile-award-bg-color;
    }
    
    &::before {
      left: 2px;
      transform: rotate(40deg);
    }
    
    &::after {
      right: 2px;
      transform: rotate(-40deg);
    }
    
    .ico-svg {
      position: relative;
      color: $color-icon-light;
      max-width: 20px;
      max-height: 32px;
      margin-top: 13px;
      z-index: 10;
    }
  }
  
  &__award-border {
    position: absolute;
    min-width: 43px;
    width: 43px;
    height: 43px;
    border: 2px solid rgba($background-light,0.5);
    left: 3px;
    top: 3px;
    border-bottom: 0;
    background: transparent;
    z-index: 8;
    
    &::after, &::before {
      border-bottom: 2px solid rgba($background-light,0.5);
      background: transparent;
      width: 28px;
      bottom: -10px;
    }

    &::before {
      left: 1px;
    }

    &::after {
      right: 1px;
    }
  }
  
  &__award-title {
    @include font-family($font-family-primary, semibold);
    font-size: 16px;
    line-height: 18px;
  }

  &__award-title-dotted {
    position: relative;
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 12px;

      &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 50px;
        height: 2px;
        background-image: linear-gradient(to right, $color-text 40%, rgba(255, 255, 255, 0) 20%);
        background-position: top;
        background-size: 8px 2px;
        background-repeat: repeat-x;
        margin-top: -2px;
      }

      &::before {
        left: -65px;
      }

      &::after {
        right: -65px;
      }
    }
  }
  
  &__award-credits {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    color: $color-link;
    text-transform: uppercase;
  }

  &__score-title {
    font-size: $font-size-small;
  }

  &__score {
    font-size: 50px;
    line-height: 1;
    color: $color-link;
  }

  &__marker {

    .ico-svg {
      color: $color-accent;
      max-width: 20px;
      max-height: 26px;
      margin-right: 12px;
    }
  }

  &__details {
    font-size: $font-size-default;
    line-height: 30px;

    a {
      text-decoration: underline;
    }
  }

  &__avatar {
    position: relative;
    min-width: 110px;
    width: 110px;
    height: 110px;
    overflow: hidden;
    margin-right: 28px;

    @include media-breakpoint-down(md) {
      min-width: 90px;
      width: 90px;
      height: 90px;
    }
    
    span {
      display: inline-block;
      width: 110px;
      min-width: 110px;
      height: 110px;
      @include font-family($font-family-primary, regular);
      font-size: 30px;
      line-height: 110px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;

      @include media-breakpoint-down(md) {
        min-width: 90px;
        width: 90px;
        height: 90px;
        line-height: 90px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 110px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;

      @include media-breakpoint-down(md) {
        min-width: 90px;
        width: 90px;
        height: 90px;
      }
    }
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 24px;
    width: 24px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      height: 16px;
      width: 16px;
    }

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }
  }

  &__actions {
    display: inline-block;
    color: $color-link;
    margin-right: 12px;

    &:disabled {
      color: $color-grey-3;
      cursor: not-allowed;
    }

    .ico-svg {
      min-width: 27px;
      width: 27px;
      height: 20px;
    }
  }

  &__wrap-contact {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 1fr);

    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  &__contact {
    font-size: $font-size-default;
    line-height: 1;
    margin: 16px 0;

    @include media-breakpoint-up(sm) {
      margin: 8px 0;
    }

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      margin-right: 12px;
    }
    
    span {
      color: $color-text;
    }

    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__link {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    text-decoration: underline;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 8px;
    border-right: 1px solid $color-border-accent;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;
    }
    
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }
  }

  &__desc {
    margin-bottom: 30px;
  }

  &__edit {
    margin-bottom: 24px;
  }

  &__button {
    background-color: transparent;
    color: $color-link;
  }

  &__box-badge {
    margin-bottom: 30px;
  }

  &__badge {
    height: 22px;
    font-size: $font-size-small;
    line-height: 23px;
    color: $color-link;
    text-transform: uppercase;
    border: solid 1px $color-link;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &__kudos {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 18px;
    text-decoration: underline;

    .ico-svg {
      max-width: 36px;
      max-height: 36px;
      color: $color-accent;
      margin-right: 12px;
    }
  }

  &__credits {
    display: inline-block;
    width: 36px;
    text-align: center;
    text-decoration: none;
    margin-top: 8px;
  }
}