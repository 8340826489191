.hero-content {
  position: relative;
  z-index: 5;
  height: 380px;
  color: $hero-content-text-color;

  @include media-breakpoint-down(md) {
    height: 300px;
    background-size: cover;
  }

  @include media-breakpoint-down(xs) {
    height: 320px;
  }

  *:not(.article-detail__category) {
    color: $hero-content-text-color;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: $hero-content-opacity-color;
    opacity: $hero-content-opacity;
  }

  &__img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $hero-content-bg-color;
    background-repeat: no-repeat;
    background-position: top center;
    filter: grayscale(1);
  }

  &__info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  &__title {
    margin: 40px 0;
  }

  &__desc {
    line-height: 30px;
    margin-bottom: 12px;
  }

  .info-notify__btn {
    color: $hero-content-text-color;

    .ico-svg *, span {
      color: $hero-content-text-color;
    }
  }

  .section__actions {
    margin-top: 0;
  }

  .section__submenu *, .info-notify__submenu * {
    color: $color-text;
  }

  .section__button {
    color: $btn-link-text-color;

    &:hover *, &:focus * {
      color: $btn-link-text-color-hover;
    }

    * {
      color: $btn-link-text-color;

      &:hover {
        color: $btn-link-text-color-hover;
      }
    }
  }

  .article-detail__type {
    span {
      color: $hero-content-text-color;
    }
  }

  .article-detail__check {
    span {
      color: $hero-content-text-color;
    }
  }

  .article-detail__tag {
    color: $hero-content-text-color;
    border-color: $hero-content-text-color;
  }

  .intranet-breadcrumb {
    margin-bottom: 40px;

    &__link {
      &::after {
        border-color: $hero-content-text-color;
      }
      &:hover, &:focus {
        color: $hero-content-text-color;
        
        .ico-svg {
          color: $hero-content-text-color;
        }
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $hero-content-text-color;
      }
    }
  }
}