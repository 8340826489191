.intranet-form {
  position: relative;
  color: $color-text;

  &.open {
    .intranet-form__advanced {
      max-height: 3500px;
    }
    .intranet-form__btn-advanced {
      &::after {
        top: 22px;
        transform: rotate(-135deg);
      }
    }
  }

  &__wrapper {
    background-color: $background-light;
    border-radius: $border-radius;
    padding: 30px 25px;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }
  }

  &__section {
    border-bottom: solid 1px $color-border-medium;
    padding: 30px 0;

    img {
      max-height: 112px;
    }
  }

  &__legend {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 0;
  }

  &__advanced {
    max-height: 0;
    overflow: hidden;
    transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
  }

  &__btn-advanced {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 20px;
    color: $color-link;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: $background-light;
    padding: 15px 20px;
    padding-right: 40px;

    &::after {
      content: "";
      position: absolute;
      top: 18px;
      right: 17px;
      border: solid $color-link;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 24px;
  }

  &__subject {
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 26px;
    color: $color-text;
    margin-bottom: 24px;
  }

  &__type {
    font-size: 13px;
    line-height: 16px;
    color: $color-text;

    .ico-svg {
      max-width: 23px;
      max-height: 16px;
      color: $color-icon-secondary;
    }
  }

  &__strumenti {
    margin-bottom: 16px;

    &-desc {
      font-size: 13px;
      line-height: $line-height-default;
      margin-top: 4px;
    }
  }

  .repeating-group {
    .add-link {
      display: none;
    }

    &:first-child {
      .add-link {
        display: block;
      }
      .remove-link {
        display: none;
      }
    }

    .form-group {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    .ico-svg {
      max-width: 38px;
      max-height: 38px;
      color: $color-link;
    }
  }

  .form-group {
    margin-bottom: 24px;

    .btn-link {
      height: 37px;
      line-height: 37px;
    }
  }

  .col-form-label {
    @include font-family($font-family-primary, bold);
  }

  .form-control {
    border-color: $color-border-medium;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .radio, .checkbox {
    line-height: 1;

    div {
      font-size: 13px;
      line-height: $line-height-default;
      margin-top: 4px;
    }

    &__mark {
      top: 0;
    }
  }

  .form-select, select {
    width: 100%;
    background-color: $white;
    border: 1px solid $color-border-medium;
    border-radius: $border-radius-input-form;
    box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;

    &:disabled {
      cursor: not-allowed;
    }
  }

  textarea {
    min-height: 160px;
  }

  &__scroll {
    max-height: 300px;
    overflow: auto;
    
    @include media-breakpoint-up(md) {
      /* Firefox */
      scrollbar-color: rgba($background-dark, 0.2) $white;
      scrollbar-width: thin;

      /* Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px $color-body; 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($background-dark, 0.2); 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($background-dark, 0.5); 
      }
    }

    @include media-breakpoint-down(sm) {
      .box-person.box-person--small {
        padding: 15px 0;
      }
    }
  }

  &__footer {
    border-top: solid 1px $color-border-medium;
    padding: 30px 0;

    &-info {
      font-size: 13px;
      margin-right: 30px;
    }
  }
}