.intranet-left-nav {

  @include media-breakpoint-down(sm) {
    margin-top: 52px;
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 32px;
  }

  &__link {
    display: inline-block;
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;

    &--active {
      color: $color-text;
      border-bottom: solid 2px $color-border-accent;
    }
  }
}