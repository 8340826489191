.box-corso-group {

  &__img {
    margin-bottom: 24px;
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 26px;
    color: $color-text;
    margin-bottom: 16px;
  }
}