.box-wall {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  padding: 24px;
  padding-bottom: 16px;
  margin-top: 24px;

  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  &:first-child, &--first {
    margin-top: 0;
  }

  &__avatar {
    position: relative;
    min-width: 40px;
    width: 40px;
    height: 40px;
    @include font-family($font-family-primary, regular);
    font-size: 16px;
    line-height: 40px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    border-radius: $border-radius-avatar;
    background-color: $avatar-bg-color;
    overflow: hidden;
    margin-right: 24px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 26px;
    color: $color-text;
    margin-bottom: 12px;
  }

  &__link {
    color: $color-text;
  }

  &__date {
    @include font-family($font-family-primary, semibold);
    font-size: 13px;
    line-height: 1;
    color: $color-text-secondary;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__img {
    margin-bottom: 30px;
  }

  &__text {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
  }

  &__social {
    border-top: solid 1px $color-border-light;
    padding-top: 16px;
    margin-top: 20px;

    > div {
      flex: 1;
    }
  }

  &__edit {
    position: relative;
    
    > button {
      padding: 8px;
      margin-top: -8px;
    }

    .ico-svg {
      min-width: 20px;
      max-width: 20px;
      max-height: 20px;
      color: $color-link;
    }

    &.open {
      .box-wall__submenu {
        display: block;
      }
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    top: 45px;
    left: -180px;
    z-index: 99;
    font-size: $font-size-default;
    line-height: 1.2;
    border: solid 1px $color-border-medium;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 30px;
      z-index: -1;
      width: 20px; 
      height: 20px;
      background-color: $background-light;
      border-top: solid 1px $color-border-medium;
      border-right: solid 1px $color-border-medium;
      transform: rotate(-45deg);
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 16px 20px;
    }
  }
}