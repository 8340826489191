.box-utility-link {
  $spacing: 8px;
  display: block;
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding-bottom: calc(100% - #{2 * $spacing});
  margin: 0 $spacing 30px $spacing;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ico-svg {
    color: $box-links-icon-color;

    &:hover, &:focus {
      color: $box-links-icon-color-hover;
    }

    &:focus {
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent-dark;
    }

    @include media-breakpoint-down(md) {
      max-width: 32px;
      max-height: 32px;
    }

    @include media-breakpoint-down(sm) {
      max-width: 40px;
      max-height: 40px;
    }
  }

  &__name {
    display: block;
    color: $color-text;
    font-size: $font-size-small;
    line-height: 18px;
    margin-top: 16px;

    @include media-breakpoint-only(md) {
      line-height: 15px;
      margin-top: 8px;
    }
  }
}