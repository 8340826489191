.hero {
  position: relative;
  display: flex;
  align-items: $hero-text-align-item;
  justify-content: $hero-text-justify-content;
  height: $hero-height-img;
  background-color: $hero-bg-color;
  background-repeat: no-repeat;
  background-position: $hero-positiont-img;
  background-size: cover;
  border-radius: $border-radius;
  transition: all .6s ease-in-out 0s;
  padding: 40px;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $hero-opacity-color;
    border-radius: $border-radius;
    opacity: $hero-opacity;
  }

  &__title {
    position: relative;
    z-index: 1;
    max-width: 480px;
    @include font-family($hero-font-family, semibold);
    font-size: 44px;
    line-height: 52px;
    color: $hero-text-color;

    @include media-breakpoint-down(lg) {
      font-size: 34px;
    }
  }
}