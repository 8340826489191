.card-filter-btn {
  display: inline-block;
  height: 24px;
  @include font-family($font-family-primary, semibold);
  line-height: 24px;
  text-transform: uppercase;
  color: $color-link;
  cursor: pointer;
  border-radius: $border-radius-input-form;
  border: 1px solid $color-link;
  padding: 0 5px;
  margin-bottom: 36px;

  &.active {
    color: $color-text-contrast;
    background-color: $background-primary;
    border-color: $color-border-primary;
  }

  &--pending, &--rifiutato, &--accettato {
    position: relative;
    padding-left: 30px;
    margin-right: 16px;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 12px;
      top: 50%;
      width: 10px;
      height: 10px;
      background-color: $background-primary;
      border-radius: 50%;
      margin-top: -5px;
    }
  }

  &--pending {
    &::before {
      background-color: $color-warning;
    }
  }

  &--rifiutato {
    &::before {
      background-color: $color-danger;
    }
  }

  &--accettato {
    &::before {
      background-color: $color-success;
    }
  }

  &--moderatore {
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}
