.intranet-error-page {
  
  &__header {
    background-color: $background-light;
    padding: 32px 0;
  }

  &__main {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    padding: 120px 0;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 72px;
    line-height: 1;
    letter-spacing: -1.6px;
    color: $error-color-title;
    margin-bottom: 64px;

    @include media-breakpoint-down(sm) {
      font-size: 38px;
      margin-bottom: 28px;
    }
  }

  &__code {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    font-size: 480px;
    line-height: 380px;
    color: $background-light;
    opacity: 0.2;

    @include media-breakpoint-only(md) {
      font-size: 400px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 40vw;
      line-height: 1;
    }
  }

  &__code-label {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-text;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      font-size: 26px;
      margin-bottom: 24px;
    }
  }

  &__text {
    max-width: 650px;
    @include font-family($font-family-primary, semibold);
    font-size: 34px;
    line-height: 40px;
    margin: 0 auto 40px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }

  &__footer {
    color: $footer-text-color;
    background-color: $footer-bg-color;
    padding: 48px 0;
  }
}