.default-asset-publisher {
    .visible-interaction{
        display: block;
        right: 10%;
        position: absolute;

        @include media-breakpoint-down(xl) {
            top: 0%;
            right: 5%
        }
    }
   
}