.faccette {

  &__header {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
    font-size: 17px;
    line-height: 1;
  }

  .js-accordion__toggle {
    cursor: pointer;
    .ico-svg {
      max-width: 10px;
      max-height: 10px;
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
  
  .box-accordion {
    padding: 24px;
    border-top: 1px solid $color-border-dark;
    
    &:first-child {
      border-top: none;
    }
    
    &.open {
      .js-accordion__toggle {
        .ico-svg {
          transform: rotate(180deg);
        }
      }
    }
    
  }
  
  &__label {
    font-size: $font-size-default;
  }

  &__field {
    position: relative;
    font-size: $font-size-default;
    color: $color-text-secondary;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
    
    &:hover, &.active {
      color: $color-link;
      
      a, button {
        color: $color-link;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-link;
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }

    a, button {
      cursor: pointer;
      color: $color-text-secondary;
      background-color: transparent;

      @include media-breakpoint-only(lg) {
        font-size: 11px;
      }
    }
  }

  &__content {
    padding-top: 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li:last-child {
        .faccette__field {
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &--badge {

      ul {
        li {
          display: inline-block;

          &:last-child {
            .faccette__field {
              margin-bottom: 8px;
            }
          }
        }
      }

      .faccette__field {
        padding-left: 0;

        &:hover, &.active {
          &::before {
            content: none;
          }
        }

        a {
          position: relative;
          display: inline-block;
          height: 22px;
          font-size: $font-size-small;
          line-height: 23px;
          color: $color-text-secondary;
          text-transform: uppercase;
          white-space: nowrap;
          border: solid 1px $color-border-dark;
          border-radius: $border-radius-bean;
          padding: 0 6px;
          margin-right: 4px;
          margin-bottom: 8px;

          &.active {
            color: $color-link;
            border-color: $color-link;
            padding-left: 20px;

            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 9px;
              top: 5px;
              width: 5px;
              height: 10px;
              border: solid $color-link;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }

  &__input {
    display: block;
    width: 100%;
    font-size: $font-size-default;
    line-height: 1;
    color: $color-link;
    text-align: right;
    background-color: transparent;
  }

  &__date {
    color: $color-text;
    border-radius: $border-radius-bean;
    border: solid 1px $color-border-dark;
    padding: 12px;
    margin-bottom: 6px;

    .ico-svg {
      color: $color-border-primary;
      max-width: 16px;
      max-height: 16px;
      margin-right: 10px;
    }
  }

  &__viewall, &__viewless {
    display: block;
    margin-top: 30px;
    text-decoration: underline;
    text-transform: uppercase;
    color: $color-link;
    background-color: transparent;
    outline: 0;
    
    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }
}