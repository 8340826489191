.intranet-anchor-nav {
  font-size: $font-size-default;
  line-height: $line-height-default;
  margin-bottom: $section-spacing-size;
    
  @include media-breakpoint-down(sm) {
    margin-bottom: $section-spacing-size-sm;
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
  }

  &__link {
    display: block;
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    border-left: solid 2px $color-border-medium;
    padding: 10px 15px;

    &--active {
      @include font-family($font-family-primary, semibold);
      border-color: $color-border-accent;
    }
  }
}