// Variabili del componente
$utility-btn-size:        65px;
$utility-btn-sm-size:     57px;

.btn-scroll-top {
  opacity: 0;
  position: fixed;
  right: 10px;
  bottom: - $utility-btn-size;
  z-index: 1001;
  width: $utility-btn-size;
  height: $utility-btn-size;
  color: $btn-scoll-top-text-color;
  background-color: $btn-scoll-top-bg-color;
  border-radius: 50%;
  transition: $transition-ease-in-out;
  transition-property: left, right, bottom, top, opacity;

  @include media-breakpoint-down(md) {
    bottom: 10px;
    width: $utility-btn-sm-size;
    height: $utility-btn-sm-size;
  }
  
  @include media-breakpoint-iphonex-landscape {
    right: 10px;
    bottom: 10px;
  }

  .ico-svg {
    color: $btn-scoll-top-text-color;
    max-width: 20px;
    max-height: 20px;

    @include media-breakpoint-down(sm) {
      max-width: 15px;
      max-height: 15px;
    }
  }

  &.visible {
    opacity: 1;
    bottom: 15px;

    @include media-breakpoint-down(md) {
      bottom: 10px;
    }
  }
}