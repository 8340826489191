.dropzone {
  border: 1px solid $color-border-medium;
  border-radius: $border-radius-input-form;
  box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
  
  .dz-preview.dz-error .dz-error-message {
    display: none;
  }

  .dz-message {
    margin: 45px 0;
  }

  .dz-remove {
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 20px;
    color: $color-accent;
    text-transform: uppercase;
    cursor: pointer;
    border: solid 1px $color-border-accent;
    border-radius: $border-radius-input-form;
    padding-top: 0;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0;
    margin-top: 8px;
  }
}