.intranet-social {
  
  &__list {
    margin-bottom: 0;
  }

  &__item {
    list-style-type: none;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    color: $social-text-color;

    &:hover {
      color: $social-text-color-hover;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $social-text-color;
    }

    .ico-svg {
      max-width: 28px;
      max-height: 28px;
    }
  }
}