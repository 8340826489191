.box-highlight {
  padding-top: $section-spacing-size;
    
  @include media-breakpoint-down(sm) {
    padding-top: $section-spacing-size-sm;
  }

  &__category {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    line-height: 23px;
    color: $category-text-color;
    text-transform: uppercase;
    background-color: $category-bg-color;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }

  &__date {
    font-size: $font-size-small;
    line-height: 23px;
    color: $color-text;
    text-transform: uppercase;
    margin-left: 20px;
  }

  &__title, &__link {
    @include font-family($font-family-primary, semibold);
    font-size: 44px;
    line-height: 50px;
    letter-spacing: -1px;
    color: $color-text;
    padding-bottom: 20px;

    &:hover {
      color: $color-accent;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent;
    }

    @include media-breakpoint-down(lg) {
      font-size: 34px;
      line-height: 40px;
    }

    @include media-breakpoint-down(md) {
      font-size: 28px;
      line-height: 1.5;
    }
  }

  &__link {
    padding-bottom: 0;
  }

  &__abstract {
    font-size: $font-size-big;
    line-height: 1.6;
    color: $color-text;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      font-size: $font-size-default;
      line-height: $line-height-default;
    }

    p {
      font-size: $font-size-big;
      line-height: 1.6;
      color: $color-text;

      @include media-breakpoint-down(md) {
        font-size: $font-size-default;
        line-height: $line-height-default;
      }
    }
  }

  &__avatar {
    position: relative;
    min-width: 36px;
    width: 36px;
    height: 36px;
    @include font-family($font-family-primary, regular);
    font-size: 12px;
    line-height: 36px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 10px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      min-width: 36px;
      height: 36px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__name {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 36px;
    color: $color-text;
  }

  @include media-breakpoint-down(md) {
    &__img {
      margin-bottom: 24px;
    }
  }
}