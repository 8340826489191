.box-member {
  position: relative;

  &__avatar {
    position: relative;
    min-width: 46px;
    width: 46px;
    height: 46px;
    @include font-family($font-family-primary, regular);
    font-size: 16px;
    line-height: 46px;
    border-radius: $border-radius-avatar;
    background-color: $avatar-bg-color;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 3;
    margin-right: 8px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 46px;
      width: 46px;
      height: 46px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__name {
    color: $color-text;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;

    &--boss {
      color: $color-link;
    }
  }

  &__role {
    font-size: $font-size-small;
  }

  &--connection {
    margin-left: 63px;
    margin-top: 32px;

    &::after {
      content: "";
      position: absolute;
      height: 78px;
      left: -40px;
      top: -54px;
      width: 62px;
      border-style: solid;
      border-color: $color-border-dark;
      border-width: 0 0 1px 1px;
    }
  }

  ul {
    margin: 0;
    list-style: none;
  }

  &__connection {
    position: absolute;
    width: 64px;
    height: 50%;
    left: 23px;
    bottom: 64px;
    border-left: 1px solid $color-border-medium;
  }

  &__viewall {
    margin-top: 24px;
    margin-left: 54px;

    &--colleghi {
      margin-left: 117px;
      margin-top: 8px;  
    }

    a,button {
      font-size: $font-size-small;
      text-decoration: underline;
      background-color: transparent;
      outline: 0;
      color: $color-link;
    }
  }

}