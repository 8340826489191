/*
* Intranet 80.20
* Copyright 2023, Ariadne
* Author: Davide Pillitteri
* 11/04/2023
* Bootstrap v4.3.1
* https://getbootstrap.com/docs/4.3/getting-started/introduction/
* SVG
* https://fontawesome.com/icons
*/

/* Import Modules and Variables
==================================================*/
@import 'modules/bootstrap';
@import 'modules/mixins';


/* Import Plug-In
==================================================*/
@import '~selectric/src/selectric.scss';
@import '~slick-carousel/slick/slick.scss';
@import '~@staaky/fresco/dist/css/fresco.css';
@import '~leaflet/dist/leaflet.css';
@import '~dropzone/dist/dropzone';
@import '~easy-autocomplete/dist/easy-autocomplete.min.css';
@import '~flatpickr/dist/flatpickr.min.css';


/* Import Style
==================================================*/
@import 'partials/liferay';
@import 'partials/reset';
@import 'partials/awhy';
@import 'partials/switch-color-scheme';

// .wrapper must be added to liferay #wrapper div inside portal_normal.ftl
// This is the most external container of a custom project, avoiding
// administration panel
.wrapper {
	@include font-family($font-family-primary, regular);
	font-size: $font-size-default;
  line-height: $line-height-default;
	color: $color-text;
	
	@import 'partials/styles';
	@import 'partials/btn-scroll-top';
	@import 'partials/btn-to-page';
	@import 'partials/btn-acknowledgment';
	@import 'partials/btn-birthday';
	@import 'partials/faccette';
	@import 'partials/favourite-star';
	@import 'partials/birthday-button';
	@import 'partials/loading-spinner';
	@import 'partials/section';
	@import 'partials/slider';
	@import 'partials/info-notify';
	@import 'partials/switch-toggle';

	@import 'partials/box-accordion';
	@import 'partials/box-app';
	@import 'partials/box-iscrizioni';
	@import 'partials/box-avviso';
	@import 'partials/box-bacheca';
	@import 'partials/box-bacheca-card';
	@import 'partials/box-calendar';
	@import 'partials/box-comments';
	@import 'partials/box-document';
	@import 'partials/box-financial';
	@import 'partials/box-manager';
	@import 'partials/box-notification';
	@import 'partials/box-photo-lightbox';
	@import 'partials/box-risultati';
	@import 'partials/box-utility-link';
	@import 'partials/box-weather';
	@import 'partials/box-person';
	@import 'partials/box-news';
	@import 'partials/box-corsi';
	@import 'partials/box-corso-group';
	@import 'partials/box-gruppi';
	@import 'partials/box-gruppo';
	@import 'partials/box-progress';
	@import 'partials/box-page';
	@import 'partials/box-member';
	@import 'partials/box-category';
	@import 'partials/box-activities';
	@import 'partials/box-group-activities';
	@import 'partials/box-post-activity';
	@import 'partials/box-faq';
	@import 'partials/box-elearning';
	@import 'partials/box-favorite';
	@import 'partials/box-person-contact';
	@import 'partials/box-person-birthday';
	@import 'partials/box-filiale';
	@import 'partials/box-wall';
	@import 'partials/box-portlet';
	@import 'partials/box-appuntamenti';
	@import 'partials/box-user';
	@import 'partials/box-highlight';
	@import 'partials/box-hero-welcome';
	@import 'partials/box-acknowledgment';
	@import 'partials/box-reservation';
	@import 'partials/box-links';
	@import 'partials/box-profilo';
	@import 'partials/box-kudos';
	@import 'partials/box-birthdays';
	@import 'partials/box-my-birthday';
	
	@import 'partials/intranet-breadcrumb';
	@import 'partials/intranet-card';
	@import 'partials/intranet-footer';
	@import 'partials/intranet-hamburger';
	@import 'partials/intranet-header';
	@import 'partials/intranet-logo';
	@import 'partials/intranet-pagination';
	@import 'partials/intranet-search';
	@import 'partials/intranet-social';
	@import 'partials/intranet-banner';
	@import 'partials/intranet-notification';
	@import 'partials/intranet-navigation';
	@import 'partials/intranet-anchor-nav';
	@import 'partials/intranet-child-nav';
	@import 'partials/intranet-left-nav';
	@import 'partials/intranet-search-grid';
	@import 'partials/intranet-sort';
	@import 'partials/intranet-user';
	@import 'partials/intranet-tab';
	@import 'partials/intranet-megamenu';
	@import 'partials/intranet-map';
	@import 'partials/intranet-indice';
	@import 'partials/intranet-subscribe';
	@import 'partials/intranet-form';
	@import 'partials/intranet-underground';
	@import 'partials/intranet-list-user';
	@import 'partials/intranet-error-page';
	@import 'partials/intranet-banner-card';

	@import 'partials/modal-avviso';
	@import 'partials/profile';
	@import 'partials/article-social';
	@import 'partials/article-detail';
	@import 'partials/hero';
	@import 'partials/hero-content';
	@import 'partials/checkbox';
	@import 'partials/radio';
	@import 'partials/favorite-filter';
	@import 'partials/acknowledgment-filter';
	@import 'partials/card-filter-btn';
	@import 'partials/upload-file';
	@import 'partials/calendar-input';
	@import 'partials/dropzone';
	@import 'partials/filestyle';
	@import 'partials/bootstrap-tagsinput';
	@import 'partials/iscrizioni-legend';
	@import 'partials/intro-filiale';
	@import 'partials/form-searchable';
	@import 'partials/reservations';

	@import 'partials/liferay-event-calendar';
	@import 'partials/liferay-edit-web-content';
	@import 'partials/liferay-fragments-jca';

	@import 'partials/chat-tabs';


	//restyling
	@import 'partials/sidebar';
	@import 'partials/box-welcome';
	@import 'partials/box-apps';
}

@import 'partials/dark-mode';