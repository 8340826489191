.portlet-category-facet,
.portlet-modified-facet,
.portlet-type-facet {
  .panel-group {
    border-radius: 0;

    & .panel:last-child {
      border-radius: 0;
    }
  }
}

.search-facet {
  background-color: $background-medium;
  border-width: 0;
  margin-bottom: 30px;

  .panel-title.h4 {
    border-bottom: 1px solid $color-border-primary;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    padding: 5px 50px 10px 5px;
    transition: border-bottom .4s;
    background-color: $color-white;

    .collapse-icon {
      padding: 0;
      color: $color-link;
    }
  }

  // .search-facet--show class must be added by JavaScript inside Liferay Theme
  &--show .panel-title.h4 {
    background-color: $background-medium;
    border-bottom: 0;

    .collapse-icon {
      color: $color-text-secondary;
    }
  }

  .panel-body {
    padding: 20px 5px 5px;
  }

  .field-wrapper {
    margin-bottom: 0;
  }

  .list-unstyled {
    margin: 0;
  }

  .facet-value {
    .facet-checkbox-label {
      line-height: 27px;
      color: $color-text-secondary;
      display: flex;
      align-items: center;
      margin-bottom: 0;

      .facet-term {
        display: none;
      }

      .term-name {
        font-size: $font-size-default;
        line-height: 27px;
        font-weight: 300;

        &.facet-term-selected {
          font-weight: 600;
        }
      }

      .term-count {
        font-size: 16px;
        font-weight: 600;
        margin-left: auto;
      }
    }

    > a {
      line-height: 27px;
      color: $color-text-secondary;
      display: flex;
      justify-content: space-between;

      .term-name {
        font-size: $font-size-default;
        line-height: 27px;
        font-weight: 300;

        &.facet-term-selected {
          font-weight: 600;
        }
      }

      .term-count {
        font-size: 16px;
        font-weight: 600;
      }
    }

    &[class*="tag-popularity-"] {
      font-size: inherit;
    }
  } // .facet-value

  .modified-custom-range {
    display: flex;
    flex-direction: column;
    margin: 5px 5px 0;

    .col-md-6 {
      padding-left: 0;
      padding-right: 0;
      max-width: none;

      .field-wrapper {
        display: flex;
        margin-bottom: 10px;
      }

      .control-label {
        flex-basis: 50px;
        color: $color-text-secondary;
        font-size: $font-size-default;
        line-height: 35px;
      }

      .lfr-input-date {
        .form-control {
          border: 1px solid $background-medium;
          border-radius: 0;

          &:focus {
            box-shadow: none;
            border-color: $color-border-primary;
          }
        }
      }
    }

    .modified-facet-custom-range-filter-button {
      @include font-family($font-family-primary, regular);
      font-size: $font-size-small;
      line-height: 32px;
      font-weight: 400;
      color: $color-white;
      text-transform: uppercase;
      cursor: pointer;
      background-color: $color-border-primary;
      border: 1px solid $color-border-primary;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }

  } // .modified-custom-range
} // .search-facet
