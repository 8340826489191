.intranet-tab {
  position: relative;
  z-index: 4;
  margin-bottom: $intranet-tab-spacing-size;

  @include media-breakpoint-down(sm) {
    margin-bottom: $intranet-tab-spacing-size-sm;
  }

  @include media-breakpoint-down(sm) {
    &.open {
      .intranet-tab__nav {
        display: block;
        margin-top: 8px;
      }

      .intranet-tab__buttons {
        border-bottom: none;
      }
    }
  }

  &__nav {
    position: relative;
    background-color: $background-light;
    border-radius: $border-radius-card;

    @include media-breakpoint-up(md) {
      &.has-dots {
        padding-right: 60px;

        .intranet-tab__item {
          &:last-child {
            border-right: 1px solid $color-border-dark;
          }
        }
      }

      &.open {
        .intranet-tab__others {
          display: block;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__others-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 60px;
    background-color: transparent;
    color: $color-accent;
    padding: 8px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .ico-svg {
      color: $color-accent;
    }
  }

  &__others {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1;
    width: 230px;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 25px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;
    }

    > div {
      min-height: 170px;
      background-color: $background-light;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__others-link {
    display: block;
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-secondary-base;
    padding: 8px;
    margin: 8px;

    &--active {
      color: $color-link;
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    width: 100%;
    max-width: calc(100% / 6);
    min-height: 55px;
    text-align: center;
    border-right: 1px solid $color-border-dark;
    padding-bottom: 2px;

    &:first-child {
      border-bottom-left-radius: $border-radius-card;
    }

    &:last-child {
      border-right: 0;
    }

    @include media-breakpoint-down(sm) {
      max-width: none;
    }

    @include media-breakpoint-down(sm) {
      display: block;
      border-right: none;
    }

    &--active {
      color:  $color-link;
      border-bottom: 2px solid $color-border-accent;
      padding-bottom: 0;

      @include media-breakpoint-down(sm) {
        @include font-family($font-family-primary, bold);
        border-bottom: none;
      }

      .intranet-tab__link {
        color:  $color-link;
      }
    }
  }

  &__link {
    display: block;
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 1;
    text-transform: uppercase;
    color: $color-secondary-base;
    padding: 8px;
    padding-bottom: 5px;

    &:hover {
      color: $color-link;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  &__menu {
    font-size: 18px;
    line-height: 35px;
  }

  &__button {
    width: 28px;
    height: 35px;
    outline: none;
    color: $color-accent;
    background-color: transparent;
    margin-left: 8px;
  }
}