.box-bacheca {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;

  &[data-link], &[data-toggle] {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &__icon {
    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 16px;
    }

    .ico-svg {
      color: $color-warning;
      max-height: 50px;
      min-width: 40px;
      width: 40px;
      max-width: 40px;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__type, &__price {
    font-size: 13px;
    line-height: 16px;
    color: $color-text;
    text-transform: uppercase;
  }

  &__price {
    text-transform: none;
    line-height: 20px;
    white-space: nowrap;
  }

  &__description {
    font-size: 13px;
    line-height: 18px;
    color: $color-text;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    
    p {
      font-size: 13px;
      line-height: 18px;
    }

    label {
      @include font-family($font-family-primary, bold);
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      text-decoration: underline;
    }
  }

  .favourite-star {
    margin-left: 10px;

    .ico-svg {
      max-width: 20px;
      width: 20px;
    }
  }
}