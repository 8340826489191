.portlet-sort {

  form {
    line-height: 20px;
  }

  .fieldset {
    display: block;
  }

  .form-inline {
    margin-bottom: 0;
    flex-flow: row nowrap;

    .control-label {
      font-size: $font-size-small;
      color: $color-text-secondary;
      margin: 0 20px 0 0;
      flex-grow: 0;
      flex-shrink: 0;

      @include media-breakpoint-up(lg) {
        margin: 0 20px 0 auto;
      }
    }

    .form-control {
      padding: 0;
      height: 20px;
      background-size: 12px 14px;
      color: $color-link;
      flex-grow: 0;
      width: auto;
      outline: 0;
      min-width: 180px;
      border: 0;
      border-radius: 0;
      font-size: $font-size-small;
      font-weight: 700;
      color: $color-text-secondary;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-link}' viewBox='0 0 512 512'%3e%3cpath d='M256 384c6.936-0.22 13.81-2.973 19.111-8.272l227.221-227.221c11.058-11.026 11.058-28.941 0-39.999-11.026-11.058-28.94-11.058-39.999 0l-206.333 206.333c0 0-206.333-206.333-206.333-206.333-11.059-11.058-28.973-11.058-39.999 0-11.059 11.058-11.059 28.972 0 39.999l227.221 227.221c5.3 5.3 12.174 8.053 19.111 8.272z'/%3e%3c/svg%3e");

      // LVHA order as described on MDN website with 'focus' after 'hover'
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.intranet_100 {

  .portlet-sort {
    background-color: $color-white;
	  margin-top: 0px;
  }
}

// ORDINAMENTO RICERCA
.intranet-sort{

	&__btn {
		&.liferay{
			height: 0px;
			float: right;

			.ico-svg{
				height: 40px;
			}
		}
	}

	&__resultlabel {
		margin-left: 20px;
	}

	&__submenu {
		&.liferay{
			margin-top: 18px;
		}
	}

}
