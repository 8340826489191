.slider {
  &:not(.slick-vertical) {
    line-height: 0;
    margin: 0 -15px;

    .slick-list {
      @include media-breakpoint-down(sm) {
        overflow: inherit !important;
        padding-top: 0;
        padding-left: 0 !important;
      }
    }
  
    .slick-track {
      display: flex;
      align-items: stretch;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
  
      .slick-slide {
        height: auto;
  
        > div {
          height: 100%;
  
          > div {
            height: 100%;
          }
        }
      }
    }
  }

  &.slick-dotted {
    .slick-arrow {
      margin-top: calc(($slick-dots-size / 2 + 12px) * -1); //12px (50% margin-bottom card)
    }
  }

  .slick-dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $slick-dots-size;
    width: 100%;
    list-style: none;
    line-height: 0;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      display: inline-block;
      width: $slick-dots-size;
      height: $slick-dots-size;
      cursor: pointer;
      padding: 0;

      button {
        display: block;
        width: $slick-dots-size;
        height: $slick-dots-size;
        color: transparent;
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: none;
        padding: 0;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: $slick-dots-size;
          height: $slick-dots-size;
          text-align: center;
          background-color: $slick-dots-color;
          border: solid 1px $slick-dots-border-color;
          border-radius: 50%;
          -webkit-font-smoothing: antialiased;
        }
      }

      &.slick-active {
        button {
          &::before {
            background-color: $slick-dots-active-color;
            border-color: $slick-dots-active-border-color;
          }
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 42px;
    height: 42px;
    text-indent: -9999em;
    background-color: $slick-arrow-bg-color;
    border: solid 2px $slick-arrow-border-color;
    border-radius: 50%;
    transform: translateY(-50%);
    margin-top: -12px; //12px (50% margin-bottom card)

    @include media-breakpoint-up(lg) {
      opacity: 0;
      transition: opacity .5s ease-in-out;
    }

    &::after {
      content: "";
      position: absolute;
      top: 13px;
      right: 12px;
      border: solid $slick-arrow-color;
      display: inline-block;
      border-width: 0 4px 4px 0;
      padding: 4px;
      transform: rotate(135deg);
    }

    &.slick-disabled {
      background-color: $background-dark;
      border-color: $background-dark;
    }
    
    &.slick-prev {
      left: 15px;

      @include media-breakpoint-up(lg) {
        left: 0;
      }
    }
    
    &.slick-next {
      right: 15px;

      @include media-breakpoint-up(lg) {
        right: 0;
      }

      &::after {
        transform: rotate(315deg);
        right: 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      .slick-arrow {
        opacity: 1;
      }
    }
  }

  &.slick-vertical {
    .slick-arrow {
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      margin-top: 0;

      &.slick-prev {
        top: -15px;
      }

      &.slick-next {
        top: auto;
        right: auto;
        bottom: 15px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &.slider-start-list {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;

      > div {
        position: relative;
        flex: 0 0 33.333333%;
        width: 100%;
        max-width: 33.333333%;
        padding-right: 15px;
        padding-left: 15px;

        .intranet-card {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
  
  .intranet-card.intranet-card--small,
  .box-avviso {
    .btn-acknowledgment__tooltip {
      top: 100%;
      left: auto;
      right: -24px;
      bottom: auto;
      box-shadow: 0 -4px 4px 0 rgba($background-medium, 0.1), 0 12px 16px 0 rgba($background-medium, 0.25);
      margin-top: 12px;
      transform: none;

      &::after {
        top: -5px;
        left: auto;
        right: 29px;
        bottom: auto;
        margin-left: -5px;
      }
    }
  }

  &.slider-utility-link {
    margin: 0 -8px;
  }

  &.slider-utility-link-howto {
    margin: 0 -8px;
  }

  &.slider-apps {
    margin: 0 -8px;
  }

  &.slider-persone {
    margin: 0 -8px;
  }

  &.slider-how-to-last-insert {
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }
  }

  &.slider-photogallery-detail {
    margin: $section-spacing-size 0;
    
    @include media-breakpoint-down(sm) {
      padding-bottom: $section-spacing-size-sm;
      margin: $section-spacing-size-sm 0;
    }

    .slick-arrow {
      margin-top: -$section-spacing-size/2;
    
      @include media-breakpoint-down(sm) {
        margin-top: $section-spacing-size-sm/2;
      }
    }

    .slick-list {
      overflow: hidden !important;
    }

    .slick-track {
      .slick-slide {
        > div {
          > div {
            display: flex !important;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .article-detail__img {
      width: auto;
      max-width: 100%;
      max-height: 486px;
      margin: 0 auto 16px auto;
    }
  }

  // slider favourite sidebar
  &.slider-favourite-sidebar {
    padding-bottom: 0;

    .slick-dots {
      margin-top: 16px;
    }
  }

  // slider apps sidebar
  &.slider-apps-sidebar {
    padding-bottom: 0;

    .slick-dots {
      margin-top: 16px;
    }
  }

  // slider favorite sidebar
  &.slider-favorite-sidebar {
    padding-bottom: 0;

    .slick-dots {
      margin-top: 16px;
    }
  }

  // slider link utili sidebar
  &.slider-utility-link-sidebar {
    padding-bottom: 24px;
    margin: 0 -8px;

    .slick-list, .slick-track {
      height: 100%;
    }

    .slider-slide-wrap {
      height: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fit, minmax(100px, 1fr));

      // &.slider-slide-elem-1,
      // &.slider-slide-elem-3,
      // &.slider-slide-elem-5,
      // &.slider-slide-elem-7,
      // &.slider-slide-elem-9,
      // &.slider-slide-elem-11,
      // &.slider-slide-elem-13 {
      //   grid-template-columns: 1fr;
      // }

      &.slider-slide-elem-3:not(:has(> *:nth-child(n+3))),
      &.slider-slide-elem-4:not(:has(:nth-child(3))) {
        grid-template-rows: repeat(2, 1fr);
      }

      &.slider-slide-elem-5:not(:has(> *:nth-child(n+5))),
      &.slider-slide-elem-6:not(:has(:nth-child(5))) {
        grid-template-rows: repeat(3, 1fr);
      }

      &.slider-slide-elem-7:not(:has(> *:nth-child(n+7))),
      &.slider-slide-elem-8:not(:has(:nth-child(7))) {
        grid-template-rows: repeat(4, 1fr);
      }

      &.slider-slide-elem-9:not(:has(> *:nth-child(n+9))),
      &.slider-slide-elem-10:not(:has(:nth-child(9))) {
        grid-template-rows: repeat(5, 1fr);
      }

      &.slider-slide-elem-11:not(:has(> *:nth-child(n+11))),
      &.slider-slide-elem-12:not(:has(:nth-child(11))) {
        grid-template-rows: repeat(6, 1fr);
      }

      &.slider-slide-elem-13:not(:has(> *:nth-child(n+13))),
      &.slider-slide-elem-14:not(:has(:nth-child(13))) {
        grid-template-rows: repeat(7, 1fr);
      }

      &.slider-slide-elem-15:not(:has(> *:nth-child(n+15))),
      &.slider-slide-elem-16:not(:has(:nth-child(15))) {
        grid-template-rows: repeat(8, 1fr);
      }
    }

    .slick-dots {
      margin-top: 16px;
      
      li {
        &.slick-active {
          button {
            &::before {
              background-color: $slick-dots-active-color;
            }
          }
        }

        button {
          &::before {
            background-color: $slick-dots-color;
          }
        }
      }
    }
  }

  //Slider avvisi editabili in Liferay
  &.slider-avvisi-editable {
    &.slick-dotted {
      padding-bottom: 0;
      margin-top: 0;
    }

    .slick-list {
      padding-bottom: 50px;
    }
  }

  //Slider categorie
  &.slider-categorie {
    .box-category {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
