.intranet-user {
  position: relative;
  margin-left: 32px;

  @include media-breakpoint-down(xl) {
    margin-left: 16px;
  }

  @include media-breakpoint-down(md) {
    margin-right: 15px;
  }

  &.open {
    .intranet-user__submenu {
      display: block;
    }
  }

  &__btn {
    position: relative;
    height: 32px;
    background-color: transparent;

    .avatar {
      position: relative;
      min-width: 32px;
      width: 32px;
      height: 32px;
      @include font-family($font-family-primary, regular);
      font-size: 14px;
      line-height: 32px;
      color: $header-avatar-user-color;
      text-align: center;
      background-color: $header-avatar-user-bg-color;
      border-radius: $border-radius-avatar;

      img {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 32px;
        width: 32px;
        height: 32px;
        object-fit: cover;
        object-position: center center;
        border-radius: $border-radius-avatar;
        vertical-align: initial;
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $header-avatar-user-color;
    }
  }

  &__name {
    @include font-family($font-family-primary, bold);
    font-size: $font-size-default;
    line-height: $line-height-default;
  }

  &__role {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: $line-height-default;
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    right: 0;
    z-index: 99;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 8px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 8px;
      }
    }

    > div {
      min-height: 270px;
      background-color: $background-light;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    margin: 0;
    margin-top: 30px;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;
  }

  &__link {
    color: $color-text;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }
}