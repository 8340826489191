.upload-file {

  &__initials {
    position: relative;
    min-width: 58px;
    width: 58px;
    min-height: 58px;
    height: 58px;
    background-color: $background-accent;
    border-radius: $border-radius-avatar;
    font-size: 20px;
    color: $color-text-contrast;
    text-align: center;
    text-transform: uppercase;
    line-height: 61px;
    overflow: hidden;
    margin-right: 12px;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    min-width: 58px;
    width: 58px;
    min-height: 58px;
    height: 58px;
    border-radius: $border-radius-avatar;
  }

  &__delete {
    font-size: 14px;
    line-height: 20px;
    color: $color-link;
    text-transform: uppercase;
    margin-right: 4px;
  }

  &__delete-btn {
    width: 20px;
    height: 20px;
    color: $color-link;
    background-color: transparent;
    padding: 0 3px;
    margin-left: 4px;
  }

  &__file {
    font-size: 13px;
    line-height: 20px;
    margin-top: 24px;
    margin-bottom: 30px;

    .form-control {
      padding-top: 3px;
    }
  }
}