.intranet-pagination {
  margin-bottom: 30px;

  .pagination-bar {
    min-width: 80%;

    @include media-breakpoint-up(lg) {
      min-width: auto;
    }
  }

  .pagination-items-per-page {
    margin-bottom: 20px;
    flex-basis: 40%;

    @include media-breakpoint-up(lg) {
      flex-basis: auto;
      margin-bottom: 0;
      margin-right: 30px;
    }

    .page-link {
      padding: 0;
      color: $color-text-secondary;

      span:last-child {
        color: $color-link;
      }
    }

    a {
      border: 0;
      border-radius: 0;
      height: auto;

      // LVHA order as described on MDN website with 'focus' after 'hover'
      &:hover {
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
      }

      .lexicon-icon {
        margin: 0;
        line-height: 0.25;
      }
    }

    &.show {
      .page-link {
        background-color: transparent;
      }
    }

    .dropdown-menu {
      margin: 0;
      border-radius: 0;
      border-color: $color-border-medium;
      padding: 7px;
      min-width: 100px;

      a {
        padding: 0;
        font-size: $font-size-small;
        font-weight: 300;
        color: $color-link;
      }

      &::after {
        padding-top: 0;
      }
    }
  } // .pagination-items-per-page

  .pagination-results {
    flex-basis: 60%;
    color: $color-text-secondary;
    font-size: $font-size-default;
    line-height: 20px;
    font-weight: 300;
    padding: 0;
    margin-bottom: 20px;
    text-align: right;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      flex-basis: auto;
      text-align: left;
    }
  }

  .pagination {
    border-radius: 0;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
      margin-left: 60px;
    }
  }

  .page-item {
    width: 32px;
    height: 32px;
    line-height: 32px;
    margin-left: 2px;
    margin-right: 2px;

    .page-link {
      border: 0;
      height: inherit;
      line-height: inherit;
      width: 100%;
      color: $color-text-secondary;

      &:hover {
        color: $color-link;
        background-color: transparent;
      }

      &:focus {
        box-shadow: none;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }

    &.active {
      .page-link {
        background-color: $background-primary;
        color: $color-white;
      }
    }

    &.disabled {
      .page-link {
        color: $color-text-secondary;
      }
    }

    &:last-child {
      .page-link {
        border-radius: 0;
      }
    }

    &:first-child {
      .page-link {
        border-radius: 0;
      }
    }

    // This is the ellipsis dropdown menu when you have a lot
    // of pages that permits you to choose which page to go
    &.dropdown {
      .page-link[aria-expanded="true"] {
        color: $color-white;
        background-color: $background-primary;
      }

      .dropdown-menu {
        margin: 0 0 4px;
        border-radius: 0;
        border-color: $color-border-medium;
        padding: 7px;
        min-width: 100px;
        
        .link-list {
          margin: 0;

          li {
            line-height: 20px;
          }
        }

        a {
          padding: 0;
          font-size: $font-size-small;
          font-weight: 300;
          color: $color-link;

          &:active {
            background-color: $color-white;
          }
        }

        &::after {
          padding-top: 0;
        }
      }
    }

    &.show {
      .page-link {
        background-color: $color-white;
        &:hover {
          cursor: pointer;
        }
      }
    }
  } // .page-item

  // Only for Internet Explorer 11
  @at-root {
    .ie & {
      .pagination-items-per-page {
        flex-basis: auto;
        margin-right: 20px;
      }

      .pagination-results {
        flex-basis: auto;
      }

      .pagination {
        margin-left: 60px;

        @include media-breakpoint-up(lg) {
          margin: 0;
        }
      }
    }
  }
} // .intranet-pagination
