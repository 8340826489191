.box-my-birthday {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px;
  margin-top: $section-spacing-size * 2;
  margin-bottom: $section-spacing-size;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 56px;
    min-height: 56px;
    color: $box-my-birthdayicon-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $box-my-birthdayicon-bg-color;
    border-radius: $border-radius-date;
    padding: 8px;
    margin-right: 12px;
  }

  &__text {
    padding-right: 24px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 18px;
    line-height: 24px;
    color: $color-text;
  }

  &__desc {
    font-size: $font-size-small;
    color: $color-text;

    p {
      font-size: $font-size-small;
      color: $color-text;
      margin-bottom: 0;
    }
  }

  &__avatar {
    position: relative;
    min-width: 42px;
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: $border-radius-avatar;
    border: solid 1px $color-white;
    margin-left: -8px;

    span {
      display: inline-block;
      width: 40px;
      min-width: 40px;
      height: 40px;
      @include font-family($font-family-primary, regular);
      font-size: 12px;
      line-height: 40px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center center;
      vertical-align: initial;
      border-radius: 50%;
    }
  }

  &__congratulations {
    font-size: $font-size-small;
    color: $color-text;
    text-align: right;
    margin-top: 4px;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}