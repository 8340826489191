$height-intranet-search: 42px;

.intranet-search {
  position: relative;
  
  &.open {
    .intranet-search__dropdown {
      display: block;
    }
  }
  
  @include media-breakpoint-down(md) {
    background-color: $search-bg-color-mobile;
  }

  &__choose {
    position: relative;
    max-width: 350px;
    background-color: $search-bg-color;
    border-radius: $search-type-border-radius;

    @include media-breakpoint-down(md) {
      width: calc(100% - #{$height-intranet-search});
      max-width: none;
      background-color: transparent;
    }

    &.open {
      .intranet-search__filter {
        display: block;
      }
    }
  }

  &__type {
    position: relative;
    z-index: 2;
    min-width: 112px;
    height: $height-intranet-search;
    @include font-family($font-family-primary, regular);
    font-size: 14px;
    line-height: $height-intranet-search;
    color: $search-type-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: $search-type-bg-color;
    border-radius: $search-type-border-radius;
    padding: 0px 24px 0 12px;

    @include media-breakpoint-down(md) {
      border-radius: 0;
    }

    @include media-breakpoint-down(sm) {
      max-width: 112px;
      font-size: $font-size-small;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding-left: 10px;
      padding-right: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 10px;
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $search-type-arrow-color;
      margin-top: -3px;

      @include media-breakpoint-down(sm) {
        right: 8px;
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $search-type-color;
    }
  }

  &__label {
    width: 100%;
    margin-bottom: 0;
  }

  &__text {
    min-width: 240px;
    height: $height-intranet-search;
    @include font-family($font-family-primary, regular);
    font-size: 14px;
    line-height: $height-intranet-search;
    color: $search-text-color;
    background-color: transparent;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 0 16px;

    @include media-breakpoint-down(md) {
      min-width: auto;
      width: 100%;
      color: $search-text-color-mobile;
      padding-right: 0;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-small;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: 10px;
    }

    &::placeholder {
      color: rgba($search-text-color, 0.6);

      @include media-breakpoint-down(md) {
        color: rgba($search-text-color-mobile, 0.6);
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $search-text-color;
    }
  }

  &__submit {
    width: $height-intranet-search;
    height: $height-intranet-search;
    color: $search-submit-color;

    @include media-breakpoint-down(md) {
      color: $search-submit-color-mobile;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $search-submit-color;
    }

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
    }
  }

  &__filter {
    display: none;
    position: absolute;
    top: 43px;
    left: 0;
    z-index: 99;
    width: 230px;
    color: $search-text-color-mobile;

    @include media-breakpoint-down(md) {
      left: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $search-filter-bg-color;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 30px;
      }
    }

    > div {
      background-color: $search-filter-bg-color;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 14px;
    }

    &-label {
      display: block;
      position: relative;
      color: $search-filter-text-color;
      cursor: pointer;
      user-select: none;
      padding-top: 5px;
      padding-left: 35px;
      padding-bottom: 5px;

      &:hover input ~ .intranet-search__filter-checkmark {
        background-color: $search-checkmark-bg-color;
      }

      &:hover input:checked ~ .intranet-search__filter-checkmark {
        background-color: $search-checkmark-check-bg-color;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $search-filter-text-color;
      }
    }

    &-input {
      width: 0;
      height: 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .intranet-search__filter-checkmark {
        background-color: $search-checkmark-check-bg-color;
      }

      &:checked ~ .intranet-search__filter-checkmark:after {
        display: block;
      }
    }

    &-checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      width: 24px;
      height: 24px;
      background-color: $search-checkmark-bg-color;
      border-radius: 50%;

      &::after {
        display: none;
        content: "";
        position: absolute;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        background-color: $color-border-light;
        border-radius: 50%;
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: $height-intranet-search;
    left: 0;
    right: 0; 
    z-index: 4;
    max-width: 645px;
    max-height: calc(100vh - 103px);
    overflow-y: auto;
    background-color: $color-body;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    @include media-breakpoint-down(md) {
      max-width:100%;
    }
  }

  &__box {
    padding: 4px 8px;
  }

  &__link {
    display: inline-block;
    font-size: 13px;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    padding: 4px;
    margin: 8px;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $search-filter-text-color;
    }
  }
}