.intranet-child-nav {
  margin-bottom: $intranet-child-nav-spacing-size;

  @include media-breakpoint-down(sm) {
    margin-bottom: $intranet-child-nav-spacing-size-sm;
  }

  &--small {
    margin-bottom: $intranet-child-nav-spacing-size/2;

    @include media-breakpoint-down(sm) {
      margin-bottom: $intranet-child-nav-spacing-size-sm;
    }

    .intranet-child-nav__img {
      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
  
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
      }
    }

    .intranet-child-nav__name,
    .intranet-child-nav__name a {
      font-size: 24px;
      line-height: $line-height-default;
      color: $color-text;
      margin-bottom: 40px;
    }
  }

  &__img {
    width: 100%;
    border-radius: $border-radius;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  &__name {
    @include font-family($font-family-secondary, semibold);
    font-size: 34px;
    line-height: 40px;
    color: $color-text;
    letter-spacing: -1px;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      font-size: 28px;
      line-height: 1.5;
      margin-bottom: 8px;
    }
  }

  &__desc {
    font-size: 18px;
    line-height: 1.5;

    p {
      font-size: 18px;
      line-height: 1.5;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  &__link {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 20px;
    color: $color-link;
    text-transform: uppercase;
    text-decoration: underline;

    &:hover {
      color: $color-link;
      text-decoration: none;
    }

    &:focus {
      color: $color-link;
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  &__submenu {
    margin-top: 40px;

    ul {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: $line-height-default;
      color: $color-text;
      margin: 0;

      li {
        list-style-type: none;
        @include font-family($font-family-primary, semibold);
        font-size: $font-size-default;
        line-height: $line-height-default;
        color: $color-text;
        margin-bottom: 12px;

        a {
          color: $color-text;
        }

        ul {
          @include font-family($font-family-primary, regular);
          margin-top: 12px;
          margin-bottom: 34px;

          li {
            @include font-family($font-family-primary, regular);
            
            a {
              @include font-family($font-family-primary, regular);
            }
          }
        }
      }
    }
  }
}