.box-photo-lightbox {
  position: relative;
  overflow: hidden;
  border-radius: $border-radius-card;
  margin-bottom: 30px;

  @include media-breakpoint-up(md) {
    &:hover {
      .box-photo-lightbox__img {
        transform: scale(1.1);
      }
      .box-photo-lightbox__title {
        bottom: 0;
      }
    }
  }
  
  &__link {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 75%;
    border-radius: $border-radius-card;
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $border-radius-card;
    transition: all .6s ease-in-out 0s;
  }
  
  &__title {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    color: $color-icon-light;
    background-color: rgba(0,0,0, 0.8);
    border-bottom-left-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    transition: all .6s ease-in-out 0s;
    padding: 8px 16px;
  }
}