.portlet-forms {
  padding-bottom: 16px;
  
  .ddm-form-builder-app .lfr-ddm-form-page {
    .text-secondary {
      @include font-family($font-family-primary, regular);
      font-size: $font-size-default;
      color: $color-text !important;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .form-feedback-group {
    font-size: $font-size-small;
    font-style: italic;
    margin-top: 4px;
  }

  //Multistep
  .multi-step-nav {
    padding: 0;
    margin-top: $section-spacing-size;
  }
  .multi-step-divider {
    background-color: $background-medium;
  }
  .multi-step-icon {
    background-color: $background-medium;
    color: $color-text;
  }
  .multi-step-item.active .multi-step-divider {
    background-color: $background-primary;
  }
  .multi-step-item.active .multi-step-icon,
  .nav-underline .tab.active a.multi-step-item .multi-step-icon {
    background-color: $background-primary;
    color: $color-text-contrast;
  }

  .liferay-ddm-form-field-paragraph p,
  .form-entry .liferay-ddm-form-field-paragraph p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lfr-ddm__default-page-header-description {
    display: block;
    margin-top: 8px;
  }

  //Page title & description
  .lfr-ddm-form-page-title,
  .form-builder-page-header-title.form-control,
  .form-entry .lfr-ddm-form-page-title,
  .form-entry .form-builder-page-header-title.form-control {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 1;
    color: $section-title-color;
    word-break: break-word;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 24px;
  }
  .form-builder-page-header-description.form-control,
  .lfr-ddm-form-page-description,
  .form-entry .form-builder-page-header-description.form-control,
  .form-entry .lfr-ddm-form-page-description {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    padding-left: 0;
    padding-right: 0;
  }

  //icona required
  .reference-mark {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: $line-height-default;
    font-weight: $bold;
    color: $color-danger;
  }

  .lexicon-icon {
    display: inline-block;
    fill: currentColor;
    height: 8px;
    width: 8px;
    vertical-align: middle;
    margin-top: 0;
  }

  .legend .reference-mark,
  p .reference-mark {
    line-height: 1;
    color: $color-danger;

    .lexicon-icon {
      margin-top: -8px;
    }
  }

  .btn-primary, .btn-secondary {
    display: inline-block;
    height: 53px;
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 53px;
    color: $btn-link-text-color;
    text-transform: uppercase;
    cursor: pointer;
    background-color: $btn-link-bg-color;
    border: solid 1px $btn-link-bg-color;
    border-radius: $border-radius-btn-link;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0;

    &:hover, &:focus, &--selected {
      color: $btn-link-text-color-hover;
      text-decoration: none;
      background-color: $btn-link-bg-color-hover;
      border-color: $btn-link-bg-color-hover;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $btn-link-text-color-hover;
    }

    &:disabled {
      color: $color-text-contrast;
      background-color: $background-dark;
      border-color: $color-border-dark;
      cursor: not-allowed;

      &:hover, &:focus {
        background-color: $background-dark;
      }
    }
    
    .lexicon-icon {
      height: 1em;
      width: 1em;
      margin-top: -8px;
    }

    &.lfr-ddm-form-pagination-prev {
      @include font-family($font-family-primary, semibold);
      color: $btn-link-secondary-text-color;
      background-color: $btn-link-secondary-bg-color;
      
      &:hover, &:focus, &--selected {
        color: $btn-link-secondary-text-color-hover;
        border-color: $btn-link-secondary-border-color;
        background-color: $btn-link-secondary-bg-color;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $btn-link-secondary-text-color-hover;
      }

      &:disabled {
        color: $background-dark;
        background-color: transparent;
        border-color: $background-dark;
        cursor: not-allowed;
    
        &:hover, &:focus {
          color: $background-dark;
          background-color: transparent;
          border-color: $background-dark;
        }
      }

      .lexicon-icon {
        margin-top: 0;
      }
    }
  }

  .btn-secondary {
    height: 100%;

    .clear-button {
      height: 1em;
      width: 1em;
    }
  }

  .custom-control {
    padding-left: 0;
    margin-top: 8px;
  }

  .input-group-inset-item-after,
  .form-builder-select-field .select-field-trigger {
    .lexicon-icon {
      height: 1em;
      width: 1em;
    }
  }

  legend {
    @include font-family($font-family-primary, regular);
    font-size: 15px;
    line-height: 18px;
    color: $color-text;
  }

  .ddm-form-success-page {
    padding-bottom: 80px;
  }
  
}
  
.lfr-ddm__default-page-header-title {
  color: $section-page-title-color;
}

.lfr-ddm__default-page-container {
  background-color: transparent;
  border: none;
  padding: 32px 0;

  .lfr-ddm__default-page-title {
    color: $section-page-title-color;
  }

  .lfr-ddm__default-page-buttons {
    display: none;
  }
}