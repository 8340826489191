.form-searchable {
  border: solid 1px $color-border-medium;
  border-radius: 2px;
  padding-left: 16px;
  margin-bottom: $section-spacing-size;
    
  @include media-breakpoint-down(sm) {
    margin-bottom: $section-spacing-size-sm;
  }
  
  &__text {
    width: 100%;
    font-size: $font-size-default;
    color: $color-text;
    background-color: transparent;
    padding: 15px 16px;
  }

  &__btn {
    width: 20px;
    color: $color-text;
  }
}

#box-person-searchable-container {
  .box-person-contact {
    margin-left: 0;
    margin-right: 0;
  }
}