.chat-tabs {

  .chat-panel-window {
    border-color: $color-border-primary;
    border-radius: $border-radius;
  
    .chat-panel-title {
      background-color: $chat-panel-title-bg-color;
      color: $chat-panel-title-color;
    }
  
    .chat-panel-button {
      background-color: $chat-panel-button-bg-color;
    
      &.close, &.minimize {
        &:before {
          background: $chat-panel-button-color;
        }
      }
    
      &.close {
        opacity: 0.5;
  
        &:after {
          background: $chat-panel-button-color;
        }
    
        &:hover {
          opacity: 1;
  
          &:before, &:after {
            background: $chat-panel-button-color;
          }
        }
      }
    
      &.minimize {
        opacity: 0.5;
  
        &:hover {
          opacity: 1;
  
          &:before {
            background: $chat-panel-button-color;
          }
        }
      }
    }

  }

  .chat-panel-input {
    button.message-submit {
      background: $chat-panel-input-bg-color;
      
      &:before {
        border-right: solid 3px $chat-panel-input-color;
        border-top: solid 3px $chat-panel-input-color;
      }
    }
  }

  .chat-settings {
    .ctrl-holder {
      input[type=submit] {
        @include font-family($font-family-primary, semibold);
        color: $chat-settings-text-color;
        background-color: $chat-settings-bg-color;
        border: solid 1px $chat-settings-bg-color;
      }
    }
  }
}

.chat-bar {
  div ul {
    li {
      .chat-panel-trigger {
        color: $color-text;
        background-color: $color-primary-light;
        border: solid 1px $color-body;

        &:hover {
          color: $color-text-contrast;
          background-color: $color-primary-base;
        }

        .unread {
          color: $color-white;
          background-color: $color-danger;
        }
      }

      &.selected .chat-panel-trigger {
        color: $color-text-contrast;
        background-color: $color-primary-base;
      }
    }
  }

  .chat-panel-output {
    .name {
      color: $color-text;
      background-color: $background-light;
    }

    .incoming {
      .name {
        background-color: $color-primary-light;
        color: $color-text;
      }
    }
  }
}

.chat-panel-avatar {
  span {
    color: $color-text-contrast;
    background-color: $avatar-bg-color;
    border: solid 1px $color-text-contrast;
  }
}