.intranet-search-grid {
  &__form {
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }
    
    .ico-svg {
      color: $color-icon-primary;
      max-width: 16px;
      max-height: 16px;
    }
  }

  &__subtitle {
    color: $color-text;
    margin-bottom: 24px;
  }
  
  &__text {
    font-size: $font-size-default;
    color: $color-text;
    background-color: transparent;
    border-radius: $border-radius;
    border: solid 1px $color-border-dark;
    padding: 15px 20px;
    margin-right: 20px;
  }

  &__submit {
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    color: $color-link;
    text-transform: uppercase;
    background: transparent;
  }

  &__header {
    color: $color-text;
    padding: 12px 0;
    border-bottom: 1px solid $color-border-dark;

    .ico-svg {
      color: $color-icon-primary;
      max-width: 10px;
      max-height: 10px;
      margin-left: 16px;
    }
  }

  &__toggle-sort {
    background: transparent;
  }

  &__toggle-filters {
    background: transparent;

    .ico-svg {
      transform: rotate(270deg);
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
    }

    &--open {
      color: $color-link;

      .ico-svg {
        color: $color-accent;
        transform: rotate(90deg);
      }
    }
  }

  &__results {
    padding-top: 40px;
    flex-basis: 100% !important;
  }

  &__body {
    
    &:not(.intranet-search-grid__body--filters) {
      .intranet-search-grid__col.intranet-search-grid__col--two {
        .box-avviso {
          height: calc(100% - 24px);
          margin-bottom: 24px;
        }
      }
    }

    &--filters {
      .intranet-search-grid__filters {
        display: block;
      }

      .intranet-search-grid__results {
        flex-basis: 68.6666% !important;
        padding-left: 30px;
        
        @media (min-width: 1600px) {
          flex-basis: 75% !important;
        }

        @include media-breakpoint-down(md) {
          flex-basis: 50% !important;
        }

        @include media-breakpoint-down(sm) {
          flex-basis: 100% !important;
          border-left: 0;
          padding-left: 0;
        }
      }

      .intranet-search-grid__col {
        width: calc(100% / 2);
        
        @media (min-width: 1600px) {
          width: calc(100% / 3);
        }

        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      .intranet-search-grid__col--two {
        width: 100%;
      }
    }
  }

  &__filters {
    display: none;
    width: 33%;
    padding-top: 40px;

    @media (min-width: 1600px) {
      width: 25%;
    }

    @include media-breakpoint-down(md) {
      width: 50%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding-right: 0;
    }
  }

  .faccette {
    background-color: $background-light;
    border-radius: $border-radius;
  }

  &__badge {
    padding-bottom: 20px;

    a, button {
      @include font-family($font-family-primary, semibold);
      font-size: 14px;
      line-height: 28px;
      color: $color-link;
      text-transform: uppercase;
      background-color: transparent;
      border-radius: 16px;
      border: solid 1px $color-link;
      padding: 0 10px;
      padding-top: 1px;
      margin-right: 15px;
      margin-bottom: 15px;

      .ico-svg {
        max-width: 14px;
        max-height: 14px;
        color: $color-link;
        cursor: pointer;
        margin-left: 3px;
        margin-bottom: 2px;
      }
    }
  }

  &__list {
    margin-right: -15px;
    margin-left: -15px;

    @include media-breakpoint-up(md) {
      .box-person, .box-document, .box-news, .box-page,
      .box-wall, .box-appuntamenti, .box-reservation {
        margin-left: 15px;
        margin-right: 15px;
      }
    }

    .box-appuntamenti {
      background-color: $background-light;
      box-shadow: $box-shadow-default;
      padding: 30px;

      &__edit {
        top: 18px;
        right: 18px;
      }
    }

    .box-wall {
      margin-bottom: 16px;
    }
  }

  &__col {
    width: calc(100% / 3);

    @media (min-width: 1600px) {
      width: calc(100% / 4);
    }

    @include media-breakpoint-down(md) {
      width: calc(100% / 2);
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: 0 15px;

      .intranet-card, .box-avviso {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &__col--two {
    width: calc(100% / 2);

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 15px;
    }
  }

  &__letters {
    margin-left: -8px;

    button {
      display: inline-block;
      width: 32px;
      font-size: 21px;
      color: $color-link;
      text-align: center;
      text-transform: uppercase;
      background-color: transparent;
      border: 0;
      border-radius: $border-radius;
      padding: 4px 6px;
      margin: 0 3px 12px;

      &[disabled] {
        color: $color-secondary-light;
      }

      &.active {
        color: $btn-link-text-color;
        background-color: $background-accent;
      }
    }
  }
}
