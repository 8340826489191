.box-accordion {
  
  &.open {
    .box-accordion__body {
      max-height: 3500px;
    }
  }
  
  &__body {
    max-height: 0;
    overflow: hidden;
    transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);
  }
}