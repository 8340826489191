.article-social {
  &__info {
    display: inline-flex;
    align-items: center;
    max-width: 70px;
    flex-grow: 1;
    font-size: 12px;
    color: $color-text;
    background-color: transparent;
    outline: 0;
    margin-left: 8px;

    .ico-svg {
      width: 16px;
      max-width: 16px;
      max-height: 16px;
      color: $color-text;
      margin-right: 4px;
    }

    span {
      line-height: 24px;
    }
  }
}