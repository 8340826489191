.intranet-banner {
  background-color: $banner-bg-color;
  text-align: center;
  padding: $banner-padding-y 0;

  &__link {
    display: inline-block;
    max-width: $banner-max-width-img;
    max-height: $banner-max-height-img;
    color: $social-text-color;
    margin: $banner-margin-y-img $banner-margin-x-img;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $social-text-color;
    }
  }

  &__img {
    max-width: $banner-max-width-img;
    max-height: $banner-max-height-img;
  }
}