.reservations {

  &__date {
    position: relative;
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 22px;
    padding-left: 13px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: -3px;
      width: 8px;
      height: 8px;
      background-color: $background-accent;
      border-radius: 50%;
    }
  }

  &__list {
    border-left: solid 1px $color-border-medium;
    padding: 12px;
    padding-right: 0;

    &:first-child {
      padding-top: 24px;
    }

    &:last-child {
      padding-bottom: 24px;
    }
  }
  
  &__hour {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 1;
    margin-bottom: 8px;
  }
  
  &__info {
    position: relative;
    display: inline-block;
    font-size: $font-size-small;
    line-height: 18px;
    padding: 4px 32px 4px 0;
  }

  &__ico {
    max-width: 18px;
    max-height: 18px;
    margin-right: 8px;
  }
  
  &__edit {
    position: absolute;
    top: 50%;
    right: -8px;
    color: $color-accent;
    padding: 8px;
    margin-top: -18px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-accent;
    }

    &.open {
      .reservations__submenu {
        display: block;
      }
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    top: 45px;
    left: -160px;
    z-index: 99;
    font-size: $font-size-default;
    line-height: 1.2;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 45px;
      z-index: -1;
      width: 20px; 
      height: 20px;
      background-color: $background-light;
      border-top: solid 1px $color-border-medium;
      border-right: solid 1px $color-border-medium;
      transform: rotate(-45deg);
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 16px 20px;
    }
  }
}