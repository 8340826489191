.intranet-sort {
  position: relative;
  margin-left: 30px;

  @include media-breakpoint-down(xs) {
    margin-left: 15px;
  }

  &.open {
    .intranet-sort__submenu {
      display: block;
    }
    .intranet-sort__btn {
      .ico-svg {
        transform: rotate(180deg);
      }
    }
  }

  &__btn {
    position: relative;
    height: 40px;
    background-color: transparent;
  }

  &__submenu {
    display: none;
    width: 230px;
    position: absolute;
    right: 0;
    z-index: 99;
    color: $color-text;
    border-radius: $border-radius;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      right: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 30px;
      }
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;
  }

  &__link {
    color: $color-text-secondary;
    background-color: transparent;

    &:hover {
      color: $color-link;
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  .ico-svg {
    color: $color-text;
    max-width: 10px;
    max-height: 10px;
    margin-left: 16px;
  }
}