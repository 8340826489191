.favourite-star {
  cursor: pointer;
  margin-left: 8px;

  &__label {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    line-height: 1;
    color: $color-link;
    text-transform: uppercase;
    padding-top: 3px;
    margin-left: 15px;
  }

  .ico-svg {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    max-height: 20px;

    .fa-primary {
      color: $color-icon-light;
      fill: $color-icon-light;
    }

    .fa-secondary {
      color: $color-accent;
      fill: $color-accent;
    }

    &:hover, &:focus {
      color: $color-icon-secondary;
      fill: $color-icon-secondary;
    }
  }

  &.active {
    .ico-svg {
      .fa-primary {
        color: $color-accent;
        fill: $color-accent;
        transform: translate(-10%, -11%) scale(1.2);
      }
    }
  }

  &--button {
    border-radius: $border-radius-bean;
    border: 1px solid $color-border-primary;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;

    .ico-svg {
      order: 2;
      width: 15px;
      max-height: 15px;
    }
    
    .favourite-star__label {
      @include font-family($font-family-primary, regular);
      color: $color-link;
      margin-left: 0;
      margin-right: 7px;
    }

    &.active {
      background-color: $background-primary;
      
      .ico-svg {
        .fa-primary, .fa-secondary {
          color: $color-icon-light;
          fill: $color-icon-light;
        }
      }
      
      .favourite-star__label {
        color: $color-text-contrast;
      }
    }
  }
}
