.intranet-list-user {
  color: $color-text;
  background-color: $background-light;
  border-radius: $border-radius-card;
  padding: 24px 16px;

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &__intro {
    .checkbox {
      margin-bottom: 0;

      &__mark {
        top: 0;
      }
    }
  }

  &__row {
    border-top: solid 1px $color-border-medium;
    padding: 16px 0;
  }

  &__name {
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }

    .checkbox {
      margin-bottom: 0;

      &__mark {
        top: 0;
      }
    }
  }

  &__btn {
    @include font-family($font-family-primary, bold);
    font-size: 15px;
    line-height: 20px;
    color: $color-text;
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }

    .ico-svg {
      min-width: 12px;
      max-width: 12px;
      max-height: 12px;
      margin-left: 8px;
    }
  }

  &__status {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: $color-success;
    border-radius: 50%;

    &--pending {
      background-color: $color-warning;
    }

    &--rejected {
      background-color: $color-danger;
    }
  }

  &__actions {
    position: relative;
    color: $color-primary-base;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-primary-base;
    }

    &.open {
      .intranet-list-user__submenu {
        display: block;
      }
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 270px;
    position: absolute;
    top: 45px;
    left: -200px;
    z-index: 99;
    font-size: $font-size-default;
    line-height: 1.2;
    border: solid 1px $color-border-medium;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    @include media-breakpoint-down(xs) {
      left: -220px;
    }

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 30px;
      z-index: -1;
      width: 20px; 
      height: 20px;
      background-color: $background-light;
      border-top: solid 1px $color-border-medium;
      border-right: solid 1px $color-border-medium;
      transform: rotate(-45deg);
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 16px 20px;
    }
  }
}