.box-hero-welcome {
  position: relative;
  height: 265px;
  background-color: $welcome-bg-color;
  background-repeat: no-repeat;
  background-position: center;

  @include media-breakpoint-down(md) {
    height: auto;
    min-height: 265px;
    padding: $section-spacing-size 0;
    margin-bottom: $section-spacing-size;
  }

  @include media-breakpoint-down(xs) {
    background-size: cover;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $welcome-opacity-color;
    opacity: $welcome-opacity;
  }

  &__title, &__link {
    @include font-family($font-family-primary, semibold);
    font-size: 44px;
    line-height: $line-height-default;
    letter-spacing: -1px;
    color: $welcome-text-color;

    @include media-breakpoint-down(lg) {
      font-size: 34px;
    }
  }

  &__link {
    &:hover {
      color: $color-accent;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $welcome-text-color;
    }
  }
}