.box-apps {
  color: $box-apps-text-color;

  @include media-breakpoint-down(sm) {

    &.open {
      .box-apps__wrap {
        max-height: 3500px;
      }

      .box-apps__title {
        &::after {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__title {
    display: block;
    width: 100%;
    @include font-family($font-family-primary, bold);
    font-size: 14px;
    line-height: 1;
    color: $box-apps-text-color;
    text-align: left;
    text-transform: uppercase;
    cursor: text;
    margin-bottom: 16px;

    @include media-breakpoint-down(sm) {
      position: relative;
      cursor: pointer;
      background-color: $box-apps-bg-color;
      border-radius: $border-radius-title-box;
      padding: 16px 24px;
      margin-top: 24px;
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 16px;
        width: 16px;
        height: 16px;
        border-top: solid 1px $box-apps-text-color;
        border-left: solid 1px $box-apps-text-color;
        transform: rotate(225deg);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__wrap {
      max-height: 0;
      overflow: hidden;
      transition: $transition-cubic;
    }
  }
}