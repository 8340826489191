/* Fonts
==================================================*/
@import "~@fontsource/montserrat/300.css";
@import "~@fontsource/montserrat/400.css";
@import "~@fontsource/montserrat/500.css";
@import "~@fontsource/montserrat/600.css";
@import "~@fontsource/montserrat/700.css";

$montserrat: 'Montserrat', sans-serif;

$font-family-primary: $montserrat;
$font-family-secondary: $montserrat;

/* Colors
==================================================*/
$color-white: #fff;
$color-black: #000;

$color-primary-base: #133651;
$color-primary-light: #133651;
$color-primary-dark: #133651;

$color-secondary-base: #4C4955;
$color-secondary-light: #9E9CA5;
$color-secondary-dark: #131314;

$color-grey-1: #F5F5F5;
$color-grey-2: #EDEDED;
$color-grey-3: #E3E3E3;
$color-grey-4: #CDCDCD;

$color-accent: #E4B384;
$color-accent-light: #E4B384;
$color-accent-dark: #E4B384;

$color-danger: #D6012E;
$color-warning: #F3B653;
$color-success: #68be72;
$color-info: #63B1E5;

$color-body: $color-white;
$color-link: $color-accent;
$color-text: $color-secondary-dark;
$color-text-secondary: $color-secondary-base;
$color-text-contrast: $color-grey-1;
$color-text-headings: $color-text;


/* Theme
==================================================*/
@mixin font-family($family: $font-family-primary, $weight: regular) {
  @include font-google($family, $weight);
}

$font-size-default: 16px;
$font-size-big: 20px;
$font-size-small: 13px;

$line-height-default: 1.2;
$font-weight-text-detail: medium;

//Background
$background-primary: $color-primary-base;
$background-primary-dark: $color-primary-dark;

$background-light: $color-grey-2;
$background-medium: $color-grey-3;
$background-dark: $color-grey-4;

$background-accent: $color-accent;
$background-accent-dark: $color-accent-dark;

//Border
$color-border-primary: $color-primary-base;
$color-border-primary-dark: $color-primary-dark;
$color-border-light: $color-grey-2;
$color-border-medium: $color-grey-3;
$color-border-dark: $color-grey-4;

$color-border-accent: $color-accent;
$color-border-accent-dark: $color-accent-dark;

$color-icon-primary: $color-primary-base;
$color-icon-secondary: $color-grey-4;
$color-icon-light: $color-grey-2;
$color-icon-medium: $color-grey-3;

$color-icon-pdf: $color-primary-dark; //#EE4F3C
$color-icon-spreadsheet: $color-primary-dark; //#287E33
$color-icon-word: $color-primary-dark; //#2D56AF
$color-icon-ppt: $color-primary-dark; //#DA6637
$color-icon-video: $color-primary-dark; //#2D56AF
$color-icon-music: $color-primary-dark; //#287E33
$color-icon-image: $color-primary-dark; //#DA6637
$color-icon-archive: $color-primary-dark; //#2C6DF7
$color-icon-procedura: $color-primary-dark;
$color-icon-contenttype: $color-accent;


/* Dark mode
==================================================*/
$darkmode-color-dark: #0e0e0e;
$darkmode-color-medium: #282828;
$darkmode-color-light: #d7d6d9;
$darkmode-color-accent: $color-accent;
$darkmode-color-link: $darkmode-color-accent;


/* Effects
==================================================*/
$border-radius: 12px;
$border-radius-card: $border-radius;
$border-radius-btn-link: 30px;
$border-radius-scrollbar: 2px;
$border-radius-avatar: 50%;
$border-radius-bean: 4px;
$border-radius-input-form: $border-radius-bean;
$border-radius-date: 6px;
$border-radius-title-box: $border-radius-date;
$box-shadow-default: 0 2px 4px 0 rgba($color-black, 0.1), 0 4px 16px 0 rgba($color-black, 0.12);

$transition-cubic: .4s all cubic-bezier(0.77, 0, 0.175, 1);
$transition-ease-in-out: all .2s ease-in-out 0s;

//Focus link/button
$focus-outline-style: dotted;
$focus-outline-width: 0;
$focus-text-decoration: none;


/* Spacing/Size Components
==================================================*/
$section-spacing-size: 16px;
$section-spacing-size-sm: 12px;

$section-description-spacing-size: $section-spacing-size*2;
$section-description-spacing-size-sm: $section-spacing-size-sm*2;

$intranet-child-nav-spacing-size: $section-spacing-size*2;
$intranet-child-nav-spacing-size-sm: $section-spacing-size-sm*2;

$intranet-tab-spacing-size: $section-spacing-size;
$intranet-tab-spacing-size-sm: $section-spacing-size-sm;


/* Blink for demo
==================================================*/
$background-blink: $color-primary-base;


/* Personalizzazioni
==================================================*/
$body-bg-color: $color-body;

$notification-btn-color: $color-primary-base;
$notification-num-bg-color: $color-danger;
$notification-num-border-color: $color-text-contrast;
$notification-num-text-color: $color-text-contrast;

$box-notification-bg-color-unread: rgba($background-accent, 0.1);

$search-bg-color: rgba($background-medium, 0.5);
$search-bg-color-mobile: rgba($background-medium, 0.5);
$search-type-bg-color: $color-primary-base;
$search-type-border-radius: $border-radius;
$search-type-color: $color-text-contrast;
$search-type-arrow-color: $color-text-contrast;
$search-text-color: $color-text;
$search-text-color-mobile: $color-text;
$search-submit-color: $color-primary-base;
$search-submit-color-mobile: $color-text;
$search-filter-bg-color: $background-light;
$search-filter-shadow: $box-shadow-default;
$search-filter-text-color: $color-text;
$search-checkmark-bg-color: $color-border-medium;
$search-checkmark-check-bg-color: $color-border-primary;

$hamburger-color: $color-primary-base;
$hamburger-border-radius: 4px;

$header-bg-color: $color-body;
$header-text-color: $color-text;
$header-avatar-user-color: $color-primary-base;
$header-avatar-user-bg-color: $background-accent;

$nav-bg-color: $color-grey-1;
$nav-bg-color-mobile: $color-grey-1;
$nav-text-color: $color-text;
$nav-text-letter-spacing: normal;
$nav-text-color-hover: $color-accent;
$nav-text-color-mobile: $color-text;
$nav-text-letter-spacing-mobile: normal;
$nav-text-color-submenu: $color-text;
$nav-text-letter-spacing-submenu: normal;

$megamenu-bg-color: $background-light;

$hero-height-img: auto;
$hero-positiont-img: center;
$hero-opacity: 0;
$hero-opacity-color: transparent;
$hero-bg-color: transparent;
$hero-font-family: $font-family-secondary;
$hero-text-color: $color-text;
$hero-text-contrast-color: $color-text-contrast;
$hero-text-align-item: end;
$hero-text-justify-content: start;

$hero-content-bg-color: $color-primary-dark;
$hero-content-opacity: 0.5;
$hero-content-opacity-color: $color-primary-dark;
$hero-content-text-color: $color-text-contrast;

$box-links-bg-color: $background-medium;
$box-links-bg-color-mobile: $box-links-bg-color;
$box-links-text-color: $color-text;
$box-links-icon-color: $color-icon-primary;
$box-links-radius: 8px;
$box-links-bg-color-hover: $background-dark;
$box-links-text-color-hover: $color-text;
$box-links-icon-color-hover: $color-icon-primary;

$box-apps-bg-color: $background-medium;
$box-apps-bg-color-mobile: $box-links-bg-color;
$box-apps-text-color: $color-text;
$box-apps-icon-bg-color: $background-accent;
$box-apps-icon-color: $color-primary-base;
$box-apps-radius: 6px;
$box-apps-bg-color-hover: $background-dark;
$box-apps-text-color-hover: $color-text;
$box-apps-icon-bg-color-hover: $background-accent;
$box-apps-icon-color-hover: $color-primary-light;

$box-risultati-details-bg-color: $background-light;
$box-risultati-details-title-color: $color-text-contrast;
$box-risultati-info-bg-color: $background-dark;
$box-risultati-text-color: $color-text-contrast;
$box-risultati-text-opacity: 0.4;

$welcome-opacity: 0.4;
$welcome-opacity-color: $color-primary-dark;
$welcome-bg-color: $color-primary-dark;
$welcome-text-color: $color-text-contrast;

$footer-bg-color: $color-primary-base;
$footer-text-color: $color-text-contrast;
$footer-text-color-hover: $color-accent;
$footer-text-decoration-hover: none;
$footer-text-color-submenu: $color-text-contrast;
$footer-text-color-legal: $color-text-contrast;
$footer-text-opacity: 1;

$btn-scoll-top-bg-color: $background-medium;
$btn-scoll-top-text-color: $color-text;

$breadcrumb-text-color: $color-text-secondary;
$breadcrumb-icon-color: $color-icon-secondary;

$category-text-color: $color-primary-base;
$category-bg-color: $background-accent;

$avatar-text-color: $color-primary-base;
$avatar-bg-color: $background-accent;

$evento-month-text-color: $color-text-contrast;
$evento-month-text-bg-color: $background-accent;

$btn-link-text-color: $color-primary-base;
$btn-link-text-color-hover: $color-text-contrast;
$btn-link-bg-color: $background-accent;
$btn-link-bg-color-hover: $background-accent-dark;
$btn-link-secondary-text-color: $color-accent;
$btn-link-secondary-text-color-hover: $color-accent-dark;
$btn-link-secondary-bg-color: transparent;
$btn-link-secondary-border-color: $color-border-accent-dark;

$slick-arrow-bg-color: $background-dark;
$slick-arrow-border-color: $background-dark;
$slick-arrow-color: $color-text-contrast;
$slick-dots-size: 8px;
$slick-dots-color: $background-dark;
$slick-dots-border-color: $background-dark;
$slick-dots-active-color: $color-primary-base;
$slick-dots-active-border-color: $color-primary-base;

$bacheca-type-text-color: $color-text-contrast;
$bacheca-type-bg-color: $background-dark;

$profile-award-bg-color: linear-gradient(to bottom, $color-primary-base, $color-primary-base);
$profile-editphoto-color: $color-primary-base;
$profile-editphoto-bg-color: $background-accent;

$social-text-color: $background-accent;
$social-text-color-hover: $background-accent;

$box-person-birthday-text-color: $color-primary-dark;
$box-person-birthday-bg-color: $color-primary-light;

$box-my-birthdayicon-text-color: $color-text-contrast;
$box-my-birthdayicon-bg-color: $background-primary;

$btn-birthday-cta-text-color: $color-text;
$btn-birthday-cta-bg-color: $background-accent;
$btn-birthday-msg-text-color: $color-text;
$btn-birthday-msg-bg-color: $background-dark;

$banner-bg-color: $color-body;
$banner-padding-y: 40px;
$banner-margin-y-img: 10px;
$banner-margin-x-img: 10px;
$banner-max-width-img: 200px;
$banner-max-height-img: 66px;

$manager-link-text-color: $color-link;
$manager-name-text-color: $color-text;
$manager-role-text-color: $color-text;
$manager-actions-color: $color-link;
$manager-actions-disabled-color: $color-grey-3;

$gruppi-btn-cancel-request-color: $color-text-contrast;
$gruppi-btn-cancel-request-bg-color: $color-text;

$error-color-title: $color-primary-base;

$detail-link-text-color: $color-link;
$detail-link-text-decoration: none;
$detail-link-text-color-hover: $color-link;
$detail-link-text-decoration-hover: none;

$detail-table-border-width: 1px;
$detail-table-border: $color-border-dark;
$detail-table-th-bg-color: $color-body;
$detail-table-row-even-bg-color: transparent;
$detail-table-row-odd-bg-color: transparent;
$detail-table-cell-padding-y: 8px;
$detail-table-cell-padding-x: 16px;

$section-title-color: $color-text;
$section-page-title-color: $color-text;
$section-link-text-color: $color-link;

$banner-card-title-color: $color-text;
$banner-card-bg-color: $background-light;
$banner-card-link-color: $color-link;
$banner-card-bg-size: cover;
$banner-card-bg-position: center center;
$banner-card-min-height: 270px;
$banner-card-min-width-img: 45%;

$box-faq-border-radius: $border-radius-card;
$box-faq-bg-color: $background-light;
$box-faq-border-color: $color-border-dark;
$box-faq-font-size-title: 20px;
$box-faq-title-bg-color: $background-light;
$box-faq-title-bg-color-open: $background-primary;
$box-faq-title-color: $color-text;
$box-faq-title-color-open: $color-text-contrast;
$box-faq-arrow-color: $color-border-dark;
$box-faq-arrow-color-open: $box-faq-title-color-open;

$mypage-icon-color: $color-accent;
$mypage-icon-full-color: $color-primary-base;
$mypage-icon-full-bg-color: $color-accent;

$btn-acknowledgment-color: $color-accent;

$switch-color-scheme-bg-color: $background-medium;
$switch-color-scheme-ico-color: $color-primary-light;
$switch-color-scheme-ico-bg-color: $color-accent;

$chat-panel-title-bg-color: $color-primary-base;
$chat-panel-title-color: $color-text-contrast;
$chat-panel-button-bg-color: $background-light;
$chat-panel-button-color: $color-primary-base;
$chat-panel-input-bg-color: $color-primary-base;
$chat-panel-input-color: $color-text-contrast;
$chat-settings-text-color: $color-text;
$chat-settings-bg-color: $background-accent;
$chat-panel-trigger-color: $color-text;
$chat-panel-trigger-color-hover: $color-text-contrast;
$chat-panel-trigger-bg-color: $color-primary-light;
$chat-panel-trigger-bg-color-hover: $color-primary-base;

$sidebar-spacing-size: 16px;
$sidebar-bg-color: $background-light;
$sidebar-sm-bg-color: $sidebar-bg-color;
$sidebar-box-bg-color: $sidebar-bg-color;
$sidebar-box-sm-bg-color: $sidebar-box-bg-color;
$sidebar-box-spacing-size: $sidebar-spacing-size;
$sidebar-box-sm-spacing-size: $sidebar-box-spacing-size;
$sidebar-box-border-radius: $border-radius;
$sidebar-darkmode-bg-color: $darkmode-color-medium;
$sidebar-darkmode-box-bg-color: $darkmode-color-dark;
$sidebar-darkmode-box-sm-bg-color: $sidebar-darkmode-box-bg-color;


// Personalizzazioni tema Gruppo romano (installazione tenant)
.intranet.intranet--romano {
  .wrapper {
    .hero {
      background-size: contain;
      aspect-ratio: 2.5;
    }
  }
}