.intro-filiale {
  @include font-family($font-family-primary, regular);
  font-size: $font-size-default;
  line-height: 30px;
  color: $color-text;
  background-color: $background-light;
  border-radius: $border-radius;
  margin-top: - ($section-spacing-size - ($section-spacing-size/3));
    
  @include media-breakpoint-down(md) {
    margin-top: - $section-spacing-size-sm;
  }

  &__img {
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &__photo {
    width: 100%;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &__card {
    position: absolute;
    right: 17px;
    bottom: 39px;
    z-index: 2;
    width: 100%;
    max-width: 520px;

    @include media-breakpoint-only(md) {
      max-width: 400px;
      right: 6px;
      bottom: 24px;
    }

    @include media-breakpoint-down(sm) {
      position: relative;
      right: auto;
      bottom: auto;
      max-width: none;
      background-color: $background-light;
      padding: 16px 8px 0 72px;
      margin-bottom: 16px;
    }

    > div {
      cursor: pointer;
      border-radius: $border-radius-card;

      @include media-breakpoint-up(md) {
        position: relative;
        background-color: $background-light;
        padding: 24px;
        padding-bottom: 16px;
        margin-top: 39px;
        margin-right: 34px;
      }

      @include media-breakpoint-only(md) {
        margin-top: 30px;
        margin-right: 18px;
      }
    }

    .intro-filiale__contact {
      font-size: 13px;
      line-height: 20px;
  
      .ico-svg {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }
  
  &__avatar {
    position: absolute;
    top: -39px;
    right: -34px;
    min-width: 128px;
    width: 128px;
    height: 128px;
    overflow: hidden;

    @include media-breakpoint-only(md) {
      top: -24px;
      right: -14px;
      min-width: 80px;
      width: 80px;
      height: 80px;
      font-size: 32px;
      line-height: 80px;
    }

    @include media-breakpoint-down(sm) {
      top: 12px;
      left: 16px;
      min-width: 40px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      line-height: 40px;
    }

    span {
      display: inline-block;
      min-width: 128px;
      width: 128px;
      height: 128px;
      @include font-family($font-family-primary, regular);
      font-size: 44px;
      line-height: 128px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;

      @include media-breakpoint-only(md) {
        min-width: 80px;
        width: 80px;
        height: 80px;
        font-size: 32px;
        line-height: 80px;
      }
  
      @include media-breakpoint-down(sm) {
        min-width: 40px;
        width: 40px;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 128px;
      width: 128px;
      height: 128px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;

      @include media-breakpoint-only(md) {
        min-width: 80px;
        width: 80px;
        height: 80px;
      }
  
      @include media-breakpoint-down(sm) {
        min-width: 40px;
        width: 40px;
        height: 40px;
      }
    }
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 24px;
    width: 24px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    @include media-breakpoint-down(md) {
      height: 16px;
      width: 16px;
    }

    @include media-breakpoint-down(xs) {
      height: 12px;
      width: 12px;
    }

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }
  }
  
  &__name {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    margin-right: 94px;

    @include media-breakpoint-only(md) {
      font-size: 18px;
      line-height: 24px;
      margin-right: 60px;
    }

    @include media-breakpoint-down(sm) {
      font-size: $font-size-default;
      line-height: 21px;
    }
  }

  &__link {
    color: $color-text;
  }

  &__actions {
    display: inline-block;
    color: $color-link;
    margin-right: 12px;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    .ico-svg {
      min-width: 27px;
      width: 27px;
      height: 20px;
    }
  }
  
  &__role {
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 20px;
    color: $color-text;
    margin-top: 4px;
    margin-right: 94px;
  }

  &__contact {
    margin-top: 24px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      margin-right: 8px;
    }
    
    span {
      color: $color-text;
    }

    a {
      display: inline-block;
    }
  }

  &__phone {
    padding-right: 24px;
    padding-bottom: 8px;
  }

  &__email {
    padding-bottom: 8px;

    span {
      color: $color-link;
      text-decoration: underline;
      
      &:hover {
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }

  &__info {
    padding: 16px;
    padding-top: 30px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    
    @include media-breakpoint-down(sm) {
      background-color: $background-light;
      padding-top: 0;
    }
  }

  &__box-title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    margin: 24px 0;
  }

  &__separator {
    border-top: solid 1px $color-border-medium;
  }

  &__list {
    margin: 0;

    li {
      position: relative;
      list-style-type: none;
      padding-left: 16px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 11px;
        width: 6px;
        height: 6px;
        background-color: $background-accent;
        border-radius: 50%;
      }
    }
  }

  &__box {
    padding-right: 40px;
    padding-bottom: 24px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      margin-right: 8px;
    }
  }

  &__label {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
  }

  &__value {
    font-size: 13px;
    line-height: 21px;
    color: $color-text;
  }

  .box-person-contact {
    background-color: $background-medium;
    margin-left: 0;
    margin-right: 0;
  }
}