.box-filiale {
  position: relative;
  background-color: $background-light;
  border: solid 1px $color-border-medium;
  border-radius: $border-radius-card;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px 16px 24px 16px;
  margin-right: 15px;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
    margin-right: 0;
  }
  
  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__address {
    font-size: 13px;
    line-height: 28px;
    color: $color-text;
  }

  &__contact {
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
    margin-top: 10px;
    margin-right: 24px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }

    .ico-svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    
    span {
      color: $color-text;
    }

    a {
      display: inline-block;
    }
  }

  &__link {
    span {
      color: $color-link;
      text-decoration: underline;
      
      &:hover {
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }
}