.html-custom,
// .component-html,
.lfr-layout-structure-item-basic-component-heading,
.journal-content-article {
  @include font-family($font-family-primary, medium);
  font-size: $font-size-default;
  line-height: 30px;
  color: $color-text;
  
  h1, h2, h3, h4, h5, h6 {
    color: $color-text-headings;
    margin-bottom: 20px;
  }

  p {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 30px;
    margin-bottom: 24px;
  }

  ul, ol {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
    margin: 10px 0 20px 15px;

    li {
      @include font-family($font-family-primary, medium);
      font-size: $font-size-default;
      line-height: 30px;
      color: $color-text;
      margin-bottom: 10px;
    }
  }

  a {
    color: $detail-link-text-color;
    text-decoration: $detail-link-text-decoration;

    &:hover {
      color: $detail-link-text-color-hover;
      text-decoration: $detail-link-text-decoration-hover;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $detail-link-text-color;
    }
  }

  table {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    border-left: solid $detail-table-border-width $detail-table-border;
    border-bottom: solid $detail-table-border-width $detail-table-border;
    margin-bottom: 24px;

    tr {
      &:nth-child(even) {
        background-color: $detail-table-row-even-bg-color;
      }
      &:nth-child(odd) {
        background-color: $detail-table-row-odd-bg-color;
      }
    }

    td, th {
      color: $color-text;
      border-top: solid $detail-table-border-width $detail-table-border;
      border-right: solid $detail-table-border-width $detail-table-border;
      padding: $detail-table-cell-padding-y $detail-table-cell-padding-x;
    }

    th {
      @include font-family($font-family-primary, semibold);
      background-color: $detail-table-th-bg-color;
    }
  }
}