.bootstrap-tagsinput {
  display: block;
  min-height: 53px;
  width: 100%;
  color: #495057;
  line-height: 23px;
  cursor: text;
  background-color: $white;
  border: 1px solid $color-border-medium;
  border-radius: $border-radius-input-form;
  box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
  padding: 14px .75rem;
  padding-bottom: 0;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: $color-grey-3;
  opacity: 1;
}
.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: $color-grey-3;
}
.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: $color-grey-3;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .tag {
  display: inline-block;
  height: 22px;
  font-size: $font-size-small;
  line-height: 23px;
  color: $color-link;
  text-transform: uppercase;
  border: solid 1px $color-link;
  border-radius: $border-radius-input-form;
  white-space: nowrap;
  padding: 0 10px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255,255,255, 0.2), 0 1px 2px rgba(0,0,0, 0.05);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0,0,0, 0.125);
}