.modal-avviso {

  &.modal-edit-contact {
    .modal-form {
      .form-select {
        max-width: 35%;

        @include media-breakpoint-down(xs) {
          max-width: 40%;
        }
      }
    }
  }

  .modal-content {
    border-radius: 0;
  }
  
  .modal-header {
    height: auto;
    box-sizing: content-box;
    border-bottom-color: $color-border-medium;
  }

  .modal-title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: $line-height-default;
    color: $color-text;
    text-overflow: inherit;
    white-space: normal;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }
  
  .close {

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }

    span {
      font-size: 40px;
      color: $color-accent;
    }
  }

  .modal-body {
    padding: 30px 15px;
  }
  
  .modal-info {
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 28px;
    color: $color-text;
  }

  .modal-footer {
    border-top: 0;

    .btn-link {
      min-width: 120px;
    }
  }

  //form
  .modal-form {
    color: $color-text;

    &__title {
      @include font-family($font-family-primary, semibold);
      color: $color-text;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    .form-group {
      margin-bottom: 12px;
    }

    .col-form-label {
      @include font-family($font-family-primary, bold);
    }

    .form-select, select {
      width: 100%;
      background-color: $white;
      border: 1px solid $color-border-medium;
      border-radius: $border-radius-input-form;
      box-shadow: inset 0 1px 1px rgba(0,0,0, 0.075);
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .75rem;
    }

    textarea {
      min-height: 160px;
    }

    &__submit {
      margin-top: 24px;

      .btn-link {
        min-width: 120px;
        margin: 0 15px;
      }
    }

    .checkbox {
      &__input {
        &:checked {
          ~ .checkbox__mark {
            background-color: $color-white;
          }
        }
      }

      &__mark {
        background-color: $color-white;
      }
    }

    .radio {
      &__input {
        &:checked {
          ~ .checkbox__mark {
            background-color: $color-white;
          }
        }
      }

      &__mark {
        background-color: $color-white;
      }
    }

    &__subtitle {
      font-size: 24px;
      line-height: 30px;
      color: $color-text;
      margin-bottom: 24px;
    }

    .btn-link-action {
      margin-left: 30px;
      margin-bottom: 24px;

      &:first-child {
        margin-left: 0;
      }
    }

    &__scroll {
      height: 50vh;
      overflow: auto;
      
      @include media-breakpoint-up(md) {
        max-height: calc(100vh - 180px);
        
        /* Firefox */
        overflow-y: scroll;
        scrollbar-color: rgba($background-dark, 0.2) $white;
        scrollbar-width: thin;
  
        /* Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px $color-body; 
          border-radius: $border-radius-scrollbar;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba($background-dark, 0.2); 
          border-radius: $border-radius-scrollbar;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba($background-dark, 0.5); 
        }
      }
    }
  }
}