.intranet-indice {

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 32px;
  }

  &__results {
    border-top: solid 1px $color-border-medium;
    padding-top: 32px;
    padding-bottom: $section-spacing-size;
    margin-top: 24px;
    
    @include media-breakpoint-down(sm) {
      padding-bottom: $section-spacing-size-sm;
    }
  }

  &__list {
    column-count: 4;
    margin: 0;

    @include media-breakpoint-down(lg) {
      column-count: 3;
    }

    @include media-breakpoint-down(md) {
      column-count: 2;
    }

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }

  &__item {
    list-style-type: none;
    margin-bottom: 12px;
  }

  &__link {
    display: inline-block;
  }
}