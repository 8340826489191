.birthday-button {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-bean;
  border: 1px solid $color-border-primary;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 8px;

  &__label {
    display: inline-block;
    @include font-family($font-family-primary, regular);
    line-height: 1;
    color: $color-link;
    text-transform: uppercase;
    padding-top: 3px;
    margin-right: 7px;
  }

  .ico-svg {
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    max-height: 18px;
    color: $color-accent;
  }

  &.active {
    background-color: $background-primary;

    .birthday-button__label {
      color: $color-text-contrast;
    }

    .ico-svg {
      color: $color-text-contrast;
    }
  }
}
