.intranet_100.container {
    .notifications-portlet {
        background-color: $background-light;
        padding: 15px;
        margin-bottom: 30px;
        @at-root {
            .intranet .dropdown-item.active {
                background-color: $background-accent;
            }
        }
        h2.portlet-title-text {
            border-bottom: solid 2px $color-border-accent;
            padding: 0px;
            padding-bottom: 10px;
            margin-bottom: 30px;
            font-size: 24px;
            line-height: 1.5;
            font-weight: 300;
            text-transform: uppercase;
            max-width: 100%;
        }
        .navigation-bar-light,
        .management-bar-light {
            background-color: transparent;
        }
        .navbar.navigation-bar.navigation-bar-light,
        .taglib-empty-result-message-header {
            display: none;
        }
        .sheet.taglib-empty-result-message {
            border: none;
            padding: 0px;
        }
        li.list-group-item {
            &.list-group-item-flex {
                border: 1px solid $color-border-dark;
                background-color: $color-border-dark;
                margin-bottom: 5px;
                box-shadow: none;
                padding: 20px;
                .dropdown.lfr-icon-menu {
                    margin-left: -21px;
                }
                .autofit-col {
                    width: 0px;
                    margin: 0;
                    padding: 0px;
                }
                .user-info,
                .checkbox {
                    display: none;
                }
                a {
                    color: $color-link;
                }
                &.active {
                    border-color: $color-border-primary;
                    background-color: $background-primary;
                }
                &.list-group-item-primary {
                    border-color: $color-border-medium;
                    background-color: transparent;
                    &.active {
                        border-color: $background-primary;
                        background-color: $background-primary;
                    }
                }
            }
        }
    }
}

.box-notification__button-liferay{
	width:36px;
	height:36px;

}
.intranet-notification__scroll-liferay{
	max-height: calc(100vh - 315px) !important;

}


