.box-acknowledgment {
  background-color: $background-light;
  border-left: solid 8px $color-warning;
  box-shadow: $box-shadow-default;
  padding: 20px 30px;
  margin-bottom: $section-spacing-size;

  @include media-breakpoint-down(sm) {
    margin-bottom: $section-spacing-size-sm;
  }

  &__icon {
    width: 34px;
    min-width: 34px;
    max-width: 34px;
    height: 30px;
    color: $color-warning;
    margin-right: 20px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;
    margin-bottom: 16px;
  }

  &__link {
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 20px;
    color: $color-link;
    margin-bottom: 12px;

    span {
      color: $color-link;
      text-decoration: underline;
    }

    .ico-svg {
      max-width: 22px;
      max-height: 17px;
      margin-left: 10px;
    }

    &:hover {
      color: $color-link;

      span {
        color: $color-link;
        text-decoration: none;
      }
    }
  }

  &__confirmed {
    font-size: 15px;
    line-height: 20px;
    cursor: help;
    margin-bottom: 12px;

    .ico-svg {
      max-width: 22px;
      max-height: 17px;
      color: $color-link;
      margin-left: 10px;
    }
  }
}