.box-faq {
  border-radius: $box-faq-border-radius;
  margin-bottom: 16px;

  &.open {
    background-color: $box-faq-bg-color;

    .box-faq__title {
      color: $box-faq-title-color-open;
      background-color: $box-faq-title-bg-color-open;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .box-faq__text {
      max-height: 3500px;
    }
    
    .box-faq__arrow {
      color: $box-faq-arrow-color-open;
      border-color: $box-faq-arrow-color-open;

      .ico-svg {
        transform: rotate(180deg);
      }
    }
  }
  
  &__title {
    position: relative;
    @include font-family($font-family-primary, semibold);
    font-size: $box-faq-font-size-title;
    line-height: 1.2;
    color: $box-faq-title-color;
    background-color: $box-faq-title-bg-color;
    border-radius: $box-faq-border-radius;
    cursor: pointer;
    transition: $transition-ease-in-out;
    padding: 20px 24px;
    padding-right: 70px;
  }

  &__arrow {
    position: absolute;
    top: 15px;
    right: 24px;
    z-index: 2;
    width: 35px;
    height: 35px;
    color: $box-faq-arrow-color;
    border: solid 1px $box-faq-arrow-color;
    border-radius: 50%;
    padding: 0 10px;

    .ico-svg {
      transition: $transition-ease-in-out;
    }
  }
  
  &__text {
    max-height: 0;
    overflow: hidden;
    transition: $transition-cubic;
    background-color: $box-faq-bg-color;
    border-bottom-left-radius: $box-faq-border-radius;
    border-bottom-right-radius: $box-faq-border-radius;

    > div {
      padding: 11px 24px;
    }
  }
}