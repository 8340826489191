.box-favorite {
  color: $box-links-text-color;

  @include media-breakpoint-down(sm) {
    overflow: hidden;

    &.open {
      .box-favorite__wrap {
        max-height: 3500px;
      }

      .box-favorite__title {
        &::after {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__title {
    display: block;
    width: 100%;
    @include font-family($font-family-primary, bold);
    font-size: 14px;
    line-height: 1;
    color: $box-links-text-color;
    text-align: left;
    text-transform: uppercase;
    cursor: text;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      position: relative;
      cursor: pointer;
      background-color: $box-apps-bg-color;
      border-radius: $border-radius-title-box;
      padding: 16px 24px;
      margin-top: 24px;
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 16px;
        width: 16px;
        height: 16px;
        border-top: solid 1px $box-links-text-color;
        border-left: solid 1px $box-links-text-color;
        transform: rotate(225deg);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__wrap {
      max-height: 0;
      overflow: hidden;
      transition: $transition-cubic;
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: -20px;
    background-color: $background-light;
    padding: 14px 22px;
    z-index: 3;
  }

  &__select {
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 4px;
      display: inline-block;
      border: solid $color-black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateX(-8px);
      padding: 3px;
    }

    select {
      max-width: 144px;
      appearance: none;
      font-size: 14px;
      cursor: pointer;
      background-color: $background-light;
      border: solid 1px $background-dark;
      border-radius: $border-radius-input-form;
      padding: 4px;
      padding-right: 24px;
    }
  }

  &__list {
    height: 100%;
    max-height: 400px;
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      padding-right: 4px;

      /* Firefox */
      scrollbar-color: rgba($background-primary, 0.4);
      scrollbar-width: thin;

      /* Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px $background-medium;
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($background-primary, 0.4); 
        border-radius: $border-radius-scrollbar;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba($background-primary, 0.8); 
      }
    }
  }

  .box-document, .box-news, .box-person, .box-bacheca, .box-page {
    background-color: $background-medium;
    padding: 8px 16px;
    margin-bottom: 8px;

    &__icon {
      display: none !important;
    }
  }

  .box-person {
    &__title, &__actions {
      margin-top: 0;
    }

    &__actions {
      margin-left: 8px;
    }

    &__avatar {
      display: none !important;
    }
  }
}