.intranet-footer {
  &__top {
    color: $footer-text-color;
    background-color: $footer-bg-color;
    padding: 40px 0;

    @include media-breakpoint-down(md) {
      padding: 24px 0;
    }
  }

  &__bottom {
    @include font-family($font-family-primary, medium);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    padding: 24px 0;
  }

  &__list {
    margin: 0;

    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }

  &__item {
    font-size: 14px;
    list-style-type: none;
    border-left: solid 1px $footer-text-color;
    padding-left: 8px;
    margin-left: 8px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
    
    &--active {
      .intranet-footer__link {
        color: $footer-text-color-hover;
        text-decoration: $footer-text-decoration-hover;
      }
    }
  }

  &__link {
    @include font-family($font-family-primary, medium);
    font-size: 14px;
    color: $footer-text-color;

    &:hover {
      color: $footer-text-color-hover;
      text-decoration: $footer-text-decoration-hover;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-accent;
    }
  }
}