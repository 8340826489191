.switch-color-scheme {
  position: relative;
  display: inline-block;
  min-width: 60px;
  max-width: 60px;
  height: 34px;

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $switch-color-scheme-bg-color;
    border: solid 1px $switch-color-scheme-bg-color;
    border-radius: 34px;
    transition: .4s;
  
    span {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 3px;
      color: $switch-color-scheme-ico-color;
      background-color: $switch-color-scheme-ico-bg-color;
      border-radius: 50%;
      transition: .4s;
  
      .ico-svg {
        max-width: 14px;
        max-height: 14px;
  
        &.dark {
          display: none;
        }
      }
    }
  }
}