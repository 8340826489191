.box-bacheca-card {
  position: relative;
  background-color: $background-light;
  border: solid 1px $color-border-medium;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px;
  margin-bottom: 30px;

  &[data-link], &[data-toggle] {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__info {
    margin-bottom: 16px;
  }

  &__category {
    display: inline-block;
    height: 22px;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    line-height: 23px;
    color: $category-text-color;
    text-transform: uppercase;
    background-color: $category-bg-color;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
  }

  &__type {
    display: inline-block;
    height: 22px;
    font-size: $font-size-small;
    line-height: 24px;
    color: $bacheca-type-text-color;
    text-transform: uppercase;
    background: $bacheca-type-bg-color;
    border-radius: $border-radius-bean;
    white-space: nowrap;
    padding: 0 10px;
    margin-right: 10px;
  }

  &__price {
    @include font-family($font-family-primary, bold);
    font-size: 18px;
    color: $color-accent;
    white-space: nowrap;
    margin-right: 34px;
  }

  &__img {
    width: 100%;
    min-width: 190px;
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
      max-width: 190px;
      margin-right: 15px;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 26px;
    color: $color-text;
    margin-bottom: 15px;
  }

  &__abstract {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 24px;
    color: $color-text;
    margin-bottom: 15px;

    p {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: 24px;
      color: $color-text;
      margin-bottom: 0;
    }
  }

  &__user {
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  &__name {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
  }

  &__avatar {
    position: relative;
    min-width: 40px;
    width: 40px;
    height: 40px;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 40px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 15px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  .article-social {
    min-width: 210px;

    @include media-breakpoint-up(md) {
      min-width: 130px;

      &__info {
        margin-left: 25px;
      }
    }
  }

  .favourite-star {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
  }
}

.intranet-search-grid__body {

  @include media-breakpoint-up(md) {
    .box-bacheca-card {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &--filters {
    .box-bacheca-card {
      margin-left: 0;
    }
  }

  @include media-breakpoint-only(md) {
    &--filters {
      .box-bacheca-card {

        &__img-text {
          flex-direction: column !important;
        }

        &__img {
          max-width: none;
          margin-right: 0;
        }

        &__user {
          margin-bottom: 15px;
        }

        &__user-social {
          flex-direction: column !important;
          align-items: flex-start !important;

          .ml-md-auto {
            margin-left: 0 !important;

            .article-social {
              min-width: 210px;

              &__info {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}