.btn-to-page {
  @include font-family($font-family-primary, semibold);
  font-size: 24px;
  line-height: 30px;
  color: $color-text;
  margin-top: $section-spacing-size/2;
  margin-bottom: $section-spacing-size;
    
  @include media-breakpoint-down(sm) {
    margin-top: $section-spacing-size-sm/2;
    margin-bottom: $section-spacing-size-sm;
  }

  span {
    display: inline-block;
    border-left: solid 1px $color-border-dark;

    &:first-child {
      border-left: 0;

      .btn-to-page__link {
        margin-left: 0;
      }
    }
  }

  &__link {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-link;
    margin: 0 24px;

    &:hover, &:focus, &--active {
      color: $color-text;
    }
  }
}