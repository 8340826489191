body.intranet.dark-theme {
  background-color: $darkmode-color-dark;

  .wrapper {
    color: $darkmode-color-light;

    p {
      color: $darkmode-color-light;
    }

    a {
      color: $darkmode-color-link;

      &.btn-link {
        color: $btn-link-text-color;
      }
    }

    img {
      filter: brightness(0.6) contrast(1.2);
    }

    .intranet-header {
      background-color: $darkmode-color-dark;
    }

    .box-weather {
      color: $darkmode-color-light;
    }

    .sidebar {
      background-color: $sidebar-darkmode-bg-color;

      .box-document, .box-person, .box-person-birthday, .box-news, .box-page, .box-app, .box-corsi, .box-corso-group, .box-gruppo {
        background-color: $sidebar-darkmode-box-bg-color;

        @include media-breakpoint-down(md) {
          background-color: $sidebar-darkmode-box-sm-bg-color;
        }
      }

      .box-app {
        border-radius: $border-radius-date;
        
        &__content {
          background-color: $darkmode-color-dark;
        }
        &__name {
          color: $darkmode-color-light;
        }
      }
    }

    .box-welcome, .box-birthdays, .box-apps, .box-favorite, .box-gruppi, .box-links, .box-elearning {
      background-color: $darkmode-color-medium;
    }

    .btn-link {
      &--secondary {
        &:hover, &:focus, &--selected {
          color: $darkmode-color-link;
        }
      }
    }

    .btn-to-page {
      color: $darkmode-color-light;

      &__link {
        color: $darkmode-color-light;

        &--active {
          text-decoration: underline;
        }
      }
    }

    .btn-scroll-top {
      color: $darkmode-color-medium;
      background-color: $background-medium;

      .ico-svg {
        color: $darkmode-color-medium;
      }
    }

    .btn-link-action {
      color: $darkmode-color-link;
    }

    .table {
      color: $darkmode-color-light;
      
      th {
        color: $darkmode-color-link;
      }

      .intestazione {
        background-color: transparent;
        color: $darkmode-color-light;
      }
    }
    
    .slider {
      .slick-arrow {
        background-color: $darkmode-color-accent;

        &::after {
          border-color: $darkmode-color-medium;
        }
      }
    }

    .portlet-login {
      color: $darkmode-color-light;
      background-color: $darkmode-color-medium;

      .portlet-title-text {
        color: $darkmode-color-light;
      }

      .portlet-content {
        .portlet-header {
          .portlet-img-logo {
            filter: brightness(0) invert(1);
          }
        }

        .portlet-body {
          a {
            color: $darkmode-color-link;
          }
        }
      }

      .form-group {
        .control-label {
          color: $darkmode-color-light;
        }
      }
    }

    .portlet-document-library {
      .management-bar {
        &.management-bar-primary {
          color: $darkmode-color-light;

          .navbar-nav .nav-link:hover,
          .navbar-nav .nav-link.hover {
            color: $darkmode-color-light;
          }
        }
      }
      .management-bar-light {
        .navbar-nav {
          .nav-link {
            color: $darkmode-color-accent;

            &:hover, &:focus {
              color: $darkmode-color-light;
            }
          }
        }
      }
      .subnav-tbar-primary {
        background-color: $color-primary-base;
      }
      .breadcrumb {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
      }
      .card, .card-horizontal {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;
        border-color: $darkmode-color-medium;
      }
      .card, .card-section-header {
        color: $darkmode-color-light;

        .card-title {
          a.text-truncate {
            color: $darkmode-color-light;
          }
        }
      }
      .form-check-card:hover .card,
      .form-check-card:hover .card-section-header {
        box-shadow: none;
      }
      .form-check-card .custom-control .custom-control-input:checked ~ .card {
        box-shadow: none;
      }
      .pagination-bar {
        .pagination-items-per-page > a,
        .pagination-items-per-page > button {
          color: $darkmode-color-light;
        }
      }
      .sidenav-container {
        .sidenav-content {
          .container-fluid-max-xl {
            .navbar-nav {
              .nav-item {
                h3 {
                  color: $darkmode-color-light !important;
                }
              }
            }
          }
        }
      }
      .component-action {
        color: $darkmode-color-accent;
        &:focus {
          box-shadow: none;
        }
      }
    }

    .intranet-error-page {
      color: $darkmode-color-light;

      &__header, &__footer {
        background-color: $darkmode-color-medium;
      }

      &__title, &__text, &__code-label {
        color: $darkmode-color-light;
      }
    }

    .section {
      &--light {
        background-color: $darkmode-color-medium;
      }

      &__title, &__page-title {
        color: $darkmode-color-light;

        &--arrow {
          display: inline-block;
          position: relative;
          padding-right: 24px;

          &::after {
            border-color: $darkmode-color-light;
          }
        }
      }

      &__link {
        color: $darkmode-color-link;
      }

      &__item {
        color: $darkmode-color-light;

        a, button {
          color: $darkmode-color-light;
        }
      }

      &__submenu {
        box-shadow: none;

        &::before {
          border-bottom: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .intranet-header {
      @include media-breakpoint-down(md) {
        box-shadow: none;
      }

      &__bg {
        background-color: $darkmode-color-dark;
      }

      &__topbar {
        background-color: $darkmode-color-dark;
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-light;
      }
    }

    .intranet-search {

      @include media-breakpoint-down(md) {
        background-color: $darkmode-color-medium;
      }

      &__filter {
        box-shadow: none;

        > div {
          background-color: $darkmode-color-medium;
          box-shadow: none;
        }

        &::before {
          border-bottom-color: $darkmode-color-medium;
        }
      }

      &__filter-label {
        color: $darkmode-color-light;
      }

      &__choose {
        background-color: $darkmode-color-medium;
      }

      &__text {
        color: $darkmode-color-light;

        &::placeholder {
          color: $darkmode-color-light;
          opacity: .6;
        }
      }

      &__submit {
        color: $darkmode-color-light;

        @include media-breakpoint-down(md) {
          color: $darkmode-color-light;
        }
      }

      &__dropdown {
        background-color: $darkmode-color-dark;
        box-shadow: none;
      }
    }

    .intranet-logo {
      &__img {
        &--has-alternative {
          display: none;
        }

        &--alternative {
          display: block;
        }
      }
    }

    .intranet-navigation {
      background-color: $darkmode-color-medium;

      &__item {
        &--active {
          .intranet-navigation__link {
            color: $darkmode-color-accent;
          }
        }
      }

      &__link {
        color: $darkmode-color-light;

        &:hover, &:focus {
          color: $darkmode-color-accent;
        }
      }

      &__submenu {
        &::before {
          border-bottom-color: $darkmode-color-medium;
        }

        ul {
          background-color: $darkmode-color-medium;
          box-shadow: none;

          li {
            &.active {
              a {
                color: $darkmode-color-accent;
              }
            }

            a {
              color: $darkmode-color-light;

              &::after {
                border-color: $darkmode-color-accent;
              }
            }
          }
        }

        .intranet-navigation__link {
          color: $darkmode-color-light;
        }
      }

      .switch-color-scheme {
        &__slider {
          background-color: $darkmode-color-dark;
        }
      }
    }

    .intranet-breadcrumb {
      color: $darkmode-color-light;

      &__item, &__current, &__link {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-light;

        &::after {
          border-color: $darkmode-color-accent;
        }
      }

      .ico-svg {
        color: $darkmode-color-accent;
      }
    }

    .intranet-megamenu {
      background-color: $darkmode-color-medium;

      &__title, &__section-link, &__content-title, &__category-name {
        color: $darkmode-color-light;
      }

      &__third-level ul li a, &__category ul li a {
        color: $darkmode-color-light;
      }

      &__viewall, &__viewless {
        color: $darkmode-color-link !important;
      }

      .box-document {
        background-color: $darkmode-color-dark;
      }
    }

    .intranet-left-nav {
      &__item, &__link {
        color: $darkmode-color-light;
      }
    }

    .intranet-child-nav {
      &__name {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-link;
      }

      &--small {
        .intranet-child-nav__name,
        .intranet-child-nav__name a {
          color: $darkmode-color-light;
        }
      }

      &__submenu {
        ul {
          color: $darkmode-color-light;

          li {
            color: $darkmode-color-light;

            a {
              color: $darkmode-color-light;
            }
          }
        }
      }
    }

    .intranet-form {
      color: $darkmode-color-light;

      &__wrapper {
        background-color: $darkmode-color-medium;
        box-shadow: none;
      }

      &__title, &__subject {
        color: $darkmode-color-light;
      }

      &__btn-advanced {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;

        &::after {
          border-color: $darkmode-color-link;
        }
      }

      &__scroll {
        @include media-breakpoint-up(md) {
          &::-webkit-scrollbar-thumb {
            background-color: rgba($darkmode-color-light, 0.2);
            border-radius: $border-radius-scrollbar;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba($darkmode-color-light, 0.5);
          }
        }
      }

      &__footer-info {
        color: $darkmode-color-light;
      }

      .form-select, select {
        background-color: $darkmode-color-medium;
        box-shadow: none;
        color: $darkmode-color-light;

        option {
          background-color: $darkmode-color-medium;
          color: $darkmode-color-light;
        }
      }

      .easy-autocomplete-container {
        ul {
          color: $darkmode-color-light;
          background-color: $darkmode-color-medium;

          li {
            color: $darkmode-color-light;
            background-color: $darkmode-color-medium;

            &.selected {
              color: $darkmode-color-medium;
              background-color: $darkmode-color-light;
            }
          }
        }
      }
    }

    .form-searchable {
      &__text {
        color: $darkmode-color-light;
      }
    }

    .intranet-list-user {
      color: $darkmode-color-light;
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__btn {
        color: $darkmode-color-light;

        .ico-svg {
          color: $color-accent;
        }
      }

      &__name {
        a {
          color: $darkmode-color-light;
        }
      }

      &__submenu {
        box-shadow: none;

        &::before {
          background-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }
    
    .intranet-search-grid {
      &__header {
        color: $darkmode-color-light;

        .ico-svg {
          color: $darkmode-color-light;
        }
      }

      &__subtitle {
        color: $darkmode-color-light;
      }

      &__text {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;
      }

      &__badge {
        a, button {
          color: $darkmode-color-link;
          border-color: $darkmode-color-link;

          .ico-svg {
            color: $darkmode-color-link;
          }
        }
      }

      &__letters {
        button:not([disabled]) {
          color: $darkmode-color-link;

          &.active {
            color: initial;
          }
        }
      }

      &__toggle-filters {
        color: $darkmode-color-light;
      }

      &__form {
        .ico-svg {
          color: $darkmode-color-light;
        }
      }

      .faccette {
        background-color: $darkmode-color-medium;
        border: none;
        box-shadow: none;

        &__header {
          color: $darkmode-color-light;
        }

        &__date {
          color: $darkmode-color-light;

          .ico-svg {
            color: $darkmode-color-light;
          }
        }

        &__field, &__field a, &__field button {
          color: $darkmode-color-light;
        }

        &__input {
          color: $darkmode-color-link;
          background-color: $darkmode-color-medium;
        }

        &__viewall, &__viewless {
          color: $darkmode-color-link;
        }
      }
    }

    .intranet-sort {
      color: $darkmode-color-light;

      &__btn {
        color: $darkmode-color-light;
      }

      .ico-svg {
        color: $darkmode-color-light;
      }

      &__submenu {
        box-shadow: none;

        &::before {
          border-bottom: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }

      &__link {
        color: $darkmode-color-light;
      }
    }

    .intranet-user {
      &__name, &__role, &__link {
        color: $darkmode-color-light;
      }

      &__submenu {
        &::before {
          border-bottom: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
          box-shadow: none;
        }
      }
    }

    .intranet-subscribe {
      &__link, &__info {
        color: $darkmode-color-link;
      }

      &__tooltip {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;

        &::after {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .info-notify {

      &__item {
        a {
          color: $darkmode-color-light;
        }
      }

      &__link {
        span {
          color: $darkmode-color-link;
        }
      }

      &__submenu {
        box-shadow: none;

        &::before {
          border-bottom: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;

          p {
            strong {
              color: $darkmode-color-light;
            }
          }
        }
      }

    }

    .acknowledgment-filter {
      background-color: $darkmode-color-medium;
      border: 0;
      box-shadow: none;

      &__title, &__link {
        color: $darkmode-color-light;
      }
    }

    .favorite-filter {
      background-color: $darkmode-color-medium;
      border: 0;
      box-shadow: none;

      &__title, &__link {
        color: $darkmode-color-light;
      }
    }

    .box-bacheca {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__price, &__type, &__description {
        color: $darkmode-color-light;
      }
    }

    .box-wall {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__link, &__date, &__text {
        color: $darkmode-color-light;
      }

      &__submenu {
        box-shadow: none;

        &::before {
          background-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .box-appuntamenti {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__link, &__date, &__text {
        color: $darkmode-color-light;
      }

      &__submenu {
        box-shadow: none;

        &::before {
          background-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .box-portlet {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      a {
        color: $darkmode-color-link;
      }

      &--empty {
        color: $darkmode-color-light;
      }

      &__title, &__toggle {
        color: $darkmode-color-light;
      }

      &__content {
        a {
          color: $darkmode-color-link;
        }
      }
    }

    .box-group-activities {
      background-color: $darkmode-color-medium;
      box-shadow: none;
    }

    .box-post-activity {
      &__title, &__group, &__info {
        color: $darkmode-color-light;
      }
    }

    .box-highlight {
      &__title, &__link, &__name {
        color: $darkmode-color-light;
      }
    }

    .box-welcome {
      background-color: $darkmode-color-medium;

      &::before {
        background-color: $darkmode-color-medium;
      }
    }

    .box-user {
      &__name, &__link, &__role {
        color: $darkmode-color-light;
      }
    }

    .box-faq {
      border: none;

      &.open {
        background-color: transparent;
      }

      &__title {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
      }

      &__arrow {
        color: $darkmode-color-light;
        border: solid 1px $darkmode-color-light;
      }

      &__text {
        background-color: $darkmode-color-medium;
      }
    }

    .box-iscrizioni {
      &__body > div {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;
      }

      &.open {
        background-color: transparent;
      }

      &__title {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;

        .ico-svg {
          color: $darkmode-color-light;
          border-color: $darkmode-color-light;
        }
      }

      &__arrow {
        color: $darkmode-color-light;
        border: solid 1px $darkmode-color-light;
      }

      &__type {
        color: $darkmode-color-light;
      }

      &__field {
        a {
          color: $darkmode-color-link;
        }
      }
    }

    .box-reservation {
      background-color: $darkmode-color-medium;
      border: none;
      box-shadow: none;

      &__title, &__type, &__description {
        color: $darkmode-color-light;
      }
    }

    .box-filiale {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__address, &__contact {
        color: $darkmode-color-light;
      }

      &__contact {
        a {
          color: $darkmode-color-link;

          span {
            color: $darkmode-color-light;
          }
        }
      }
    }

    .box-category {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__header {
        background-color: $darkmode-color-medium;
      }

      &__name {
        color: $darkmode-color-light;
      }
    }

    .box-bacheca-card {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__abstract, &__name {
        color: $darkmode-color-light;
      }
    }

    .box-notification {
      background-color: $darkmode-color-medium;
      border: 0;
      box-shadow: none;

      > div {
        border: solid 1px $darkmode-color-medium;
        border-radius: $border-radius;
      }

      &--unread {
        background-color: $darkmode-color-dark;

        > div {
          background-color: $darkmode-color-dark;
          border: solid 1px $darkmode-color-medium;
        }
      }

      &__button {
        color: $darkmode-color-accent;
      }

      &__ico {
        color: $darkmode-color-light;

        .ico-svg {
          color: $darkmode-color-light;
        }
      }

      &__category, &__title, &__source, &__time, &__delete, &__read {
        color: $darkmode-color-light;
      }

      &__title {
        span {
          color: $darkmode-color-accent;
        }
      }

      &__dropdown {
        background-color: $darkmode-color-medium;
        border-color: $darkmode-color-light;
        box-shadow: none;
      }
    }

    .intranet-notification {
      color: $darkmode-color-light;

      &__btn {
        color: $darkmode-color-light;
      }

      &__number {
        border-color: $darkmode-color-dark;
      }

      &__already-read {
        color: $darkmode-color-light;
      }

      &__submenu {
        &::before {
          border-bottom-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
          border-top: 0;
          box-shadow: none;
        }
      }

      .box-notification {
        background-color: rgba($darkmode-color-dark, 0.3);
        border-bottom: 0;

        &--unread {
          background-color: $darkmode-color-dark;

          > div {
            background-color: $darkmode-color-dark;
          }
        }
      }
    }

    .intranet-pagination {
      color: $darkmode-color-light;

      &__btn {
        color: $darkmode-color-link;
      }

      &__link {
        color: $darkmode-color-light;

        &:hover, &:focus {
          color: $color-text;
        }

        &--active {
          color: $color-text;
        }
      }

      &__options {
        color: $darkmode-color-link;

        &::before {
          border-bottom-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
          box-shadow: none;
        }

        a {
          color: $darkmode-color-light;
        }
      }
    }

    .intranet-footer {
      color: $darkmode-color-light;
      background-color: $darkmode-color-dark;

      &__top {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
      }

      &__link, &__toggle, &__copyright, &__vat {
        color: $darkmode-color-light;
      }

      &__submenu {
        color: $darkmode-color-light;

        ul {
          li {
            color: $darkmode-color-light;

            a {
              color: $darkmode-color-light;
            }
          }
        }
      }
    }

    .intranet-map {
      .leaflet-popup-content-wrapper,
      .leaflet-popup-tip {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
      }

      &__popup {
        color: $darkmode-color-light;

        .address {
          color: $darkmode-color-light;
        }
      }
    }

    .intranet-underground {
      &__name {
        color: $darkmode-color-light;
      }
    }

    .hero, .hero-content {
      &::before {
        background-color: $darkmode-color-dark;
        opacity: .7;
      }

      &__title, &__date, &__name, &__link {
        color: $darkmode-color-light;
      }
    }

    .box-comments {
      &__title, &__hide-answers {
        color: $darkmode-color-light;
      }

      &__text {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;

        &::placeholder {
          color: $darkmode-color-light;
          opacity: .6;
        }
      }

      &__details {
        a, button {
          color: $darkmode-color-link;
        }
      }

      &__avatar {
        color: $darkmode-color-light;
      }
    }

    .intranet-tab {
      &__nav {
        background-color: $darkmode-color-medium;
        border: none;
        box-shadow: none;
      }

      &__item {
        &--active {
          &__link {
            color: $darkmode-color-light;
          }
        }
      }

      &__link, &__others-link {
        color: $darkmode-color-light;
      }

      &__others {

        &::before {
          border-bottom: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
          box-shadow: none;
        }
      }
    }

    .article-detail {
      &__info, &__value, &__date-text, &__name, &__text, &__source, &__office {
        color: $darkmode-color-light;
      }

      &__date {
        box-shadow: none;
      }

      &__type {
        span {
          color: $darkmode-color-light;
        }
      }

      &__status {
        color: $darkmode-color-light;
        border-color: $darkmode-color-light;
      }

      h1, h2, h3, h4, h5, h6 {
        color: $darkmode-color-light;
      }

      &__text {
        table th {
          background-color: $darkmode-color-medium;
        }

        table td, table th, ul li, ol li {
          color: $darkmode-color-light;
        }

        a {
          color: $darkmode-color-link;
        }
      }

      &__file {
        background-color: $darkmode-color-medium;
        border: 0;
        box-shadow: none;
      }

      &__icon, &__link, &__download, &__role {
        color: $darkmode-color-light;
      }

      &__tag {
        color: $darkmode-color-link;
        border-color: $darkmode-color-link;
      }

      &__feedback, &__add-calendar {
        color: $darkmode-color-link;
      }
    }

    .article-social {
      &__info {
        color: $darkmode-color-light;

        .ico-svg {
          color: $darkmode-color-light;
        }
      }
    }

    .html-custom,
    .component-html,
    .lfr-layout-structure-item-basic-component-heading,
    .journal-content-article {
      h1, h2, h3, h4, h5, h6 {
        color: $darkmode-color-light;
      }

      table td, table th, ul li, ol li {
        color: $darkmode-color-light;
      }

      a {
        color: $darkmode-color-link;
      }
    }

    .box-risultati {
      border: none;
      box-shadow: none;

      &__details {
        background-color: $darkmode-color-medium;
      }
    }

    .box-document, .box-person, .box-person-birthday, .box-news, .box-page {
      background-color: $darkmode-color-medium;
      box-shadow: none;
      border: none;
      color: $darkmode-color-light;

      &__title, &__link, &__description {
        color: $darkmode-color-light;
      }

      &__description {
        a {
          color: $darkmode-color-link;
        }
      }
    }

    .box-document {
      &__subtype, &__download {
        color: $darkmode-color-light;
      }

      &__description {
        a {
          color: $darkmode-color-link;
        }
      }

      &__submenu {
        box-shadow: none;

        &::before {
          background-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .box-person, .box-person-birthday {
      &__company, &__city, &__contact, &__settore, &__role {
        color: $darkmode-color-light;
      }

      &__actions {
        color: $darkmode-color-link;

        .ico-svg {
          color: $darkmode-color-link;
        }
      }

      &__contact {
        span {
          color: $darkmode-color-light;
        }

        a {
          color: $darkmode-color-link;
        }
      }

      &__trash {
        .ico-svg {
          color: $darkmode-color-link;
        }
      }
    }

    .box-person-birthday {
      &__mail {
        .ico-svg {
          color: $darkmode-color-link;
        }
      }

      &__msg {
        color: $color-text;
      }

      &__input-text, &__submit {
        background-color: $darkmode-color-light;
      }
    }

    .box-app {
      background-color: $darkmode-color-medium;
      border: 0;
      box-shadow: none;

      &__title {
        color: $darkmode-color-light;
      }
    }

    .box-corsi {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__category {
        color: $darkmode-color-light;
      }

      &__icon {
        .ico-svg {
          color: $darkmode-color-light;
        }
      }
    }

    .box-gruppo {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__category {
        color: $darkmode-color-light;
      }

      &__members {
        color: $darkmode-color-light;

        .ico-svg {
          color: $darkmode-color-light;
        }
      }
    }

    .box-corso-group {
      &__title {
        color: $darkmode-color-light;
      }
    }

    .box-news {
      &__type, &__date {
        color: $darkmode-color-light;
      }
    }

    .box-page {
      &__type, &__date {
        color: $darkmode-color-light;
      }

      &__description {
        a {
          color: $darkmode-color-link;
        }
      }

      &__icon {
        .ico-svg {
          color: $darkmode-color-light;
        }
      }
    }

    .intranet-anchor-nav {
      &__link {
        color: $darkmode-color-light;
      }
    }

    .intranet-social {
      &__link {
        color: $darkmode-color-light;
      }
    }

    .box-acknowledgment {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-link;

        span {
          color: $darkmode-color-link;
        }
      }
    }

    .box-manager {
      &__name, &__role {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-link;
      }

      &__actions {
        color: $darkmode-color-link;
      }
    }

    .intranet-banner-card {
      background-color: $darkmode-color-medium;
      border: 0;
      box-shadow: none;

      &__title {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-link;
      }

      &__img {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba($color-black, 0.1);
          pointer-events: none;
        }
      }
    }

    .iscrizioni-legend {
      color: $darkmode-color-light;

      &__title, &__subtitle, &__type {
        color: $darkmode-color-light;
      }
    }

    .box-progress {
      background-color: $darkmode-color-medium;

      &__label {
        color: $darkmode-color-light;
      }
    }

    .box-avviso {
      background-color: $darkmode-color-medium;
      border-top: 0;
      border-left-color: $darkmode-color-link;
      border-right: 0;
      border-bottom: 0;
      box-shadow: none;

      &__title, &__link, &__info {
        color: $darkmode-color-light;
      }

      &__date {
        color: $darkmode-color-link;
      }
    }

    .box-apps {
      &__title {
        color: $darkmode-color-light;

        @include media-breakpoint-down(sm) {
          background-color: $darkmode-color-dark;

          &::after {
            border-color: $darkmode-color-light;
          }
        }
      }

      &__content {
        background-color: $darkmode-color-dark;
      }

      &__name {
        color: $darkmode-color-light;
      }
    }

    .box-links {
      &__title {
        color: $darkmode-color-light;

        @include media-breakpoint-down(sm) {
          background-color: $darkmode-color-dark;

          &::after {
            border-color: $darkmode-color-light;
          }
        }
      }

      &__content {
        background-color: $darkmode-color-dark;

        .ico-svg {
          color: $darkmode-color-accent;
        }
      }

      &__name {
        color: $darkmode-color-light;
      }
    }

    .box-favorite, .box-elearning, .box-gruppi, .box-birthdays {
      &__list {
        @include media-breakpoint-up(md) {
          scrollbar-color: $darkmode-color-light;

          &::-webkit-scrollbar-track {
            background-color: $darkmode-color-light;
            box-shadow: 0;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba($background-primary, 0.6);
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba($background-primary, 1); 
          }
        }
      }

      &__title {
        color: $darkmode-color-light;

        @include media-breakpoint-down(sm) {
          background-color: $darkmode-color-dark;

          &::after {
            border-color: $darkmode-color-light;
          }
        }
      }

      &__select {
        &::after {
          border-color: $darkmode-color-light;
        }

        select {
          color: $darkmode-color-light;
          background-color: $darkmode-color-medium;
        }
      }

      &__content {
        background-color: $darkmode-color-dark;
      }

      &__name {
        color: $darkmode-color-light;
      }
    }

    .box-evento {
      &__date {
        box-shadow: none;
      }

      &__day {
        background-color: $darkmode-color-light;
      }

      &__title, &__office {
        color: $darkmode-color-light;
      }

      &__agenda {
        color: $darkmode-color-link;
      }
    }

    .box-utility-link {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__name {
        color: $darkmode-color-light;
      }
    }

    .box-person-contact {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__role, &__settore {
        color: $darkmode-color-light;
      }

      &__mail, &__chat, &__video {
        .ico-svg {
          color: $darkmode-color-accent;
        }
      }
    }

    .intranet-card {
      border: none;
      box-shadow: none;

      &__body, &__moreinfo {
        background-color: $darkmode-color-medium;
      }

      &__abstract {
        &:after {
          background-image: linear-gradient(to bottom, rgba($darkmode-color-medium, 0), rgba($darkmode-color-medium, 1));
        }
      }

      &__type {
        background-color: $darkmode-color-medium;
      }

      &__title, &__abstract, &__date, &__name, &__moreinfo-header, &__role, &__link, &__price, &__address, &__time {
        color: $darkmode-color-light;
      }

      &__moreinfo-header {
        .ico-svg {
          color: $darkmode-color-accent;
        }
      }

      &__name {
        color: $darkmode-color-accent;
      }

      &__event-date {
        box-shadow: none;
      }

      &__media-info {
        color: $darkmode-color-light;
      }

      &__moreinfo-btn {
        color: $darkmode-color-link;

        .ico-svg {
          color: $darkmode-color-link;
        }

        span {
          border-bottom-color: $darkmode-color-link;
        }
      }

      &__tag {
        color: $darkmode-color-link;
        border-color: $darkmode-color-link;
      }

      &__email {
        color: $darkmode-color-link;
      }

      &__submenu {
        color: $darkmode-color-light;
        box-shadow: none;

        &::before {
          border-bottom-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }

      &__item {
        color: $darkmode-color-light;

        a, button {
          color: $darkmode-color-light;
        }
      }
    }

    .intranet-indice {
      &__title {
        color: $darkmode-color-light;
      }

      &__link {
        color: $darkmode-color-link;
      }
    }

    .intro-filiale {
      color: $darkmode-color-light;
      border: none;
      background-color: $darkmode-color-medium;

      &__label, &__value, &__box-title, &__name, &__link, &__role {
        color: $darkmode-color-light;
      }

      &__card {
        > div {
          background-color: $darkmode-color-medium;
        }
      }

      &__contact {
        a {
          color: $darkmode-color-link;
        }

        span {
          color: $darkmode-color-light;
        }
      }

      &__actions {
        color: $darkmode-color-link;
      }
      
      .box-person-contact {
        background-color: $darkmode-color-dark;
      }
    }

    .box-calendar {
      background-color: transparent;

      &__table {
        thead {
          color: $color-text-secondary;
        }

        tbody {
          border-top: solid 1px $darkmode-color-light;
          border-left: solid 1px $darkmode-color-light;

          td {
            border-right: solid 1px $darkmode-color-light;
            border-bottom: solid 1px $darkmode-color-light;
          }
        }
      }

      &__month {
        color: $darkmode-color-light;
      }

      &__day {
        color: $darkmode-color-light;
      }

      &__event-detail {
        &::before {
          border-top: 10px solid $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
          box-shadow: none;
        }

        &-title {
          color: $darkmode-color-light;
        }
      }
    }

    .reservations {
      &__info {
        a {
          color: $darkmode-color-link;
        }
      }

      &__submenu {
        color: $darkmode-color-light;
        box-shadow: none;

        &::before {
          background-color: $darkmode-color-medium;
        }

        > div {
          background-color: $darkmode-color-medium;
        }
      }
    }

    .radio, .checkbox, .form-control {
      color: $darkmode-color-light;
      background-color: transparent;

      &:disabled {
        cursor: not-allowed;
        opacity: .5;
      }
    }

    .radio {
      &__mark {
        background-color: transparent;
        border-color: $darkmode-color-light;

        &::after {
          border-color: $darkmode-color-light;
        }
      }
    }

    .checkbox {
      border-color: $darkmode-color-light;

      &__mark {
        background-color: transparent;
        border-color: $darkmode-color-light;

        &::after {
          border-color: $darkmode-color-light;
        }
      }
    }

    .modal-avviso {
      .close {
        text-shadow: none;
      }

      .modal-content {
        background-color: $darkmode-color-medium;
      }

      .modal-header {
        border-bottom-color: $darkmode-color-light;
      }

      .modal-title, .modal-info {
        color: $darkmode-color-light;
      }

      .modal-form {
        color: $darkmode-color-light;

        &__title, &__subtitle {
          color: $darkmode-color-light;
        }
      }

      .form-select, select {
        color: $darkmode-color-light;
        background-color: transparent;
        border: 1px solid $darkmode-color-light;

        option {
          background-color: $darkmode-color-medium;
        }
      }

      .calendar-input__date {
        color: $darkmode-color-link;
        background-color: transparent;
      }

      &__subtitle {
        color: $darkmode-color-light;
      }
    }

    .box-kudos {
      color: $darkmode-color-light;

      &__award-title-dotted {
        &::before, &::after {
          background-image: linear-gradient(to right, $darkmode-color-light 40%, rgba(255, 255, 255, 0) 20%);
        }
      }

      &__score, &__award-credits {
        color: $darkmode-color-link;
      }
    }

    .box-profilo {
      color: $darkmode-color-light;

      &__avatar {
        box-shadow: none;
      }

      &__title, &__name, &__role, &__claim {
        color: $darkmode-color-light;
      }

      &__text {
        background-color: $darkmode-color-medium;
        color: $darkmode-color-light;

        &::placeholder {
          color: $darkmode-color-light;
          opacity: .6;
        }
      }

      &__wrap {
        background-color: $darkmode-color-medium;
        border: none;
        box-shadow: none;

        &--settings {
          .box-profilo__setting {
            .switch-color-scheme__slider {
              border-color: $darkmode-color-dark;
            }
    
            .switch-toggle__slider {
              border-color: $darkmode-color-dark;
            }
    
            &-select {
              select {
                border-color: $darkmode-color-dark;
              }
            }
          }
        }
      }

      &__editphoto {
        color: $darkmode-color-dark;
        background-color: $darkmode-color-light;
      }

      &__info {
        color: $darkmode-color-light;

        span {
          color: $darkmode-color-light;
          border-color: $darkmode-color-light;
        }
      }

      &__button {
        color: $darkmode-color-link;
      }
      
      &__setting {
        color: $darkmode-color-light;

        &-select {
          &::after {
            border-color: $darkmode-color-light;
          }

          select {
            color: $darkmode-color-light;
            background-color: $darkmode-color-medium;
            border-color: $darkmode-color-medium;
          }
        }
      }

      &__details {
        button, a {
          color: $darkmode-color-link;
        }
      }

      &__badge {
        color: $darkmode-color-link;
        border-color: $darkmode-color-link;
      }

      &__actions {
        color: $darkmode-color-link;

        &:disabled {
          color: $color-grey-3;
        }
      }

      &__birthday-congrats {
        color: $darkmode-color-light;
        background-color: $darkmode-color-dark;
      }

      &__delete-comment {
        color: $darkmode-color-light;
      }
    }

    .box-member {
      &__name {
        color: $darkmode-color-link;
      }

      &__viewall {
        button {
          color: $darkmode-color-link;
        }
      }
    }

    .upload-file {
      &__delete {
        color: $darkmode-color-link;
      }
    }

    .favourite-star {
      &__label {
        color: $darkmode-color-link;
      }
    }

    .btn-acknowledgment {
      color: $darkmode-color-link;

      &__btn {
        color: $darkmode-color-link;
        border-color: $darkmode-color-link;

        .ico-svg {
          color: $darkmode-color-link;
        }
      }

      &__tooltip {
        color: $darkmode-color-dark;
        background-color: $color-grey-3;
        box-shadow: none;

        &::after {
          background-color: $color-grey-3;
        }
      }

      span {
        color: $darkmode-color-link;
      }

      button {
        color: $darkmode-color-link;
      }

      &.confirmed {
        span {
          color: $darkmode-color-light;
        }
      }
    }

    .switch-color-scheme {
      &__slider {
        background-color: $darkmode-color-medium;
        border-color: $darkmode-color-medium;

        span {
          transform: translateX(26px);

          .ico-svg {
            &.dark {
              display: block;
            }

            &.brightness {
              display: none;
            }
          }
        }
      }
    }

    .switch-toggle {
      &__checkbox {
        &:checked + .switch-toggle__slider span {
          color: $darkmode-color-light;
        }
      }

      &__slider {
        background-color: $darkmode-color-medium;
        border-color: $darkmode-color-medium;

        span {
          color: $darkmode-color-medium;
        }
      }
    }
    
    .box-my-birthday {
      background-color: $darkmode-color-medium;
      box-shadow: none;

      &__title, &__desc, &__congratulations {
        color: $darkmode-color-light;
      }
    }

    .intranet-banner {
      background-color: $darkmode-color-dark;

      img {
        filter: none;
      }
    }

    .portlet-forms {
      legend {
        color: $darkmode-color-light;
      }

      //Page title & description
      .lfr-ddm-form-page-title,
      .form-builder-page-header-title.form-control,
      .form-entry .lfr-ddm-form-page-title,
      .form-entry .form-builder-page-header-title.form-control {
        color: $darkmode-color-light;
      }
      .form-builder-page-header-description.form-control,
      .lfr-ddm-form-page-description,
      .form-entry .form-builder-page-header-description.form-control,
      .form-entry .lfr-ddm-form-page-description {
        color: $darkmode-color-light;
      }
      .ddm-form-builder-app .lfr-ddm-form-page {
        .text-secondary {
          color: $darkmode-color-light !important;
        }
      }
    }

    .lfr-ddm__default-page-header-title {
      color: $darkmode-color-light;
    }

    .bootstrap-tagsinput {
      .tag {
        color: $darkmode-color-light;
        border-color: $darkmode-color-light;
      }
    }

    .calendar-input {
      .ico-svg {
        color: $darkmode-color-light;
      }
    }
  }

  .tooltip {
    .tooltip-inner {
      
      .box-calendar__event-detail {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
        box-shadow: none;

        &-resource {
          color: $darkmode-color-accent;
        }
  
        &-title {
          color: $darkmode-color-light;
        }
      }
    }
  
    &.bs-tooltip-top .arrow::before {
      border-top-color: $darkmode-color-medium;
    }
  
    &.bs-tooltip-bottom .arrow::before {
      border-bottom-color: $darkmode-color-medium;
    }
  }

  .dropdown-menu {
    background-color: $color-grey-4;

    .dropdown-item {
      color: $color-text;

      &:focus {
        background-color: transparent;
      }
    }
  }

  .flatpickr-calendar, .flatpickr-calendar * {
    background-color: $darkmode-color-medium;
    color: $darkmode-color-light;
  }

  .flatpickr-calendar.animate.open {
    background-color: $darkmode-color-medium;
    color: $darkmode-color-light;
  }

  .bootstrap-tagsinput, .dropzone {
    color: $darkmode-color-light;
    background-color: $darkmode-color-medium;
    box-shadow: none;

    input {
      color: $darkmode-color-light;
    }
  }

  .fc-view-container {
    background-color: transparent;

    table {
      background-color: transparent;
    }

    .fc-widget-header {
      background-color: transparent;
    }

    td.fc-today {
      background-color: $darkmode-color-medium;
    }
  }

  .cke_wysiwyg_frame,
  .cke_wysiwyg_div,
  .cke_top,
  .cke_inner,
  .cke_bottom {
    background-color: $darkmode-color-medium !important;
  }

  .cke_contents {
    border: 0 !important;
  }

  //
  // liferay comment
  //
  button {
    &.intranet-comment {
      color: $darkmode-color-light;
    }

    &.btn-thumbs-up {
      color: $darkmode-color-light;

     &[aria-pressed="true"] svg.lexicon-icon-thumbs-up-full {
        color: $darkmode-color-light !important;
      }
    }

    &.btn-thumbs-down {
      color: $darkmode-color-light;

      &[aria-pressed="true"] svg.lexicon-icon-thumbs-down-full {
        color: $darkmode-color-light !important;
      }
    }
  }

  .intranet-ratings-icons {
    svg {
      color: $darkmode-color-light !important;

      &:hover {
        color: $color-accent !important;
      }
    }
  }

  .intranet-username {
    color: $darkmode-color-light;
  }

  .lfr-discussion-reply-popover {
    color: $darkmode-color-light;

    &.popover {

      .popover-header {
        background-color: $darkmode-color-medium;
      }

      .popover-body {
        color: $darkmode-color-light;
        background: $darkmode-color-medium;
      }

      .arrow {
        &:before {
          border-top-color: $darkmode-color-medium;
        }

        &:after {
          border-top-color: $darkmode-color-medium;
        }
      }
    }
  }

  .lfr-icon-menu-open {
    .dropdown-menu {
      color: $darkmode-color-light;
      background-color: $darkmode-color-medium !important;

      .dropdown-item {
        color: $darkmode-color-light;

        &:hover, &:focus {
          background-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }

  .component-action[aria-expanded=true],
  .component-action.show,
  .show > .component-action {
    background-color: transparent;
  }

  .chat-bar {
    .status-message,
    .trigger-name {
      text-shadow: none;
    }
    
    div {
      ul li {
        &.selected {
          .chat-panel-trigger {
            background-color: $darkmode-color-medium;
          }
        }
        
        .chat-panel-trigger {
          &:hover {
            background-color: $darkmode-color-medium;
          }
        }
      }
    }
    
    .chat-panel-profile {
      background: $darkmode-color-dark;
      border-bottom: 1px solid $darkmode-color-light;
    }
    
    .chat-panel-window {
      color: $darkmode-color-light;
      background-color: $darkmode-color-medium;
      border-color: $darkmode-color-light;
      
      .form-group.input-text-wrapper {
        border: 0;
      }
    }
    
    .chat-panel-input {
      .message-input {
        color: $darkmode-color-light;
        background-color: $darkmode-color-medium;
      }
    }
    
    .chat-settings {
      .form-select, select {
        background: $darkmode-color-medium;
        color: $darkmode-color-light;
      }

      label.checkbox {
        .checkbox__input:checked ~ .checkbox__mark {
          background-color: transparent;
        }
      }
      
    }
    
    .chat-panel-output {
      .name {
        color: $darkmode-color-light;
        background-color: $darkmode-color-dark;
      }
      
      .incoming {
        .name {
          color: $darkmode-color-light;
          background-color: $darkmode-color-accent;
        }
      }
    }
    
    .buddy-list {
      .chat-panel-content {
        .online-users li, .history-users li {
          border: none;
          
          &:hover {
            background-color: $darkmode-color-dark;
            border: none;
          }
        }
      }
    }
  }
}
