.box-kudos {
  color: $color-text;
  
  &__box {
    padding: 0 24px;
    margin: 30px 0;

    @include media-breakpoint-down(md) {
      padding-top: 30px;
      margin-top: 0;
    }
    
    &--award {
      @include media-breakpoint-down(md) {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &--title {
      padding-bottom: 25px;
    }

    &--border-b {
      border-bottom: 1px solid $color-border-medium;
    }

    &--border-l {
      border-left: 1px solid $color-border-medium;

      @include media-breakpoint-down(md) {
        border-left: 0;
        border-top: 1px solid $color-border-medium;
      }
    }

    &--h100 {
      height: calc(100% - 60px);
    }
  }

  &__box-title {
    @include font-family($font-family-primary, semibold);
    color: $color-text;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  
  &__award-credits {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    color: $color-link;
    text-transform: uppercase;
  }

  &__score-title {
    font-size: $font-size-small;
  }

  &__score {
    font-size: 50px;
    line-height: 1;
    color: $color-link;
  }

  &__disabled {
    filter: grayscale(100%);
    opacity: 0.5;
  }
  
  &__award, &__award-border {
    text-align: center;
    position: relative;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 5px;
    background: $profile-award-bg-color;
    margin-right: 20px;
    margin-bottom: 16px;

    &--column {
      margin-top: 8px;
      margin-right: 0;
      margin-bottom: 30px;
    }
    
    &::before, &::after {
      content: "";
      position: absolute;
      width: 34px;
      height: 21px;
      border-radius: 5px;
      bottom: -10px;
      background: $profile-award-bg-color;
    }
    
    &::before {
      left: 2px;
      transform: rotate(40deg);
    }
    
    &::after {
      right: 2px;
      transform: rotate(-40deg);
    }
    
    .ico-svg {
      position: relative;
      color: $color-icon-light;
      max-width: 20px;
      max-height: 32px;
      margin-top: 13px;
      z-index: 10;
    }
  }
  
  &__award-border {
    position: absolute;
    min-width: 43px;
    width: 43px;
    height: 43px;
    border: 2px solid rgba($background-light,0.5);
    left: 3px;
    top: 3px;
    border-bottom: 0;
    background: transparent;
    z-index: 8;
    
    &::after, &::before {
      border-bottom: 2px solid rgba($background-light,0.5);
      background: transparent;
      width: 28px;
      bottom: -10px;
    }

    &::before {
      left: 1px;
    }

    &::after {
      right: 1px;
    }
  }
  
  &__award-title {
    @include font-family($font-family-primary, semibold);
    font-size: 16px;
    line-height: 18px;
  }

  &__award-title-dotted {
    position: relative;
    display: inline-block;
    font-size: 10px;
    line-height: 1;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 12px;

      &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 50px;
        height: 2px;
        background-image: linear-gradient(to right, $color-text 40%, rgba(255, 255, 255, 0) 20%);
        background-position: top;
        background-size: 8px 2px;
        background-repeat: repeat-x;
        margin-top: -2px;
      }

      &::before {
        left: -65px;
      }

      &::after {
        right: -65px;
      }
    }
  }
  
  &__award-credits {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    color: $color-link;
    text-transform: uppercase;
  }
}