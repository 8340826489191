.iscrizioni-legend {
  color: $color-text;
  line-height: 30px;
  padding: 8px 24px 8px 0;

  @include media-breakpoint-down(sm) {
    padding-right: 2px;
    margin-top: 30px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 24px;
    line-height: 30px;
    color: $color-text;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }

  &__subtitle {
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 1;
    color: $color-text;
    text-transform: uppercase;
    margin-top: 56px;
    margin-left: -24px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
}