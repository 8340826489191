.intranet-banner-card {
  min-height: $banner-card-min-height;
  background-color: $banner-card-bg-color;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  margin-bottom: $section-spacing-size;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__content {
    min-height: $banner-card-min-height;
    padding: 30px;
  }

  &__title {
    @include font-family($font-family-secondary, semibold);
    font-size: 24px;
    line-height: 1;
    color: $banner-card-title-color;
    word-break: break-word;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  &__abstract {
    margin-bottom: 30px;
  }

  &__link {
    display: inline-block;
    position: relative;
    @include font-family($font-family-primary, semibold);
    line-height: 1;
    white-space: nowrap;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: transparent;
    color: $banner-card-link-color;
    outline: 0;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $banner-card-link-color;
    }
  }

  &__img {
    min-width: $banner-card-min-width-img;
    min-height: $banner-card-min-height;
    background-size: $banner-card-bg-size;
    background-position: $banner-card-bg-position;
    border-top-right-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;

    @include media-breakpoint-down(md) {
      border-top-right-radius: 0;
      border-bottom-left-radius: $border-radius-card;
    }
  }
}