.box-page {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &--small {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 15px;
  }

  &__icon {
    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 16px;
    }

    .ico-svg {
      color: $color-icon-primary;
      max-height: 50px;
      min-width: 40px;
      width: 40px;
      max-width: 40px;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__type, &__date {
    font-size: 13px;
    line-height: 16px;
    color: $color-text;
    text-transform: uppercase;
  }

  &__date {
    text-transform: none;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 18px;
    color: $color-text;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    
    p {
      font-size: 13px;
      line-height: 18px;
    }

    label {
      @include font-family($font-family-primary, bold);
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      text-decoration: underline;
    }
  }

  &__contact {
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
    margin-top: 10px;
    margin-right: 48px;

    @include media-breakpoint-down(md) {
      margin-right: 36px;
    }

    .ico-svg {
      width: 16px;
      height: 16px;
    }
    
    span {
      color: $color-text;
    }

    a {
      display: inline-block;
    }
  }

  .favourite-star {
    margin-left: 10px;

    .ico-svg {
      max-width: 20px;
      width: 20px;
    }
  }

  @include media-breakpoint-up(md) {
    .btn-acknowledgment {
      margin-left: 10px;
    }
  }
}