.intranet-underground {
  margin-bottom: $section-spacing-size;
    
  @include media-breakpoint-down(sm) {
    margin-bottom: $section-spacing-size-sm;
  }

  &__stop {
    position: relative;
    width: 100%;
    text-align: center;

    &--first, &:first-child {
      &::before {
        content: none;
      }
    }

    &--last, &:last-child {
      &::after {
        content: none;
      }
    }

    &::before, &::after {
      content: "";
      position: absolute;
      top: 16px;
      width: 55%;
      height: 8px;
      background-color: $background-medium;
    }

    &::before {
      z-index: 2;
      left: 0;
    }

    &::after {
      z-index: 3;
      left: 50%;
      border-radius: 8px;
    }
    
    &--active {
      .intranet-underground__num {
        background-color: $background-accent;
      }

      &::before, &::after {
        background-color: $background-accent;
      }
    }

    &--complete {
      .intranet-underground__num {
        position: relative;
        background-color: $background-accent-dark;
        text-indent: -9999em;
        
        &::after {
          content: "";
          position: absolute;
          top: 10px;
          left: 16px;
          width: 9px;
          height: 16px;
          border: solid $color-text-contrast;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      &::before, &::after {
        background-color: $background-accent;
      }
    }
  }

  &__num {
    position: relative;
    z-index: 4;
    overflow: hidden;
    width: 40px;
    height: 40px;
    @include font-family($font-family-primary, semibold);
    font-size: 18px;
    line-height: 40px;
    color: $color-text-contrast;
    background-color: $background-medium;
    border-radius: 50%;
    margin-bottom: 12px;
  }
  
  &__name {
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 20px;
    color: $color-text;
    padding-left: 8px;
    padding-right: 8px;

    @include media-breakpoint-down(xs) {
      font-size: 12px;
      line-height: 18px;
    }
  }
}