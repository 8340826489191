.box-manager {
  margin-top: 16px;
  
  &__link {
    display: inline-block;
    position: relative;
    @include font-family($font-family-primary, semibold);
    color: $manager-link-text-color;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 32px;

    &:hover {
      color: $manager-link-text-color;
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }
  }

  &__avatar {
    position: relative;
    min-width: 94px;
    width: 94px;
    height: 94px;
    @include font-family($font-family-primary, regular);
    font-size: 30px;
    line-height: 94px;
    font-size: $font-size-default;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 20px;

    @include media-breakpoint-down(xs) {
      min-width: 80px;
      width: 80px;
      height: 80px;
      font-size: 26px;
      line-height: 80px;
      margin-right: 16px;
    }
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 94px;
      width: 94px;
      height: 94px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;

      @include media-breakpoint-down(xs) {
        min-width: 80px;
        width: 80px;
        height: 80px;
      }
      
    }
  }

  &__name {
    @include font-family($font-family-primary, bold);
    font-size: 20px;
    line-height: $line-height-default;
    color: $manager-name-text-color;
    margin-bottom: 4px;

    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }

  &__role {
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $manager-role-text-color;
  }

  &__actions {
    display: inline-block;
    color: $manager-actions-color;
    margin-top: 8px;
    margin-right: 12px;

    &:disabled {
      color: $manager-actions-disabled-color;
      opacity: 0.5;
      cursor: not-allowed;
    }

    .ico-svg {
      min-width: 25px;
      width: 25px;
      height: 18px;
    }
  }

}