.intranet-breadcrumb {
  font-size: $font-size-small;
  line-height: $line-height-default;
  color: $breadcrumb-text-color;
  background-color: transparent;
  border-radius: 0;
  padding-top: $section-spacing-size;
  margin-bottom: $section-spacing-size;

  @include media-breakpoint-down(sm) {
    padding-top: 20px;
    margin-bottom: $section-spacing-size-sm;
  }
  
  &__list {
    line-height: 24px;
    margin: 0;
  }
  
  &__item {
    display: inline;
    list-style-type: none;
    font-size: $font-size-small;
    line-height: $line-height-default;
    color: $breadcrumb-text-color;

    .ico-svg {
      min-width: 17px;
      max-width: 17px;
      max-height: 13px;
      color: $breadcrumb-icon-color;
      margin-right: 4px;
    }
  }
  
  &__link {
    position: relative;
    display: inline-block;
    font-size: $font-size-small;
    line-height: $line-height-default;
    color: $breadcrumb-text-color;
    margin-right: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -13px;
      display: inline-block;
      border: solid $breadcrumb-icon-color;
      border-width: 0 1px 1px 0;
      padding: 2px;
      transform: rotate(-45deg);
      margin-top: -3px;
    }
    
    @include media-breakpoint-down(md) {
      padding-left: 12px;
      
      &::after {
        right: auto;
        left: 0;
        transform: rotate(135deg);
      }
    }
    
    &:hover {
      color: $color-link;
      text-decoration: underline;
      
      .ico-svg {
        color: $color-link;
      }
    }

    &:focus {
      color: $color-link;
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
      
      .ico-svg {
        color: $color-link;
      }
    }
  }
  
  &__current {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-small;
    line-height: $line-height-default;
    color: $breadcrumb-text-color;
  }
}
