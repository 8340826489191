.intranet-pagination {
  font-size: $font-size-default;
  line-height: 1;
  color: $color-text-secondary;
  margin-top: 30px;

  &__sort {
    position: relative;
    font-size: $font-size-default;
    line-height: 1;
    margin-right: 30px;
  }

  &__sort-label {
    color: $color-text-secondary;
  }

  &__btn {
    position: relative;
    font-size: $font-size-default;
    line-height: 1;
    color: $color-link;
    background-color: transparent;
    padding-right: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      right: 2px;
      display: inline-block;
      border: solid $color-border-accent;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);
    }
  }

  &__menu {
    &.open {
      .intranet-pagination__options {
        display: block;
      }
    }
  }

  &__options {
    display: none;
    position: absolute;
    left: 0;
    z-index: 99;
    width: 230px;
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text;
    border-radius: $border-radius;

    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 40px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        left: 30px;
      }
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      box-shadow: $box-shadow-default;
      padding: 30px 20px;
      margin-top: 15px;
    }
  }

  &__list {
    margin: 0;
    margin-left: 60px;

    @include media-breakpoint-down(md) {
      margin-top: 15px;
      margin-left: 0;
    }
  }

  &__item {
    @include font-family($font-family-primary, semibold);
    list-style-type: none;
  }

  &__link {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: $color-text;
    text-align: center;
    border-radius: $border-radius;
    margin: 0 2px;

    &:hover, &:focus {
      background-color: $background-light;

      &.intranet-pagination__link--arrow:before {
        border-color: $color-icon-primary;
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }

    &--active {
      color: $btn-link-text-color;
      background-color: $btn-link-bg-color;

      &:hover, &:focus {
        color: $btn-link-text-color-hover;
        cursor: text;
        background-color: $btn-link-bg-color-hover;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $btn-link-text-color-hover;
      }
    }

    &--arrow {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        border: solid $color-icon-secondary;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
      }
    }

    &--prev {
      &::before {
        transform: rotate(135deg);
      }
    }

    &--next {
      &::before {
        transform: rotate(-45deg);
      }
    }
  }
}