.box-person-contact {
  display: block;
  position: relative;
  height: calc(100% - 24px);
  cursor: pointer;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 24px;
  margin: 0 8px 24px 8px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__avatar {
    position: relative;
    min-width: 65px;
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin-bottom: 16px;

    span {
      display: inline-block;
      width: 65px;
      min-width: 65px;
      height: 65px;
      @include font-family($font-family-primary, regular);
      font-size: 24px;
      line-height: 65px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 65px;
      min-width: 65px;
      height: 65px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 14px;
    width: 14px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }

    &--accepted {
      background-color: $color-success;
    }

    &--pending {
      background-color: $color-warning;
    }

    &--refused {
      background-color: $color-danger;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__role {
    width: 100%;
    @include font-family($font-family-primary, medium);
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    margin-top: 12px;
  }

  &__settore {
    @include font-family($font-family-primary, medium);
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    margin-top: 8px;
  }

  &__rolegroup {
    @include font-family($font-family-primary, semibold);
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    margin-top: 8px;
  }

  &__mail, &__chat, &__video {
    @include font-family($font-family-primary, medium);
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    margin-top: 12px;
    margin-left: 8px;
    margin-right: 8px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-link;
  
      &:hover, &:focus {
        color: $color-accent-dark;
      }

      &:focus {
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-accent-dark;
      }
    }
  }

  &__actions {
    display: inline-block;
    color: $color-link;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }
  }

  .btn-birthday {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}