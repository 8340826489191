.box-activities {

  &__date {
    position: relative;
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 22px;
    padding-left: 13px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 7px;
      left: -3px;
      width: 8px;
      height: 8px;
      background-color: $background-accent;
      border-radius: 50%;
    }
  }

  &__activity {
    border-left: solid 1px $color-border-dark;
    padding: 12px;
    padding-right: 0;

    &:first-child {
      padding-top: 24px;
    }

    &:last-child {
      padding-bottom: 24px;
    }
  }
  
  &__hour {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 1;
    margin-bottom: 8px;
  }
  
  &__info {
    font-size: 13px;
    line-height: 18px;
  }
}