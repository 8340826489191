.calendar-input {
  border-radius: $border-radius-input-form;
  border: solid 1px $color-border-medium;
  padding: 12px;
  color: $color-text;
  margin-bottom: 6px;

  &--disabled {
    background-color: $color-grey-1;

    &:hover {
      cursor: not-allowed;
    }
  }

  .ico-svg {
    color: $color-primary-base;
    min-width: 16px;
    max-width: 16px;
    max-height: 16px;
  }

  &__date, input {
    display: block;
    font-size: $font-size-default;
    line-height: 1;
    color: $color-link;
    text-align: right;

    &:disabled {
      cursor: not-allowed;
      background-color: $color-grey-1;
    }
  }
}