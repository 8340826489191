.box-risultati {
  overflow: hidden;
  border-radius: $border-radius-card;
  
  @include media-breakpoint-down(md) {
    background-color: $box-risultati-info-bg-color;
  }
  
  &__details {
    background-color: $box-risultati-details-bg-color;
    padding: 80px 50px;

    @include media-breakpoint-down(md) {
      background-color: transparent;
      padding: 80px 30px 0;

      .section__link, .section__title {
        color: $box-risultati-details-title-color;

        &::after {
          background-color: $box-risultati-details-title-color;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-top: 30px;
    }
  }

  &__info {
    color: $box-risultati-text-color;
    background: $box-risultati-info-bg-color;
    padding: 85px 40px;
    padding-bottom: 35px;

    @include media-breakpoint-down(md) {
      background-image: none;
      padding: 25px 30px 10px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 0;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: $section-spacing-size;

    @include media-breakpoint-down(md) {
      width: 50%;
      align-items: flex-start;
    }
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm;
    }
  }

  &__number {
    font-size: 40px;
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  &__label {
    font-size: $font-size-default;
    opacity: $box-risultati-text-opacity;
  }

}