//Gestione font-family e font-weight
$thin:        100;
$extralight:  200;
$light:       300;
$regular:     400;
$medium:      500;
$semibold:    600;
$bold:        700;
$extrabold:   900;

@mixin font-google($family, $weight){
  // @debug "family: #{$family}";
  // @debug "weight: #{$weight}";
  font-family: $family;
  @if  $weight == thin {
    font-weight: $thin;
  }
  @if  $weight == extralight {
    font-weight: $extralight;
  }
  @if  $weight == light {
    font-weight: $light;
  }
  @if  $weight == regular {
    font-weight: $regular;
  }
  @if  $weight == medium {
    font-weight: $medium;
  }
  @if  $weight == semibold {
    font-weight: $semibold;
  }
  @if  $weight == bold {
    font-weight: $bold;
  }
  @if  $weight == extrabold {
    font-weight: $extrabold;
  }
}
@mixin font-local($thin, $light, $regular, $medium, $semibold, $bold, $extrabold, $weight){
  font-weight: normal;
  @if  $weight == thin {
    font-family: $thin;
  }
  @if $weight == light {
    font-family: $light;
  }
  @if $weight == regular {
    font-family: $regular;
  }
  @if $weight == medium {
    font-family: $medium;
  }
  @if $weight == semibold {
    font-family: $semibold;
  }
  @if $weight == bold {
    font-family: $bold;
  }
  @if $weight == extrabold {
    font-family: $extrabold;
  }
}

//Smartphone landscape (comprensivo di iPhone X)
@mixin media-breakpoint-sm-landscape {
  @media only screen and (max-device-width : 767px) and (orientation: landscape), (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

//Only iPhone X landscape
@mixin media-breakpoint-iphonex-landscape {
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    @content;
  }
}

@mixin dark-mode() {
  body.intranet.dark-theme {
    @content;
  }
}