.box-comments {
  padding-bottom: 16px;

  &__text {
    width: 100%;
    min-height: 180px;
    background-color: $background-light;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: solid 1px $color-border-dark;
    border-radius: $border-radius;
    padding: 12px 21px;
    margin-bottom: 30px;

    &::placeholder {
      color: $color-text;
    }
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default + 3px;
    color: $color-text;
    margin-top: $section-spacing-size;
    margin-bottom: 15px;
    
    @include media-breakpoint-down(sm) {
      margin-top: $section-spacing-size-sm;
    }
  }

  p {
    color: $color-text;
    
    @include media-breakpoint-down(sm) {
      font-size: $font-size-small;
      line-height: 1.4;
    }
  }

  &__comment {
    font-size: $font-size-default;
    line-height: 30px;
    border-top: 1px solid $color-border-medium;
    padding-top: 24px;

    &:first-child {
      border-top: 0;
    }
  }

  &__details {
    font-size: 14px;
    line-height: 1;
    margin-top: $section-spacing-size/2;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      font-size: 13px;
      margin-top: 8px;
      margin-bottom: $section-spacing-size-sm;
    }

    button, a {
      color: $color-link;
      background-color: transparent;
      outline: 0;
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }

    span {
      margin-left: 16px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__hide-answers {
    @include font-family($font-family-primary, semibold);
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: $section-spacing-size;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }

  &__answers {
    .box-comments__comment {
      border-top: 0;
    }
    .box-comments__details {
      margin-top: 16px;
      margin-bottom: $section-spacing-size;
    }
  }

  &__avatar {
    position: relative;
    min-width: 30px;
    width: 30px;
    height: 30px;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 30px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 8px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      min-width: 30px;
      height: 30px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }
}