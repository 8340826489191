.box-person {
  position: relative;
  cursor: pointer;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }

  &--small {
    padding: 15px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  &--border-bottom {
    cursor: inherit;
    border-bottom: solid 1px $color-border-dark;
    border-radius: 0;

    .box-person__title {
      margin-top: 0;
    }

    .btn-birthday__cta {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin-left: 16px;
    }
  }
  
  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
    margin-top: 8px;
  }

  &__avatar {
    position: relative;
    min-width: 40px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 16px;
    }

    span {
      display: inline-block;
      width: 40px;
      min-width: 40px;
      height: 40px;
      @include font-family($font-family-primary, regular);
      font-size: 12px;
      line-height: 40px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      min-width: 40px;
      height: 40px;
      object-fit: cover;
      object-position: center center;
      vertical-align: initial;
      border-radius: $border-radius-avatar;
    }
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 14px;
    width: 14px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }

    &--accepted {
      background-color: $color-success;
    }

    &--pending {
      background-color: $color-warning;
    }

    &--refused {
      background-color: $color-danger;
    }
  }

  &__company, &__city {
    display: inline-block;
    font-size: 14px;
    line-height: 15px;
    color: $color-text;
    margin-top: 16px;
  }

  &__city {
    border-left: 1px solid $color-border-dark;
    padding-left: 4px;
    margin-left: 3px;

    &:first-child {
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }

  &__description {
    font-size: 13px;
    line-height: 1.5;
    color: $color-text;
    margin-top: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    
    p {
      font-size: 13px;
      line-height: 1.5;
      margin-bottom: 0
    }

    label {
      @include font-family($font-family-primary, bold);
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      text-decoration: underline;
    }
  }

  &__spacer {
    max-width: 48px;
  }

  &__contact {
    font-size: 13px;
    line-height: 16px;
    white-space: nowrap;
    margin-top: 10px;
    margin-right: 24px;

    @include media-breakpoint-down(sm) {
      white-space: normal;
      word-break: break-word;
      margin-right: 12px;
    }

    .ico-svg {
      width: 16px;
      height: 16px;
    }
    
    span {
      color: $color-text;
    }

    a {
      display: inline-block;
    }
  }

  &__actions {
    display: inline-block;
    color: $color-link;
    margin-top: 8px;
    margin-left: 12px;

    &:disabled {
      cursor: not-allowed;
      opacity: .5;
    }

    .ico-svg {
      min-width: 23px;
      width: 23px;
      height: 16px;
    }
  }

  &__link {
    span {
      color: $color-link;
      text-decoration: underline;
      
      &:hover {
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }

  &__trash {
    padding-left: 35px;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
    }

    .ico-svg {
      max-width: 24px;
      max-height: 24px;
      color: $color-link;
    }
  }

  .favourite-star {
    .ico-svg {
      width: 20px;
    }
  }

  .checkbox {
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      margin-left: 56px;
    }

    &__mark {
      position: relative;
    }
  }
}