.filestyle {
  &:disabled {
    ~ .bootstrap-filestyle {
      .btn-link {
        filter: grayscale(100%);
        opacity: 0.5;
        cursor: not-allowed;

        &:hover, &:focus {
          background-color: $background-accent;
          border: solid 1px $color-border-accent;
          color: $btn-link-text-color-hover;
          text-decoration: none;
          filter: grayscale(100%);
          opacity: 0.5;
        }

        &:focus {
          text-decoration: $focus-text-decoration;
          outline: auto;
          outline-style: $focus-outline-style;
          outline-width: $focus-outline-width;
          outline-color: $btn-link-text-color-hover;
        }
      }
    }
  }
}