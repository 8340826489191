.box-person-birthday {
  display: block;
  position: relative;
  cursor: pointer;
  background-color: $background-medium;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 8px 16px;
  padding-bottom: 0;
  margin-bottom: 8px;

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  &__avatar {
    position: relative;
    min-width: 48px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    margin-right: 12px;

    span {
      display: inline-block;
      width: 48px;
      min-width: 48px;
      height: 48px;
      @include font-family($font-family-primary, regular);
      font-size: 24px;
      line-height: 48px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      min-width: 48px;
      height: 48px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 14px;
    width: 14px;
    border: solid 1px $color-border-light;
    border-radius: 50%;

    &--in-ufficio {
      background-color: $color-success;
    }

    &--smart-working {
      background-color: $color-info;
    }

    &--assente {
      background-color: $color-danger;
    }

    &--accepted {
      background-color: $color-success;
    }

    &--pending {
      background-color: $color-warning;
    }

    &--refused {
      background-color: $color-danger;
    }
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__mail, &__chat, &__video {
    @include font-family($font-family-primary, medium);
    display: inline-block;
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 8px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      color: $color-link;
  
      &:hover, &:focus {
        color: $color-accent-dark;
      }

      &:focus {
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-accent-dark;
      }
    }
  }

  &__btn, &__btn-smart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% + 32px);
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 14px;
    color: $box-person-birthday-text-color;
    background-color: $box-person-birthday-bg-color;
    border-bottom-left-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    padding: 8px;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      color: $box-person-birthday-text-color;
      margin-right: 8px;
    }
  }

  &__form {
    display: none;
    width: calc(100% + 32px);
    color: $box-person-birthday-text-color;
    background-color: $box-person-birthday-bg-color;
    border-bottom-left-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    padding: 12px;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px;

    &.active {
      display: flex;
    }
  }

  &__input-text {
    max-width: calc(100% - 70px);
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 14px;
    border: 0;
    border-radius: $border-radius-input-form;
    padding: 8px;
  }

  &__submit {
    @include font-family($font-family-primary, medium);
    color: $color-text;
    text-transform: uppercase;
    background-color: $color-white;
    border: 0;
    border-radius: 24px;
    padding: 8px;
    margin-left: 8px;
  }
  
  &__msg, &__msg-smart {
    display: none;
    justify-content: center;
    align-items: center;
    width: calc(100% + 32px);
    @include font-family($font-family-primary, medium);
    font-size: 13px;
    line-height: 14px;
    color: $color-text;
    background-color: $background-dark;
    border-bottom-left-radius: $border-radius-card;
    border-bottom-right-radius: $border-radius-card;
    padding: 8px;
    margin-top: 16px;
    margin-left: -16px;
    margin-right: -16px;

    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      color: $color-text;
      margin-right: 8px;
    }

    &.active {
      display: flex;
    }
  }
}