.favorite-filter {
  background-color: $background-light;
  border: solid 1px $color-border-medium;
  border-radius: $border-radius;
  padding: 40px 0;

  @include media-breakpoint-down(sm) {
    margin-bottom: 26px;
  }
  
  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 17px;
    line-height: 21px;
    color: $color-text;
    padding: 0 36px;
  }

  &__list {
    margin: 24px 0 0 0;
  }

  &__icon {
    position: absolute;
    left: 0px;
    top: 8px;

    .ico-svg {
      width: 12px;
      height: 12px;
      color: $color-accent;
    }
  }

  &__item {
    list-style: none;
    padding-left: 40px;

    @include media-breakpoint-only(md) {
      padding: 0 16px;
    }

    &--active {
      background-color: $background-accent;

      .ico-svg {
        color: $color-text-contrast;
      }

      .favorite-filter__link {
        color: $color-text-contrast;

        &:hover {
          color: $color-text-contrast;

          .ico-svg {
            color: $color-text-contrast;
          }
        }

        &:focus {
          text-decoration: $focus-text-decoration;
          outline: auto;
          outline-style: $focus-outline-style;
          outline-width: $focus-outline-width;
          outline-color: $color-text-contrast;
        }
      }
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    font-size: $font-size-default;
    line-height: 30px;
    color: $color-text-secondary;
    background-color: transparent;
    padding: 10px 8px 10px 20px;

    &:hover {
      .ico-svg {
        color: $color-accent;
      }
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text-secondary;
    }
  }
}