.box-document {
  position: relative;
  font-size: 13px;
  line-height: 18px;
  color: $color-text;
  cursor: default;
  background-color: $background-light;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  padding: 16px 16px 24px 16px;
  margin-bottom: 16px;

  &[data-link] {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }
  
  @include media-breakpoint-down(sm) {
    padding: 16px 16px 20px 16px;
  }
  
  &--small {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 15px 15px 10px;
  }

  &__icon {
    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 16px;
    }

    &--pdf {
      color: $color-icon-pdf;
    }

    &--spreadsheet {
      color: $color-icon-spreadsheet;
    }

    &--word {
      color: $color-icon-word;
    }

    &--video {
      color: $color-icon-video;
    }

    &--music {
      color: $color-icon-music;
    }

    &--image {
      color: $color-icon-image;
    }

    &--archive {
      color: $color-icon-archive;
    }

    &--procedura {
      color: $color-icon-procedura;
    }

    .ico-svg {
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      max-height: 50px;
    }
  }

  &__subtype {
    color: $color-text;
    text-transform: uppercase;
    margin-top: 8px;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-small;
    }
  }
  
  .favourite-star {
    .ico-svg {
      width: 20px;
    }
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
  &__link {
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    word-break: break-word;
  }

  &__date {
    text-transform: none;
    line-height: 20px;
    margin-bottom: 8px;
  }
  
  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    @include font-family($font-family-primary, regular);
    font-size: 13px;
    line-height: 18px;
    color: $color-text;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
    
    label {
      font-size: 13px;
      line-height: 18px;
      @include font-family($font-family-primary, bold);
      margin-bottom: 0;
    }
    
    p {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 0;
    }

    a {
      color: $color-link;
      text-decoration: underline;
      word-break: break-word;
    }
  }

  &__download {
    cursor: pointer;
    color: $color-icon-primary;
    margin: 0 10px;
    width: 20px;

    @include media-breakpoint-down(sm) {
      margin-left: 5px;
    }

    .ico-svg {
      max-height: 21px;
      width: 40px;
    }
  }

  .btn-acknowledgment {
    margin-left: 10px;
    margin-right: 0;

    @include media-breakpoint-down(sm) {
      margin-left: 5px;
    }
    
    .ico-svg {
      min-width: 16px;
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
    }

    span {
      font-size: $font-size-small;
      line-height: 14px;
    }
  }

  &__edit {
    position: relative;
    color: $color-primary-base;
    padding: 8px;
    margin-left: 10px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-primary-base;
    }

    &.open {
      .box-document__submenu {
        display: block;
      }
    }
  }

  &__comment {
    position: absolute;
    right: 20px;
    bottom: 30px;

    .ico-svg {
      width: 20px;
      max-width: 20px;
      max-height: 20px;
      color: $color-icon-secondary;
      margin-right: 4px;
    }

    span {
      line-height: 24px;
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    top: 45px;
    left: -160px;
    z-index: 99;
    font-size: $font-size-default;
    line-height: 1.2;
    border: solid 1px $color-border-medium;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 45px;
      z-index: -1;
      width: 20px; 
      height: 20px;
      background-color: $background-light;
      border-top: solid 1px $color-border-medium;
      border-right: solid 1px $color-border-medium;
      transform: rotate(-45deg);
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 16px 20px;
    }
  }

  .ico-new {
    position: absolute;
    top: -3px;
    left: -15px;
    z-index: 2;
    width: 0;
    height: 0;
    cursor: help;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-bottom: 24px solid $color-info;
    transform: rotate(-45deg);

    &:hover, &:focus {
      span {
        display: block;
      }
    }

    span {
      display: none;
      position: absolute;
      top: -8px;
      font-size: 13px;
      line-height: 24px;
      color: $color-text;
      background-color: $background-medium;
      padding: 0 8px;
      transform: rotate(45deg);
    }
  }
}
