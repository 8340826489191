.box-gruppo {
  position: relative;
  background-color: $background-medium;
  border-radius: $border-radius-card;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  padding: 8px 16px;
  margin-bottom: 8px;

  &[data-link], &[data-toggle] {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &:hover {
      box-shadow: $box-shadow-default;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
  
  &__notify {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 16px;
    font-size: 12px;
    color: $color-text-contrast;
    text-transform: uppercase;
    white-space: nowrap;
    background: $color-danger;
    border-radius: 11px;
    padding: 0 8px;

    .ico-svg {
      width: 12px;
      min-width: 12px;
      max-width: 12px;
      max-height: 12px;
      margin-right: 4px;
    }
  }

  &__category {
    font-size: 13px;
    line-height: 16px;
    color: $color-text;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  &__title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 21px;
    color: $color-text;
  }

  &__members {
    margin-top: 8px;

    .ico-svg {
      max-width: 23px;
      max-height: 16px;
      color: $color-icon-secondary;
    }
  }
}