.box-appuntamenti {
  position: relative;
  border-bottom: solid 1px $color-border-medium;
  padding-bottom: 24px;
  margin-bottom: 16px;

  &__ico {
    color: $color-icon-secondary;
    
    .ico-svg {
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
    }
  }

  &__date {
    font-size: $font-size-small;
    line-height: 20px;
    color: $color-text;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  
  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    margin-bottom: 8px;
  }
  
  &__desc {
    font-size: $font-size-small;
    line-height: 20px;
    color: $color-text;

    p {
      font-size: $font-size-small;
      line-height: 20px;
      color: $color-text;
      margin-bottom: 0;
    }
  }
  
  &__edit {
    position: absolute;
    top: -8px;
    right: -8px;
    color: $color-primary-base;
    padding: 8px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-primary-base;
    }

    &.open {
      .box-appuntamenti__submenu {
        display: block;
      }
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 230px;
    position: absolute;
    top: 45px;
    left: -160px;
    z-index: 99;
    font-size: $font-size-default;
    line-height: 1.2;
    border: solid 1px $color-border-medium;
    border-radius: $border-radius;
    box-shadow: $box-shadow-default;

    &::before {
      content: "";
      position: absolute;
      top: -11px;
      right: 45px;
      z-index: -1;
      width: 20px; 
      height: 20px;
      background-color: $background-light;
      border-top: solid 1px $color-border-medium;
      border-right: solid 1px $color-border-medium;
      transform: rotate(-45deg);
    }

    > div {
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 16px 20px;
    }
  }
}