.intranet-subscribe {
  margin-left: 40px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
    margin-bottom: 16px;
  }

  &__link {
    @include font-family($font-family-primary, semibold);
    line-height: 1;
    color: $color-link;
    text-transform: uppercase;
    text-decoration: underline;
    background-color: transparent;

    span {
      display: inline-block;
      margin-left: 12px;

      @include media-breakpoint-down(sm) {
        margin-left: 8px;
      }
    }

    .ico-svg {
      width: 12px;
      min-width: 12px;
      max-width: 12px;
      max-height: 12px;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  &__info {
    position: relative;
    color: $color-link;
    margin-left: 12px;
    
    @include media-breakpoint-down(sm) {
      margin-left: 8px;
    }

    span {
      display: inline-block;
    }

    .ico-svg {
      max-width: 14px;
      max-height: 14px;
    }

    &:hover, &:focus {
      .intranet-subscribe__tooltip {
        display: block;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    z-index: 1002;
    bottom: 30px;
    width: 284px;
    font-size: $font-size-small;
    line-height: $line-height-default;
    color: $color-text-secondary;
    background-color: $background-light;
    padding: 15px;
    margin-left: -134px;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -5px;
      width: 10px;
      height: 10px;
      background-color: $background-light;
      transform: rotate(-135deg);
      margin-left: -5px;
    }
  }
}