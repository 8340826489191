.intranet {

  .circle-open {
    display: flex;
    align-items: center;
    right: 90px;
    left: auto;
    bottom: 15px;
    z-index: 1003;
    box-shadow: none;
    background-color: $background-accent !important;
    border: 0;
    padding-top: 0;

    @include media-breakpoint-down(md) {
      right: 75px;
      bottom: 10px;
      width: 56px;
      height: 56px;
    }
  
    @include media-breakpoint-sm-landscape {
      right: 10px;
      bottom: 80px;
    }

    &.slideInRight {
      animation-name: none;
    }
  }
}