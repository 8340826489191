.checkbox {
  display: block;
  position: relative;
  user-select: none;
  cursor: pointer;
  padding-left: 35px;
  margin-bottom: 16px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.2;

    .checkbox__mark {
      background-color: $color-grey-3;
      cursor: not-allowed;
    }
  }
  
  &__input {
    position: absolute;
    left: 0;
    opacity: 0;
    
    &:checked {
      ~ .checkbox__mark {
        background-color: $background-light;
        border-color: $color-border-dark;
        
        &::after {
          display: block;
        }
      }
    }
  }
  
  &__mark {
    position: absolute;
    top: calc(50% - 11px);
    left: 0;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background-color: $background-light;
    border: 1px solid $color-border-dark;
    border-radius: 2px;
    
    &::after {
      display: none;
      content: "";
      position: absolute;
      top: 2px;
      right: 6px;
      width: 6px;
      height: 10px;
      border: solid $color-border-primary;
      border-width: 0 1.5px 1.5px 0;
      transform: rotate(45deg);
    }
  }
}