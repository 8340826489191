button {
  background-color: transparent;
}

.ico-svg {
  width: 100%;
  max-width: 50px;
  height: 100%;
  max-height: 50px;
}

.ico-arrow {
  padding-right: 20px;
  position: relative;
  
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    border: solid $color-border-primary;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin-top: -4px;
    transform: rotate(-45deg);
  }
}

.btn-link {
  display: inline-block;
  height: 53px;
  @include font-family($font-family-primary, semibold);
  font-size: 14px;
  line-height: 53px;
  color: $btn-link-text-color;
  text-transform: uppercase;
  cursor: pointer;
  background-color: $btn-link-bg-color;
  border: solid 1px $btn-link-bg-color;
  border-radius: $border-radius-btn-link;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;

  &:hover, &:focus, &--selected {
    color: $btn-link-text-color-hover;
    text-decoration: none;
    background-color: $btn-link-bg-color-hover;
    border-color: $btn-link-bg-color-hover;
  }

  &:focus {
    text-decoration: $focus-text-decoration;
    outline: auto;
    outline-style: $focus-outline-style;
    outline-width: $focus-outline-width;
    outline-color: $btn-link-text-color-hover;
  }

  &:disabled {
    color: $color-text-contrast;
    background-color: $background-dark;
    border-color: $color-border-dark;
    cursor: not-allowed;

    &:hover, &:focus {
      background-color: $background-dark;
    }
  }

  &--secondary {
    @include font-family($font-family-primary, semibold);
    color: $btn-link-secondary-text-color;
    background-color: $btn-link-secondary-bg-color;
    
    &:hover, &:focus, &--selected {
      color: $btn-link-secondary-text-color-hover;
      border-color: $btn-link-secondary-border-color;
      background-color: $btn-link-secondary-bg-color;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $btn-link-secondary-text-color-hover;
    }

    &:disabled {
      color: $background-dark;
      background-color: transparent;
      border-color: $background-dark;
      cursor: not-allowed;
  
      &:hover, &:focus {
        color: $background-dark;
        background-color: transparent;
        border-color: $background-dark;
      }
    }
  }
}

.btn-link-action {
  font-size: 14px;
  line-height: 20px;
  color: $color-link;
  text-decoration: underline;
  text-transform: uppercase;
}

.btn-filter-favorite {
  display: inline-block;
  @include font-family($font-family-primary, semibold);
  font-size: 14px;
  line-height: 28px;
  color: $color-link;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  border-radius: 16px;
  border: solid 1px $color-link;
  padding: 0 8px;
  margin-left: 8px;
  margin-bottom: 8px;

  &.active {
    color: $color-text-contrast;
    background-color: $color-link;
  }
}

.like-icon {
  color: $color-text;

  &:hover {
    color: $color-text;

    .ico-svg {
      color: $color-accent;
    }
  }

  &:focus {
    color: $color-text;
    text-decoration: $focus-text-decoration;
    outline: auto;
    outline-style: $focus-outline-style;
    outline-width: $focus-outline-width;
    outline-color: $color-accent;

    .ico-svg {
      color: $color-icon-secondary;
    }
  }

  .ico-svg {
    max-width: 22px;
    max-height: 20px;
    color: $color-icon-secondary;

    .ico-solid {
      display: none;
    }
  }

  &.active {
    color: $color-text;
    
    .ico-svg {
      color: $color-accent;

      .ico-solid {
        display: block;
      }
      .ico-border {
        display: none;
      }
    }

    &:hover, &:focus {
      color: $color-text;

      .ico-svg {
        .ico-solid {
          color: $color-accent;
        }
      }
    }
  }
}

.primary {
  color: $color-link !important;
}

/* responsive video */
.iframe-responsive {
  position: relative;
  z-index: 2;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* video 16:9 */
  
  &.ratio-21by9 {
    padding-bottom: 42.857143%; /* 21:9 */
  }
  &.ratio-4by3 {
    padding-bottom: 75%; /* 4:3 */
  }
  &.ratio-1by1 {
    padding-bottom: 100%; /* 1:1 */
  }
  &.ratio-1by2 {
    padding-bottom: 200%; /* 1:2 */
  }
  
  iframe, object, embed  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  padding: 0;
  margin: -1px;
}

.h1, h1, h1 a,
.h2, h2, h2 a,
.h3, h3, h3 a,
.h4, h4, h4 a,
.h5, h5, h5 a,
.h6, h6, h6 a {
  @include font-family($font-family-primary, semibold);
  color: $color-text-headings;
  line-height: $line-height-default;
  margin-top: 0;
  margin-bottom: 0;
}
h1, h1 a {
  font-size: $font-size-default + 19px;
}
h2, h2 a {
  font-size: $font-size-default + 9px;
}
h3, h3 a {
  font-size: $font-size-default + 7px;
}
h4, h4 a {
  font-size: $font-size-default + 5px;
}
h5, h5 a {
  font-size: $font-size-default + 3px;
}
h6, h6 a {
  font-size: $font-size-default;
}
p {
  @include font-family($font-family-primary, $font-weight-text-detail);
  font-size: $font-size-default;
  line-height: 30px;
  color: $color-text;
  margin-bottom: 10px;
}
a {
  color: $color-link;
  text-decoration: none;
  
  &:hover {
    color: $color-link;
    text-decoration: none;
  }

  &:focus {
    text-decoration: $focus-text-decoration;
    outline: auto;
    outline-style: $focus-outline-style;
    outline-width: $focus-outline-width;
    outline-color: $color-link;
  }
}
b, .bold,
strong, .strong {
  @include font-family($font-family-primary, bold);
}
i, em {
  font-style: italic;
}
nav ul,
nav ol {
  margin: 0;
}
figure {
  margin: 0;
}
img {
  max-width: 100%;
}
.table {
  td, th {
    vertical-align: middle;
    
    @include media-breakpoint-down(md) {
      min-width: 180px;
    }
  }

  th {
    color: $color-link;
  }

  .intestazione {
    background-color: $background-light;
    color: $color-link;
  }
}
.flex-basis-0 {
  flex-basis: 0 !important;
}
.page-load-status {
  display: none; /* hidden by default */
  text-align: center;
  border-top: 1px solid $color-secondary-base;
  padding-top: 20px;
}

.anchor {
  display: block;
  height: 120px;
  visibility: hidden;
  margin-top: -120px;

  @include media-breakpoint-down(lg) {
    height: 95px;
    margin-top: -95px;
  }
}

.blink-text {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    opacity: 0.5;
    z-index: 99;
    animation: blinkingBackground 2s;
  }
 
}
@keyframes blinkingBackground{
  0%		{ background-color: $background-blink;}
  100%	{ background-color: transparent;}
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1520px;
  }
}