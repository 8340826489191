.layout-sidebar {
  margin-top: $sidebar-spacing-size;
  margin-bottom: $sidebar-spacing-size;

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

.sidebar {
  height: 100%;
  background-color: $sidebar-bg-color;
  border-radius: $sidebar-box-border-radius;
  will-change: min-height;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    background-color: $sidebar-sm-bg-color;
  }

  &__inner {
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    will-change: position, transform;

    @include media-breakpoint-down(sm) {
      position: static !important;
      width: auto !important;
      height: auto !important;
      transform: translate3d(0, 0, 0) !important;
      padding-top: $sidebar-spacing-size;
      padding-bottom: $sidebar-spacing-size;
    }
  }
  
  .box-welcome, .box-birthdays, .box-apps, .box-favorite, .box-gruppi, .box-links, .box-elearning {
    background-color: $sidebar-box-bg-color;
    border-radius: $sidebar-box-border-radius;
    padding: $sidebar-box-spacing-size;

    @include media-breakpoint-down(sm) {
      background-color: $sidebar-box-sm-bg-color;
      padding-top: 1px;
      padding-left: $sidebar-box-sm-spacing-size;
      padding-right: $sidebar-box-sm-spacing-size;
      padding-bottom: 0;
    }
  }
}