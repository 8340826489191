.intranet-logo {
  flex: 0 0 260px;
  width: 260px;
  max-width: 260px;
  max-height: 48px;
  transition: all 0.3s linear;
  @include font-family($font-family-primary, medium);
  font-size: 12px;
  line-height: $line-height-default;
  color: $color-text-contrast;
  padding-right: 30px;

  @include media-breakpoint-down(lg) {
    flex: 0 0 200px;
    width: 200px;
    max-width: 200px;
  }

  @include media-breakpoint-down(md) {
    margin: 6px 0;
  }

  @include media-breakpoint-down(xs) {
    margin: 8px 0;
  }

  @include media-breakpoint-down(xs) {
    height: 35px;
    flex: 0 0 120px;
    max-width: 120px;
    padding-right: 0;
  }
  
  &__link {
    display: inline-block;

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text-contrast;
    }
  }

  &__img {
    max-height: 48px;

    @include media-breakpoint-down(md) {
      max-height: 38px;
    }

    @include media-breakpoint-down(xs) {
      max-height: 35px;
    }

    &--alternative {
      display: none;
    }
  }

  &__text {
    margin-top: 7px;
    margin-left: 10px;
  }
}