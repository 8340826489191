.box-progress {
  position: relative;
  height: calc(100% - 16px);
  background-color: $background-light;
  text-align: center;
  padding: 40px 12px;
  margin-bottom: 16px;

  &__value {
    @include font-family($font-family-primary, semibold);
    font-size: 64px;
    line-height: 1;
    color: $color-accent;
    margin-bottom: 8px;

    @include media-breakpoint-down(sm) {
      font-size: 40px;
    }
  }

  &__label {
    font-size: 18px;
    line-height: 1.2;
    color: $color-text;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

    strong {
      color: $color-accent;
    }
  }
}