.intranet-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  color: $header-text-color;
  
  @include media-breakpoint-down(md) {
    color: $nav-text-color-mobile;
    box-shadow: $box-shadow-default;
  }

  &__bg {
    background-color: $header-bg-color;
  }

  &__middle {
    padding: 16px 0;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -15px;
    }
  }

  &__search {
    @include media-breakpoint-down(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}