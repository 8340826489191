.box-links {
  color: $box-links-text-color;

  @include media-breakpoint-down(sm) {
    overflow: hidden;

    &.open {
      .box-links__wrap {
        max-height: 3500px;
      }

      .box-links__title {
        &::after {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }

  &__title {
    display: block;
    width: 100%;
    @include font-family($font-family-primary, bold);
    font-size: 14px;
    line-height: 1;
    color: $box-links-text-color;
    text-align: left;
    text-transform: uppercase;
    cursor: text;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      position: relative;
      cursor: pointer;
      background-color: $box-apps-bg-color;
      border-radius: $border-radius-title-box;
      padding: 16px 24px;
      margin-top: 24px;
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 16px;
        width: 16px;
        height: 16px;
        border-top: solid 1px $box-links-text-color;
        border-left: solid 1px $box-links-text-color;
        transform: rotate(225deg);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    &__wrap {
      max-height: 0;
      overflow: hidden;
      transition: $transition-cubic;
    }
  }
  
  &__content {
    display: block;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 20px;
    color: $box-links-text-color;
    text-align: center;
    background-color: $box-links-bg-color;
    border-radius: $box-links-radius;
    padding: 8px 5px;
    padding-top: 16px;
    margin: 4px;

    &:hover {
      color: $box-links-text-color-hover;
      background-color: $box-links-bg-color-hover;

      .ico-svg {
        color: $box-links-icon-color-hover;
      }
    }

    .ico-svg {
      max-width: 32px;
      max-height: 30px;
      color: $box-links-icon-color;
      margin-bottom: 8px;
    }
  }

  &__name {
    display: -webkit-box;
    height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 1.5;
    color: $box-links-text-color;
  }

  &__icon {
    display: block;
  }
}