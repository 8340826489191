.box-user {
  margin-bottom: 40px;
  
  &__avatar {
    position: relative;
    min-width: 36px;
    width: 36px;
    height: 36px;
    overflow: hidden;
    margin-right: 10px;

    span {
      display: inline-block;
      width: 36px;
      min-width: 36px;
      height: 36px;
      @include font-family($font-family-primary, regular);
      font-size: 12px;
      line-height: 36px;
      color: $avatar-text-color;
      text-align: center;
      text-transform: uppercase;
      background-color: $avatar-bg-color;
      border-radius: $border-radius-avatar;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      min-width: 36px;
      height: 36px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__name, &__link {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 1;
    color: $color-text;
  }

  &__role {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 18px;
    color: $color-text;
    margin-top: 4px;

    span {
      position: relative;
      display: inline-block;
      padding-left: 20px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        background-color: $background-medium;
        border-radius: 50%;
      }
    }
  }

  &__email {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 1;
    margin-top: 4px;

    .ico-svg {
      width: 16px;
      height: 16px;
    }
    
    span {
      display: inline-block;
      margin-left: 4px;
    }
  }

  &__messages {
    @include font-family($font-family-primary, regular);
    font-size: $font-size-small;
    line-height: 18px;
    color: $color-link;
    margin-top: 4px;
  }
}