.portlet-navigation {
	.section {
		margin-bottom: 0;
	}
}

.portlet-navigation {
	.intranet-tabs.intranet-tabs-search {
		margin-bottom: 0;
	}
}

.control-menu {
	a {
		border-color: transparent;
		color: $color-white;
	}
}
.panel-group {
	a {
		border-color: transparent;
		color: $color-white;
	}
}
