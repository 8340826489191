.intranet-megamenu {
  line-height: 1;
  color: $nav-text-color-submenu;
  
  @include media-breakpoint-up(lg) {
    border-top: 1px solid $color-border-light;
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    max-height: calc(100vh - 120px);
    overflow: auto;
    z-index: -2;
    background-color: $megamenu-bg-color;
    transform: translateY(-100%);
    transition: none;
  }

  @include media-breakpoint-down(md) {
    max-height: 0;
    transition: $transition-cubic;
    overflow: hidden;
  }
  
  .ico-arrow {
    &::after {
      border-color: $color-border-accent;
      
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  &__wrapper {
    padding: $section-spacing-size 0;
    
    @include media-breakpoint-down(md) {
      padding: $section-spacing-size-sm 0;
    }
  }

  &__close {
    text-align: right;
    line-height: 1;

    .megamenu-close {
      display: inline-block;
      line-height: 1;
      background-color: transparent;
      outline: 0;

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-accent;
      }

      .ico-svg {
        max-width: 38px;
        max-height: 38px;
        color: $color-accent;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &__detail {
      border-bottom: 1px solid $color-border-light;
      padding-bottom: 8px;
      margin-bottom: 16px;
    }
  }
  
  @include media-breakpoint-up(lg) {
    &__navigation {
      padding: $section-spacing-size 0;

      &--has-cat {
        padding-bottom: 0;
      }
    }
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 34px;
    color: $nav-text-color-submenu;

    @include media-breakpoint-down(md) {
      font-size: 18px;
      color: $nav-text-color-mobile;

      &:hover, &--active {
        color: $nav-text-color-mobile;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $nav-text-color-mobile;
      }
    }
  
  }

  &__description {
    @include font-family($font-family-primary, medium);
    font-size: $font-size-default;
    line-height: 30px;
    color: $nav-text-color-submenu;
    margin-top: 16px;

    @include media-breakpoint-down(md) {
      font-size: $font-size-default;
      line-height: 26px;
      color: $nav-text-color-mobile;
    }
  }

  &__img {
    max-height: 220px;
    margin-bottom: 24px;
  }

  &__content-title {
    display: inline-block;
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: 30px;
    color: $nav-text-color-submenu;
    margin-bottom: 16px;
  }

  &__section-name {
    line-height: 20px;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $color-border-dark;
      padding-top: 16px;
      padding-bottom: 16px;
      margin-bottom: 8px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__last-content {
      margin-top: 24px;
    }
  }

  &__section-link {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-big;
    color: $nav-text-color-submenu;
    
    @include media-breakpoint-down(md) {
      @include font-family($font-family-primary, regular);
      color: $nav-text-color-mobile;
      margin-bottom: 16px;
      
      &:hover {
        color: $nav-text-color-mobile;
      }

      &:focus {
        color: $nav-text-color-mobile;
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $nav-text-color-mobile;
      }
    }
  }
  
  &__third-level {
    @include font-family($font-family-primary, regular);

    @include media-breakpoint-down(md) {
      margin-left: 16px;
      margin-bottom: 24px;
    }

    li {
      margin-top: 16px;
    }
  }

  &__viewall, &__viewless {
    display: block;
    @include font-family($font-family-primary, semibold);
    text-decoration: underline;
    text-transform: uppercase;
    color: $color-link !important;
    background-color: transparent;
    margin: 30px 0;

    @include media-breakpoint-down(md) {
      font-size: $font-size-default;
      color: $nav-text-color-mobile !important;
    }
    
    &:hover, &:focus {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-link;
    }
  }

  &__category { 
    padding: $section-spacing-size 0;

    @include media-breakpoint-down(md) {
      padding-bottom: $section-spacing-size-sm;

      &--has-nav {
        padding-top: $section-spacing-size-sm;
      }
    }

    ul {
      column-count: 4;

      @include media-breakpoint-down(md) {
        column-count: 1;
        margin-left: 16px;
      }
    }
  }

  &__category-name { 
    width: 100%;
    font-size: $font-size-big;
    border-bottom: 1px solid $color-border-dark;
    padding-bottom: 16px;
    margin-bottom: 24px;

    @include media-breakpoint-down(md) {
      color: $nav-text-color-mobile;
    }
  }

  &__third-level,  &__category  {
    ul {
      list-style: none;

      li {
        @include font-family($font-family-primary, regular);
        font-size: $font-size-small;
        line-height: 28px;

        a {
          display: inline-block;
          font-size: $font-size-small;
          line-height: $line-height-default;
          color: $nav-text-color-submenu;

          &:hover {
            color: $color-link;
          }

          &:focus {
            text-decoration: $focus-text-decoration;
            outline: auto;
            outline-style: $focus-outline-style;
            outline-width: $focus-outline-width;
            outline-color: $color-link;
          }

          @include media-breakpoint-down(md) {
            font-size: $font-size-default;
            line-height: 30px;
            color: $nav-text-color-mobile;

            &:hover, &:focus {
              color: $nav-text-color-mobile;
            }

            &:focus {
              text-decoration: $focus-text-decoration;
              outline: auto;
              outline-style: $focus-outline-style;
              outline-width: $focus-outline-width;
              outline-color: $nav-text-color-mobile;
            }
          }
        } 
      }
    } 
  }

  .box-document {
    background-color: $background-medium;
  }
}