.info-notify {
  position: relative;
  margin-left: 12px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 8px;
  }
  
  &.open {
    .info-notify__submenu {
      display: block;
    }
  }

  &__btn {
    height: 22px;
    font-size: $font-size-small;
    color: $notification-num-text-color;
    text-transform: uppercase;
    background: $notification-num-bg-color;
    border-radius: 11px;
    white-space: nowrap;
    padding: 0 10px;

    .ico-svg {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      max-height: 16px;
      margin-right: 4px;
    }
  }

  &__submenu {
    display: none;
    color: $color-text;
    width: 270px;
    position: absolute;
    right: 0;
    z-index: 99;
    color: $color-text;
    border-radius: $border-radius-card;

    @include media-breakpoint-down(xs) {
      right: -10px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 6px;
      right: 16px;
      width: 0; 
      height: 0; 
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $background-light;
      margin-left: -5px;

      @include media-breakpoint-down(xs) {
        right: 30px;
      }
    }

    > div {
      color: $color-text;
      background-color: $background-light;
      border-radius: $border-radius-card;
      box-shadow: $box-shadow-default;
      padding: 24px 16px;
      margin-top: 15px;

      p {
        color: $color-text;

        strong {
          color: $color-text;
        }
      }
    }
  }

  &__list {
    margin: 0;
  }

  &__item {
    list-style-type: none;
    padding: 5px 0;

    a, button {
      color: $color-text-secondary;
      text-align: left;
      background-color: transparent;

      &:hover {
        color: $color-link;
        text-decoration: underline;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }
  
  &__link {
    @include font-family($font-family-primary, semibold);
    font-size: 20px;
    line-height: 26px;
    color: $color-text;

    &:hover {
      color: $color-text;
      text-decoration: none;
    }

    &:focus {
      text-decoration: $focus-text-decoration;
      outline: auto;
      outline-style: $focus-outline-style;
      outline-width: $focus-outline-width;
      outline-color: $color-text;
    }

    span {
      @include font-family($font-family-primary, regular);
      font-size: 14px;
      line-height: 26px;
      color: $color-link;
      text-decoration: underline;
      text-transform: uppercase;
      margin-left: 12px;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-link;
      }
    }
  }
}
