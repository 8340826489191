.intranet-map {
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  margin-bottom: 16px;
  
  &.ratio-21by9 {
    padding-bottom: 42.857143%; /* 21:9 */
  }
  &.ratio-4by3 {
    padding-bottom: 75%; /* 4:3 */
  }
  &.ratio-1by1 {
    padding-bottom: 100%; /* 1:1 */
  }
  &.ratio-1by2 {
    padding-bottom: 200%; /* 1:2 */
  }

  //popup in mappa
  &__popup {
    min-width: 350px;
    margin-bottom: 40px;

    .leaflet-popup-content {
      margin: 24px;
    }

    .leaflet-popup-content-wrapper {
      border-radius: 0;
    }

    .leaflet-popup-close-button {
      width: 24px;
      height: 24px;
      @include font-family($font-family-primary, semibold);
      font-size: 24px;
      line-height: 1;
    }

    .title {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: 21px;
      color: $color-text;
      margin-bottom: 8px;
    }

    .link {
      @include font-family($font-family-primary, semibold);
      font-size: $font-size-default;
      line-height: 21px;
      color: $color-text;

      &:hover {
        color: $color-accent;
      }

      &:focus {
        text-decoration: $focus-text-decoration;
        outline: auto;
        outline-style: $focus-outline-style;
        outline-width: $focus-outline-width;
        outline-color: $color-accent;
      }
    }

    .address {
      @include font-family($font-family-primary, regular);
      font-size: 13px;
      line-height: 20px;
      color: $color-text;
      margin-bottom: 8px;
    }

    .contacts {
      font-size: 13px;
      line-height: 20px;
      margin-top: 16px;
  
      .ico-svg {
        max-width: 16px;
        max-height: 16px;
        margin-right: 4px;
      }
    
      span {
        color: $color-text;
      }
  
      a {
        display: inline-block;
        padding-right: 24px;
      }
    }
  }
}