.switch-toggle {
  position: relative;
  display: inline-block;
  min-width: 60px;
  max-width: 60px;
  height: 34px;
  margin-bottom: 0;
  
  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switch-toggle__slider {
      background-color: $switch-color-scheme-bg-color;
    }

    &:focus + .switch-toggle__slider {
      background-color: $switch-color-scheme-bg-color;
    }

    &:checked + .switch-toggle__slider span {
      color: $switch-color-scheme-ico-color;
      background-color: $switch-color-scheme-ico-bg-color;
      transform: translateX(26px);

      .ico-svg {
        display: none;

        &:first-child {
          display: none;
        }

        &:last-child {
          display: flex;
        }
      }
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $switch-color-scheme-bg-color;
    border: solid 1px $switch-color-scheme-bg-color;
    border-radius: 34px;
    cursor: pointer;
    transition: .4s;

    span {
      position: absolute;
      left: 4px;
      bottom: 3px;
      height: 26px;
      width: 26px;
      background-color: $background-dark;
      border-radius: 50%;
      transition: .4s;
      padding: 6px;

      .ico-svg {
        display: none;

        &:first-child {
          display: flex;
        }
      }
    }
  }
}