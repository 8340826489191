.box-portlet {
  position: relative;
  background-color: $background-light;
  border-radius: $border-radius;
  padding: 30px;
  padding-bottom: 16px;
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    &.open-md {
      .box-portlet__toggle {
        transform: rotate(-135deg);
      }
      .box-portlet__content {
        max-height: 3500px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 24px;
  }

  &--empty {
    font-size: 18px;
    line-height: 1;
    color: $color-text;
    text-align: center;
    background-color: transparent;
    border: solid 1px $color-border-medium;
    box-shadow: none;
    padding: 56px;
    padding-bottom: 56px;
    margin-bottom: $section-spacing-size + 20px;
    
    @include media-breakpoint-down(sm) {
      margin-bottom: $section-spacing-size-sm + 20px;
    }

    .ico-svg {
      max-width: 64px;
      max-height: 64px;
      color: $color-border-medium;
      margin-bottom: 16px;
    }
  }

  &__content {
    @include media-breakpoint-down(md) {
      max-height: 0;
      overflow: hidden;
      transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);

      > div {
        padding-top: 16px;
        
        @include media-breakpoint-down(md) {
          padding-top: 30px;
        }
      }
    }
  }

  &__toggle {
    border: solid $color-text;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(45deg);
    margin: 4px 8px;
  }

  &__title {
    @include font-family($font-family-secondary, semibold);
    font-size: 24px;
    line-height: 1;
    color: $color-text;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  &__close {
    margin-top: -4px;
    margin-left: 24px;
    color: $color-accent;

    @include media-breakpoint-down(sm) {
      margin-left: 12px;
    }

    .ico-svg {
      min-width: 30px;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .intranet-card__user {
    margin-bottom: 20px;
  }

  .section__link {
    margin-bottom: 20px;
  }
  
  &__edit {
    color: $color-primary-base;
    padding: 4px;

    .ico-svg {
      max-width: 20px;
      max-height: 20px;
      color: $color-primary-base;
    }
  }
}