.box-iscrizioni {
  margin-top: 24px;

  &.open {
    .js-accordion__toggle {
      .ico-svg {
        transform: rotate(180deg);
      }
    }

    .box-iscrizioni__title {
      color: $box-faq-title-color-open;
      background-color: $box-faq-title-bg-color-open;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .ico-svg {
        color: $box-faq-arrow-color-open;
        border-color: $box-faq-arrow-color-open;
      }
    }

    .box-iscrizioni__body {
      max-height: 5000px;

      > div {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  
  &--list {
    .box-iscrizioni__body {
      max-height: 0;
      border-top: solid 1px $color-border-dark;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 56px;
    }
  }

  &--evolute {
    .box-iscrizioni__body {
      max-height: none;
      overflow: auto;
      transition: none;
    }
  }
  
  &__head {
    padding: 0 24px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    color: $box-faq-title-color;
    background-color: $box-faq-title-bg-color;
    border-radius: $box-faq-border-radius;
    cursor: pointer;
    padding: 15px 24px;

    .ico-svg {
      width: 35px;
      height: 35px;
      color: $box-faq-arrow-color;
      border: solid 1px $box-faq-arrow-color;
      border-radius: 50%;
      padding: 10px;
    }
  }

  &__body {
    max-height: 0;
    overflow: hidden;
    transition: .4s all cubic-bezier(0.77, 0, 0.175, 1);

    > div {
      color: $color-text;
      background-color: $background-light;
      border-radius: $border-radius;
      padding: 24px;
    }
  }

  &__type {
    font-size: 13px;
    line-height: 1;
    color: $color-text;
    margin-top: 36px;
    margin-bottom: 8px;

    @include media-breakpoint-between(sm, lg) {
      min-height: 26px;
    }

    &--disabled {
      color: $color-grey-3;
    }
  }

  &__field {
    border-top: solid 1px $color-border-dark;
    padding: 22px 0;

    &:first-child {
      border-top: 0;
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .box-iscrizioni__type {
      margin-top: 0;
    }

    .ico-svg {
      max-width: 12px;
      max-height: 12px;
    }
  }

  &__check {

    .checkbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      word-wrap: normal;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        width: auto;
        font-size: 12px;
        text-align: left;
        padding-top: 4px;
        padding-left: 25px;
        margin-top: 12px;
      }
    }

    .checkbox__mark {
      top: 0;
    }
  }
}