.box-post-activity {
  border-top: solid 1px $color-border-medium;
  padding: 16px 0;

  &--first, &:first-child {
    border-top: 0;
    padding-top: 0px;
  }

  &__title {
    @include font-family($font-family-primary, semibold);
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
    margin-bottom: 8px;
  }

  &__group {
    @include font-family($font-family-primary, regular);
    font-size: 14px;
    line-height: 20px;
    color: $color-text;

    a {
      color: $color-link;
    }
  }

  &__user {
    color: $color-text;
    margin-bottom: 8px;

    &:hover {
      .box-post-activity__name {
        color: $color-link;
      }
    }
  }

  &__avatar {
    position: relative;
    min-width: 34px;
    width: 34px;
    height: 34px;
    @include font-family($font-family-primary, regular);
    font-size: $font-size-default;
    line-height: 34px;
    color: $avatar-text-color;
    text-align: center;
    text-transform: uppercase;
    background-color: $avatar-bg-color;
    border-radius: $border-radius-avatar;
    overflow: hidden;
    margin-right: 15px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 34px;
      min-width: 34px;
      height: 34px;
      object-fit: cover;
      object-position: center center;
      border-radius: $border-radius-avatar;
      vertical-align: initial;
    }
  }

  &__name {
    @include font-family($font-family-primary, bold);
    font-size: $font-size-default;
    line-height: $line-height-default;
    color: $color-text;
  }

  &__info {
    display: inline-flex;
    max-width: 70px;
    flex-grow: 1;
    color: $color-text;
    background-color: transparent;
    outline: 0;
    margin-top: 8px;

    .ico-svg {
      width: 25px;
      max-width: 25px;
      max-height: 20px;
      color: $color-icon-secondary;
      margin-right: 4px;
    }

    span {
      line-height: 24px;
    }
  }
}